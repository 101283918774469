import { createReducer } from "typesafe-actions";
import extActions from './actions';
import extConstants from "./constants";
import withJobs from "redux/_core/job/reducers";
const reducerState: {
	extForm: any;
	isSubmit: false, 
	_jobs: any;
	login: {
		fields: {
			email: "";
			password: "";
		};
		isAuthenticated: false;
		errors: {
			email: {};
		};
		helpMessages: {};
		warnings: {};
		mode: "NONE";
	};
} = {
	extForm: {},
	isSubmit: false, 
	_jobs: {},
	login: {
		fields: {
			email: "",
			password: "",
		},
		isAuthenticated: false,
		errors: {
			email: {},
		},
		helpMessages: {},
		warnings: {},
		mode: "NONE",
	},
};

const actions = extActions;
const extReducers = createReducer<any, any>(reducerState)
	.handleAction(
		actions.setExtForm,
		function loadTask(state: any, action: any) {
			const extForm = action.payload?.result[0]
			return { ...state, extForm };
		}
	)
	.handleAction(
		actions.extUserAuthenticated,
		function extUserAuthenticated(state: any, action: any) {
			return {
				...state,
				login: {
					...state.login,
					isAuthenticated: action.payload?.isAuthenticated,
				},
			};
		}
	)
	.handleAction(
		actions.formFieldsEdit,
		function formFieldsEdit(state: any, action: any) {
			return {
				...state,
				login: {
					fields: { ...state.login.fields, ...action.payload },
				},
			};
		}
	)
	.handleAction(
		actions.formFieldsPatch,
		function formFieldsPatch(state: any, action: any) {
			return {
				...state,
				extForm: {
					 ...state.extForm, ...action.payload ,
				},
			};
		}
	).handleAction(
		actions.formUpdate,
		function formUpdate(state: any, action: any) {
			return {
				...state,
				extForm: {
					 ...state.extForm, ...action.payload ,
				},
			};
		}
	)
	.handleAction(actions.formSubmit, function checklistSelect(state: any, action: any) {
		const isSubmit = action.payload.isSubmit;
		return {
			...state,
			isSubmit
		};
	});;
export default withJobs(
	extConstants.FEATURE,
	reducerState,
	extReducers
);