import React, { useState } from "react";
import { useVesselInsights } from "../hooks/useVesselInsights";

interface InsightsPanelProps {
	noonReportData: any;
}

const SimpleMarkdown: React.FC<{ text: string }> = ({ text }) => {
	const parseMarkdown = (md: string) => {
		// Convert headers
		md = md.replace(/^### (.*$)/gm, "<h3>$1</h3>");
		md = md.replace(/^## (.*$)/gm, "<h2>$1</h2>");
		md = md.replace(/^# (.*$)/gm, "<h1>$1</h1>");

		// Convert bold
		md = md.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

		// Convert italic
		md = md.replace(/\*(.*?)\*/g, "<em>$1</em>");

		// Convert links
		md = md.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');

		// Convert line breaks
		md = md.replace(/\n/g, "<br/>");

		return md;
	};

	return (
		<div
			dangerouslySetInnerHTML={{ __html: parseMarkdown(text) }}
			className="text-gray-700"
		/>
	);
};

export const InsightsPanel: React.FC<InsightsPanelProps> = ({
	noonReportData,
}) => {
	const { insights, loading, error } = useVesselInsights(noonReportData);
	const [isExpanded, setIsExpanded] = useState(true);

	return (
		<div className="w-full p-4 mt-2 bg-white border border-gray-200 rounded-lg shadow-lg top-24 right-4">
			<div
				className="flex items-center mb-3 cursor-pointer"
				onClick={() => setIsExpanded(!isExpanded)}
			>
				<span className="text-blue-600">
					<svg
						className="w-5 h-5"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M13 10V3L4 14h7v7l9-11h-7z"
						/>
					</svg>
				</span>
				<h3 className="ml-2 text-lg font-semibold">AI Insights</h3>
				<svg
					className={`w-5 h-5 ml-auto transform transition-transform duration-200 ${
						isExpanded ? "rotate-180" : ""
					}`}
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={2}
						d="M19 9l-7 7-7-7"
					/>
				</svg>
			</div>

			<div
				className={`transition-all duration-200 overflow-hidden ${
					isExpanded ? "max-h-screen" : "max-h-0"
				}`}
			>
				{loading && (
					<div className="flex space-x-4 animate-pulse">
						<div className="flex-1 py-1 space-y-4">
							<div className="w-3/4 h-4 bg-gray-200 rounded"></div>
							<div className="space-y-2">
								<div className="h-4 bg-gray-200 rounded"></div>
								<div className="w-5/6 h-4 bg-gray-200 rounded"></div>
							</div>
						</div>
					</div>
				)}

				{error && <div className="text-sm text-red-500">{error}</div>}
				{insights && (
					<div className="prose-sm prose">
						<SimpleMarkdown text={insights} />
					</div>
				)}
			</div>
		</div>
	);
};
