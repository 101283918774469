import { Middleware } from "redux";
import { IStore } from "redux/store";
import reportActions, {
	EReportActions,
	TReportActions,
	reportJobActions,
} from "./actions";
import { apiActions } from "redux/_core/api";
import reportAPIS from "./api";
import {
	IBilgeWaterAllVesselAggregateRecord,
	IBilgeWaterRecord,
	ICargoSlopAggregateRecord,
	ICargoSlopVesselSpecificAggregateRecord,
	IComprehensiveNoonReportRecord,
	IComprehensiveNoonReportVesselSpecificDataRecord,
	IGarbageCategoryAllVesselsRecord,
	IGarbageDischargeAllVesselsRecord,
	IGarbageRawRecord,
	INavnucForms,
	IORSAggregateRecord,
	IORSVesselSpecificAggregateRecord,
	IOilSpillsInWaterAggregateRecord,
	IOilSpillsInWaterVesselSpecificAggregateRecord,
} from "./@types";
import reportSelectors from "./selectors";
import {
	defaultMonthRange,
	quarterLabels as initialQuarterLabels,
	quarterWiseMonthRangeMapper,
} from "./constants";
const { apiRequest } = apiActions;

const reportMiddleware: Middleware<any, IStore, any> =
	({ dispatch, getState }) =>
	(next) =>
	(action: TReportActions) => {
		switch (action.type) {
			case EReportActions.REPORT_GARBAGE_LOAD: {
				next(action);
				const job = reportJobActions.garbageReportLoad;
				const authStore = getState().app.auth;
				const tenantId = authStore.user?.tenantId ?? "";
				const reportStore = reportSelectors._getStore(getState());
				const vesselId = reportSelectors.getVesselId(reportStore);
				// const tenantId = "1076c7c10b69e86246f3910b62fc78f5";
				// const vesselId = "f2cc6b1832ce09735c15b84d0a0bbca5";

				const yearRange = reportSelectors.getGarbageYear(reportStore);
				const monthRange = defaultMonthRange;

				dispatch(
					apiRequest<IGarbageRawRecord[]>({
						...reportAPIS.loadGarbageReport(
							tenantId,
							vesselId,
							yearRange,
							monthRange
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(reportActions.document.garbageRawDataSet(data));
							},
							onError() {
								dispatch(
									job.error({
										message: "Failed to load garbage report",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EReportActions.REPORT_BILGE_WATER_LOAD: {
				next(action);
				const job = reportJobActions.garbageReportLoad;
				const authStore = getState().app.auth;
				const tenantId = authStore.user?.tenantId ?? "";
				const reportStore = reportSelectors._getStore(getState());
				const vesselId = reportSelectors.getBilgeWaterVesselId(reportStore);
				// const tenantId = "1076c7c10b69e86246f3910b62fc78f5";
				// const vesselId = "f2cc6b1832ce09735c15b84d0a0bbca5";

				const yearRange = reportSelectors.getBilgeWaterYear(reportStore);
				const monthRange = defaultMonthRange;

				dispatch(
					apiRequest<IBilgeWaterRecord[]>({
						...reportAPIS.loadBilgeWaterReport(
							tenantId,
							vesselId,
							yearRange,
							monthRange
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(
									reportActions.document.bilgeWaterRawDataSet(
										data && data?.length ? data : []
									)
								);
							},
							onError() {
								dispatch(
									job.error({
										message: "Failed to load binge water report",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EReportActions.REPORT_BILGE_WATER_ALL_VESSELS_AGGREGATE_LOAD: {
				next(action);
				const authStore = getState().app.auth;
				const tenantId = authStore.user?.tenantId ?? "";
				const reportStore = reportSelectors._getStore(getState());
				const year = reportSelectors.getBilgeWaterYear(reportStore);
				const job = reportJobActions.bilgeWaterAllVesselsAggregateLoad;
				const monthRange = "0,11";

				dispatch(
					apiRequest<IBilgeWaterAllVesselAggregateRecord[]>({
						...reportAPIS.loadBilgeWaterAllVesselsAggregate(
							tenantId,
							year,
							monthRange
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(
									reportActions.document.bilgeWaterAllVesselDataSet(data ?? [])
								);
							},
							onError() {
								dispatch(
									job.error({
										message:
											"Failed to load binge water report for all vessels",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);

				break;
			}
			case EReportActions.REPORT_GARBAGE_DISCHARGE_ALL_VESSELS_LOAD: {
				next(action);
				const authStore = getState().app.auth;
				const tenantId = authStore.user?.tenantId ?? "";
				const reportStore = reportSelectors._getStore(getState());
				const year = reportSelectors.getGarbageYear(reportStore);
				const job = reportJobActions.allVesselsGarbageDischargeLoad;
				const monthRange = "0,11";

				dispatch(
					apiRequest<IGarbageDischargeAllVesselsRecord[]>({
						...reportAPIS.loadGarbageTypeForAllVessels(
							tenantId,
							"dischargeMethod",
							year,
							monthRange
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(
									reportActions.document.garbageDischargeAllVesselDataSet(
										data ?? []
									)
								);
							},
							onError() {
								dispatch(
									job.error({
										message:
											"Failed to load garbage discharge report for all vessels",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);

				break;
			}
			case EReportActions.REPORT_GARBAGE_CATEGORY_ALL_VESSELS_LOAD: {
				next(action);
				const authStore = getState().app.auth;
				const tenantId = authStore.user?.tenantId ?? "";
				const reportStore = reportSelectors._getStore(getState());
				const year = reportSelectors.getGarbageYear(reportStore);
				const job = reportJobActions.allVesselsGarbageCategoryLoad;
				const monthRange = "0,11";

				dispatch(
					apiRequest<IGarbageCategoryAllVesselsRecord[]>({
						...reportAPIS.loadGarbageTypeForAllVessels(
							tenantId,
							"garbageCategory",
							year,
							monthRange
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(
									reportActions.document.garbageCategoryAllVesselDataSet(
										data ?? []
									)
								);
							},
							onError() {
								dispatch(
									job.error({
										message:
											"Failed to load garbage category report for all vessels",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);

				break;
			}
			case EReportActions.REPORT_ORS_VESSEL_SPECIFIC_AGGREGATE_LOAD: {
				next(action);
				const job = reportJobActions.vesselSpecificOrsAggregateLoad;
				const authStore = getState().app.auth;
				const tenantId = authStore.user?.tenantId ?? "";
				const reportStore = reportSelectors._getStore(getState());
				const vesselId = reportSelectors.getOrsVesselId(reportStore);

				const year = reportSelectors.getOrsYear(reportStore);
				const monthRange = defaultMonthRange;

				dispatch(
					apiRequest<IORSVesselSpecificAggregateRecord[]>({
						...reportAPIS.loadOrsVesselSpecificAggregate(
							tenantId,
							year,
							monthRange,
							vesselId
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(
									reportActions.document.orsVesselSpecificRawDataSet(
										data && data?.length ? data : []
									)
								);
							},
							onError() {
								dispatch(
									job.error({
										message: "Failed to load ors report for the vessel",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EReportActions.REPORT_ORS_ALL_VESSEL_AGGREGATE_LOAD: {
				next(action);
				const job = reportJobActions.allVesselOrsAggregateLoad;
				const authStore = getState().app.auth;
				const tenantId = authStore.user?.tenantId ?? "";
				const reportStore = reportSelectors._getStore(getState());

				const year = reportSelectors.getOrsYear(reportStore);
				const monthRange = defaultMonthRange;

				dispatch(
					apiRequest<IORSAggregateRecord[]>({
						...reportAPIS.loadOrsAllVesselsAggregate(
							tenantId,
							year,
							monthRange
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(
									reportActions.document.orsRawDataSet(
										data && data?.length ? data : []
									)
								);
							},
							onError() {
								dispatch(
									job.error({
										message: "Failed to load ors report for the tenant",
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}

			case EReportActions.REPORT_CARGO_SLOP_VESSEL_SPECIFIC_AGGREGATE_LOAD: {
				next(action);
				const job = reportJobActions.vesselSpecificCargoSlopAggregateLoad;
				const authStore = getState().app.auth;
				const tenantId = authStore.user?.tenantId ?? "";
				const reportStore = reportSelectors._getStore(getState());
				const vesselId = reportSelectors.getGenericVesselId(reportStore);

				const year = reportSelectors.getGenericYear(reportStore);
				const monthRange = defaultMonthRange;

				dispatch(
					apiRequest<ICargoSlopVesselSpecificAggregateRecord[]>({
						...reportAPIS.loadCargoSlopVesselSpecificAggregate(
							tenantId,
							year,
							monthRange,
							vesselId
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(
									reportActions.document.cargoSlopVesselSpecificRawDataSet(
										data && data?.length ? data : []
									)
								);
							},
							onError(err) {
								dispatch(
									job.error({
										message:
											err?.error ??
											"Failed to load cargo slop report for the vessel",
										notification: {},
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EReportActions.REPORT_CARGO_SLOP_ALL_VESSEL_AGGREGATE_LOAD: {
				next(action);
				const job = reportJobActions.allVesselCargoSlopAggregateLoad;
				const authStore = getState().app.auth;
				const tenantId = authStore.user?.tenantId ?? "";
				const reportStore = reportSelectors._getStore(getState());

				const year = reportSelectors.getGenericYear(reportStore);
				const monthRange = defaultMonthRange;

				dispatch(
					apiRequest<ICargoSlopAggregateRecord[]>({
						...reportAPIS.loadCargoSlopAllVesselsAggregate(
							tenantId,
							year,
							monthRange
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(
									reportActions.document.cargoSlopRawDataSet(
										data && data?.length ? data : []
									)
								);
							},
							onError(err) {
								dispatch(
									job.error({
										message:
											err?.error ??
											"Failed to load cargo slop report for the tenant",
										notification: {},
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EReportActions.REPORT_OIL_SPILLS_IN_WATER_VESSEL_SPECIFIC_AGGREGATE_LOAD: {
				next(action);
				const job =
					reportJobActions.vesselSpecificOilSpillsInWaterAggregateLoad;
				const authStore = getState().app.auth;
				const tenantId = authStore.user?.tenantId ?? "";
				const reportStore = reportSelectors._getStore(getState());
				const vesselId = reportSelectors.getGenericVesselId(reportStore);

				const year = reportSelectors.getGenericYear(reportStore);
				const monthRange = defaultMonthRange;

				dispatch(
					apiRequest<IOilSpillsInWaterVesselSpecificAggregateRecord[]>({
						...reportAPIS.loadOilSpillsInWaterVesselSpecificAggregate(
							tenantId,
							year,
							monthRange,
							vesselId
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(
									reportActions.document.oilSpillsInWaterVesselSpecificRawDataSet(
										data && data?.length ? data : []
									)
								);
							},
							onError(err) {
								dispatch(
									job.error({
										message:
											err?.error ??
											"Failed to load oil spill report for the vessel",
										notification: {},
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EReportActions.REPORT_OIL_SPILLS_IN_WATER_ALL_VESSEL_AGGREGATE_LOAD: {
				next(action);
				const job = reportJobActions.allVesselOilSpillsInWaterAggregateLoad;
				const authStore = getState().app.auth;
				const tenantId = authStore.user?.tenantId ?? "";
				const reportStore = reportSelectors._getStore(getState());

				const year = reportSelectors.getGenericYear(reportStore);
				const monthRange = defaultMonthRange;

				dispatch(
					apiRequest<IOilSpillsInWaterAggregateRecord[]>({
						...reportAPIS.loadOilSpillsInWaterAllVesselsAggregate(
							tenantId,
							year,
							monthRange
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(
									reportActions.document.oilSpillsInWaterRawDataSet(
										data && data?.length ? data : []
									)
								);
							},
							onError(err) {
								dispatch(
									job.error({
										message:
											err?.error ??
											"Failed to load oil spill report for the tenant",
										notification: {},
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EReportActions.REPORT_COMPREHENSIVE_NOON_REPORT_ALL_VESSEL_LOAD: {
				next(action);
				const job = reportJobActions.allVesselComprehensiveNoonReportLoad;
				// const authStore = getState().app.auth;
				// const reportStore = reportSelectors._getStore(getState());
				// const tenantId = authStore.user?.tenantId ?? "";
				// const vesselType = reportSelectors.getGenericVesselType(reportStore);
				// const vesselClass = reportSelectors.getGenericVesselClass(reportStore);

				dispatch(
					apiRequest<{ data: IComprehensiveNoonReportRecord[] }>({
						...reportAPIS
							.loadComprehensiveNoonReportAllVessels
							// tenantId,
							// vesselType,
							// vesselClass
							(),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(
									reportActions.document.comprehensiveNoonReportRawDataSet(
										data?.data && data?.data?.length
											? data.data
											: []
									)
								);
							},
							onError(err) {
								dispatch(
									job.error({
										message:
											err?.error ??
											"Failed to load comprehensive noon report",
										notification: {},
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EReportActions.REPORT_COMPREHENSIVE_NOON_REPORT_VESSEL_SPECIFIC_DATA_LOAD: {
				next(action);
				const job = reportJobActions.vesselSpecificComprehensiveNoonReportDataLoad;
				// const authStore = getState().app.auth;
				// const reportStore = reportSelectors._getStore(getState());
				// const vesselId = reportSelectors.getGenericVesselId(reportStore);
				// const tenantId = authStore.user?.tenantId ?? "";

				dispatch(
					apiRequest<IComprehensiveNoonReportVesselSpecificDataRecord[]>({
						...reportAPIS.loadComprehensiveNoonReportVesselSpecificData(
							// tenantId,
						),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(
									reportActions.document.comprehensiveNoonReportVesselSpecificDataSet(
										data && data?.length ? data : []
									)
									
								);
							},
							onError(err) {
								dispatch(
									job.error({
										message:
											err?.error ??
											"Failed to load comprehensive noon report",
										notification: {},
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			case EReportActions.REPORT_NAVNUC_LOAD: {
				next(action);
				const job = reportJobActions.navnucLoad;
				const reportStore = reportSelectors._getStore(getState());
				const pagination = reportSelectors.getGenericPagination(reportStore)
				dispatch(
					apiRequest< INavnucForms >({
						...reportAPIS
							.loadNavnuc
							(pagination.limit, pagination.skip),
						preExecute() {
							dispatch(job.active({}));
						},
						postExecute: {
							onSuccess({ data }) {
								dispatch(
									reportActions.document.navnucRawDataSet(
										data?.forms && data?.forms?.length
											? data.forms
											: []
									)
								);
								dispatch(
									reportActions.document.reportPaginationSet(
										data?.pagination ? data.pagination: {hasMore: false,limit: 0,skip: 0,total: 0}
									)
								);
							},
							onError(err) {
								dispatch(
									job.error({
										message:
											err?.error ??
											"Failed to load Nav/Nuc reports",
										notification: {},
									})
								);
							},
							finally() {
								dispatch(job.idle({}));
							},
						},
					})
				);
				break;
			}
			default:
				next(action);
		}
	};

export default reportMiddleware;
