import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import reportSelectors from "redux/app/report/selectors";
import { IStore } from "redux/store";
import TableCloumns from "./TableColumns";
import { INavnucFormData, INavnucRecord } from "redux/app/report/@types";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { sortNavnucData } from "redux/app/report/utils";

type SortConfigData = {
	key: keyof INavnucFormData | "";
	direction: "ascending" | "descending";
}

type OwnProps = {};
type NavnucReportTableProps = PropsFromRedux & OwnProps;
function NavnucReportTable(props: NavnucReportTableProps) {
	const {
		rawNavnucData,
		filters,
		pagination
	} = props;
	const [sortConfig, setSortConfig] = useState<SortConfigData>({
		key: "",
		direction: "ascending",
	});
	const [filteredData, setFilteredData] = useState<INavnucRecord[]>(rawNavnucData);
	const reportContainerRef = useRef<HTMLDivElement>(null);

	const labels: (keyof INavnucFormData)[] = [
		"vesselName",
		"type_of_report",
		"date_of_occurrence",
		"type_of_activity",
		"possible_consequences",
		"vessel_draft",
		"depth_of_water",
		"name_of_port_allision_point",
		"description",
		"name_of_other_vessel",
		"rank_of_person_related_to_act",
		"name_of_reporter",
		"rank_of_reporter",
		"evidence",
	];

	// Filtering
	// const applyFilters = () => {
	// 	const filteredData = rawNavnucData.filter((item) => {
	// 		return filterLabels?.every(({ key, type }) => {
	// 			const filterValue = filters[key];
	// 			const itemKey = key as keyof IComprehensiveNoonReportRecord
	// 			if (!filterValue && !(type === "date")) return true;

	// 			if (type === "text" || type === "dropdown") {
	// 				return item[itemKey]?.toString().toLowerCase().includes(filterValue.toLowerCase());
	// 			}
	// 			if (type === "number") {
	// 				return Number(item[itemKey]) >= Number(filterValue);
	// 			}
	// 			if (type === "date" && filters.reportDate?.startDate && filters.reportDate?.endDate) {
	// 				const itemDate = moment(item[itemKey] as string).startOf('day');;
	// 				const startDate = moment(filters.reportDate.startDate).startOf('day');;
	// 				const endDate = moment(filters.reportDate.endDate).startOf('day');;

	// 				return itemDate.isBetween(startDate, endDate, null, '[]');
	// 			}
	// 			return true;
	// 		});
	// 	});
	// 	setFilteredData(filteredData);
	// };

	useEffect(() => {
		setFilteredData(rawNavnucData);
	}, [rawNavnucData]);

	// Sorting
	const handleSorting = (key: keyof INavnucFormData | "") => {
		setSortConfig((prev) => ({
			direction: prev.key === key && prev.direction === "ascending" ? "descending" : "ascending",
			key,
		}));
	};

	const tableRowRecords = useMemo(() => {
		return sortNavnucData(
			filteredData,
			sortConfig.key,
			sortConfig.direction
		);
	}, [filteredData, sortConfig.key, sortConfig.direction]);

	return (
		<div ref={reportContainerRef}>
			<div className="overflow-x-visible pb-4">
				<p className="text-gray-500">Total reports: {pagination.total}</p>
				<table className="w-full h-full border border-gray-400 table-auto rounded-md font-redhat-text border-collapse text-center">
					<thead>
						<tr>
							<th className="relative px-3 py-3 text-sm border font-medium leading-4 tracking-wide text-center text-gray-700 bg-gray-100 border-b border-gray-300">
								<div className="flex items-center justify-center space-x-2">
									<span>S. No</span>
								</div>
							</th>
							{labels?.map((key) => (
								<th
									key={key}
									className="relative cursor-pointer px-3 py-3 text-sm border font-medium leading-4 tracking-wide text-center text-gray-700 bg-gray-100 border-b border-gray-300"
									onClick={() => handleSorting(key)}
								>
									<div className="flex items-center justify-center space-x-2">
										<span>
											{key === "vesselName" ? "Vessel Name"
												: key[0].toUpperCase() + key.replaceAll("_", " ").slice(1)
											}
										</span>
									</div>

									<span className="absolute bottom-0 right-0">
										{sortConfig.key === key && (
											sortConfig.direction === "ascending" ? (
												<ChevronUpIcon className="w-5 h-5 text-gray-600" />
											) : (
												<ChevronDownIcon className="w-5 h-5 text-gray-600" />
											)
										)}
									</span>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{tableRowRecords.map((row, index) => {
							return (
								<tr
									key={row._id}
									className="font-medium"
								>
									<TableCloumns
										index={index}
										columns={row}
										key={row._id}
										labels={labels}
									/>
								</tr>
							);
						})}

					</tbody>
				</table>
				{!tableRowRecords.length && (
					<div className="flex justify-center items-center mt-20">
						No data found.
					</div>
				)}

			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const rawNavnucData = reportSelectors.getRawNavnucData(reportStore);
	const filters = reportSelectors.getGenericFilters(reportStore);
	const pagination = reportSelectors.getGenericPagination(reportStore);
	return {
		rawNavnucData,
		filters,
		pagination
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(NavnucReportTable);
