import React, { useEffect } from 'react'
import Table from './_elements/Table';
import { IStore } from 'redux/store';
import reportSelectors from 'redux/app/report/selectors';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import reportActions from 'redux/app/report/actions';
import { IPagination } from 'redux/app/report/@types';
import NumberedPagination from 'components/_common/NumberedPagination';

type OwnProps = {};
type NavNucReportProps = PropsFromRedux & OwnProps;
function NavNucReport(props: NavNucReportProps) {
	const {
		jobToLoadNavnuc,
		rawNavnucData,
		pagination,
		setPagination
	} = props;

	useEffect(() => {
		props.loadNavnucData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagination.skip, pagination.limit]);

	const isLoading = jobToLoadNavnuc.isLoading || !jobToLoadNavnuc.isExecuted;
	const isNavNucReportEmpty = !rawNavnucData;
	return (
		<div className="relative">
			<div className="flex flex-col">
				<div className="bg-white m-2 rounded-lg h-[calc(100vh-98px)] overflow-y-auto px-5">
					<div className="py-8 pb-0">
						<div className="flex items-center">
							<span
								title="go back"
								onClick={() => {
									window?.history?.back();
								}}
								className="mr-3 text-lg cursor-pointer bp3-icon bp3-icon-arrow-left"
							/>
							<h2 className="text-xl font-medium">
								Nav/Nuc
							</h2>
						</div>
					</div>

					{isLoading ? (
						<div className="flex justify-center mt-20">
							Loading...
						</div>
					) : isNavNucReportEmpty ? (
						<div className="flex items-center justify-center mt-20">
							No data found
						</div>
					) : (
						<div className="w-full pt-4">
							<Table />
						</div>
					)}
					<NumberedPagination
						itemIds={[]}
						currentPage={(pagination.skip / pagination.limit) + 1}
						itemsPerPage={pagination.limit}
						totalItems={pagination.total}
						onPageChange={(page, itemsPerPage) => setPagination({ ...pagination, skip: (page - 1) * itemsPerPage })}
						onItemsPerPageChange={(itemsPerPage) => setPagination({ ...pagination, limit: itemsPerPage })}
						isChangeItemsPerPageVisible={true}
						isPageLoading={isLoading}
					/>

				</div>
			</div>
		</div>
	);
}
function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const jobToLoadNavnuc =
		reportSelectors.jobToLoadNavnuc(reportStore);
	const rawNavnucData =
		reportSelectors.getRawNavnucData(reportStore);
	const pagination =
		reportSelectors.getGenericPagination(reportStore);
	return {
		jobToLoadNavnuc,
		rawNavnucData,
		pagination
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		loadNavnucData() {
			dispatch(reportActions.commands.navnucLoad());
		},
		setPagination(pagination: IPagination) {
			dispatch(reportActions.document.reportPaginationSet(pagination));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(NavNucReport);

