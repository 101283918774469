import moment from "moment";
import React, { useState, Dispatch as ReactDispatch, SetStateAction } from "react";
import { connect } from "react-redux";
import { ITabReport } from "redux/app/logs/@types";
import { IStore } from "redux/store";
import ToolsModal from "./ToolsModal";
import axios from "axios";
import { toast } from "react-hot-toast";
import { classNames } from "utils/classNames";

interface IReportRowProps {
	report: ITabReport;
	key: number;
	onClick: (report: ITabReport) => void;
	setErrorDetailsModalOpen: ReactDispatch<SetStateAction<boolean>>;
	setErrorDetails: ReactDispatch<SetStateAction<any>>;
	indexNumber: number;
	isUserThirdParty: boolean;
	isUserAdmin: boolean;
	isUserAllowedToUseCNRTool: boolean;
	vesselName: string;
	vesselImo: string;
	refreshReports: () => void;
}

export const getTitle = (report: ITabReport) => {
	return "CNR";
};

const getImosReportName = (reportType: string) => {
	switch (reportType) {
		case "STEAMING":
			return "Noon Report At Sea";
		case "PORT":
			return "Noon Report In Port";
		case "ARRIVAL":
			return "Arrival Notice";
		case "DEPARTURE":
			return "Departure Notice";

		default:
			return reportType;
	}
};

export const getSelectedDate = (report: ITabReport) => {
	if (report?.selectedDate?.includes("T")) {
		if (report?.intlDateLineRecord) {
			return `${report?.selectedDate?.split("T")?.[0]} (IDL Repeat)`;
		}
		return `${report?.selectedDate?.split("T")?.[0]}`;
	}

	return "";
};

export const getStatus = (report: ITabReport) => {
	if (report?.doc_status) {
		return `${report?.doc_status?.charAt(0)}${report?.doc_status
			?.slice(1)
			.toLowerCase()}`;
	}
	return "";
};

const ReportRow: React.FC<IReportRowProps> = (props) => {
	const { report, isUserThirdParty, isUserAdmin, isUserAllowedToUseCNRTool, vesselName, vesselImo } = props;
	const [isToolsModalOpen, setIsToolsModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleToolAction = async (actionType: string, data: any) => {
		setIsLoading(true);
		const loadingToast = toast.loading(`Processing ${actionType.toLowerCase()} action...`);

		try {
			// Make the API call using axios
			const response = await axios.post('/api/cnractions', {
				action: actionType,
				reportId: report._id,
				data: data
			});

			// if the response is successful, close the modal and refresh the page
			if (response.status === 200) {
				setIsToolsModalOpen(false);
				toast.success(`Successfully completed ${actionType.toLowerCase()} action`, {
					id: loadingToast,
					duration: 3000
				});
				props.refreshReports();
			} else {
				toast.error(`Failed to complete ${actionType.toLowerCase()} action`, {
					id: loadingToast,
					duration: 3000
				});
			}
		} catch (error: any) {
			console.error("Error handling tool action:", error);
			const errorMessage = error?.response?.data?.message || error?.message || "Unknown error occurred";
			toast.error(`Action failed: ${errorMessage}`, {
				id: loadingToast,
				duration: 5000
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<tr
			key={report._id}
			className="my-1 bg-white border hover:bg-gray-200 hover:cursor-pointer"
		>
			<td className="text-center text-gray-400 px-0.5 py-0.5 md:px-2 md:py-1 text-[9px] md:text-xs lg:text-sm">
				{props.indexNumber}
			</td>
			<td className="font-medium text-center text-gray-800 px-0.5 py-0.5 md:px-2 md:py-1 text-[7px] md:text-xs lg:text-sm">
				{getTitle(report)}
			</td>
			<td
				className="font-mono text-center text-gray-700 px-0.5 py-0.5 md:px-2 md:py-1 text-[6px] w-[110px] md:text-xs lg:text-sm"
				title={moment(report.createdAt).format("DD-MM-YYYY hh:mm a")}
			>
				{moment(report.createdAt).utc().format("DD-MM-YYYY hh:mm a")}
			</td>
			<td className="font-mono text-center px-0.5 py-0.5 md:px-2 md:py-1 text-[9px] md:text-xs lg:text-sm">
				{getSelectedDate(report)}
			</td>
			<td className="font-mono text-center px-0.5 py-0.5 md:px-2 md:py-1 text-[9px] md:text-xs lg:text-sm">
				<table
					className="mx-auto table-auto reportTimelineTable lg:table-fixed"
				>
					<tbody>
						{report?.timeline?.map((item, index) => (
							<tr className="h-full" key={index}>
								<td className="border border-gray-200 py-1 px-1 text-[6px] md:text-xs lg:text-[12px]  xl:text-sm lg:w-[86px] xl:w-[90px]">
									{item?.report_type}
								</td>
								<td className=" border border-gray-200 py-1 px-1 text-[6px] md:text-xs lg:text-[12px]  xl:text-sm lg:w-[100px] xl:w-[230px]">
									{getImosReportName(item.report_type)}
								</td>
								<td className=" text-gray-500 lg:text-[12px]  xl:text-sm border border-gray-200 py-1 px-1 text-[6px] md:text-xs">
									{item?.to_local_date} {item?.to_local_time}{" "}
									{item?.to_zd}
								</td>
								{!isUserThirdParty && (
									<td
										className="text-center border lg:text-[12px] w-8  xl:text-sm border-gray-200 py-1 px-1 text-[8px] md:text-xs "
										title={`${report?.signalApiLastProcessedDate
												? `Last Processed at ${moment(
													report?.signalApiLastProcessedDate
												).format(
													"DD-MM-YYYY hh:mm a"
												)}`
												: ""
											}`}
										onClick={() => {
											// find the erroneous report in the report.timeline by searching for the signalApiResponse
											// and show the response in a dialog/modal
											const erroneousReport = item;

											if (erroneousReport) {
												props.setErrorDetails({
													reportName:
														erroneousReport?.report_type,
													errorDetails: {
														formID: item
															?.signalApiResponse
															?.id,
														message: item
															?.signalApiResponse
															?.detail
															? item
																?.signalApiResponse
																?.detail
															: JSON.stringify(
																item?.signalApiResponse
															),

														success: item
															?.signalApiResponse
															?.id
															? true
															: item
																?.signalApiResponse
																?.detail
																? false
																: undefined,
													},
													lastProcessedDate:
														report?.signalApiLastProcessedDate,
													reportType: "SIGNAL",
													docId: report._id,
													vesselId: report.vesselId,
													doc_status:
														report.doc_status,
												});
												props.setErrorDetailsModalOpen(
													true
												);
											} else {
												console.log(
													"Signal API Response not found in the report"
												);
											}
										}}
									>
										{item?.signalApiResponse
											? item?.signalApiResponse?.id ||
												item?.signalApiResponse === 200
												? "✅"
												: "❌"
											: "⏳"}
									</td>
								)}
								{!isUserThirdParty && (
									<td
										className="text-center cursor-pointer  lg:text-[12px] w-8  xl:text-sm border border-gray-200 py-1 px-1 text-[8px] md:text-xs"
										title={`${item?.report_type === "CARGO"
												? "Not Applicable, will be sent with departure notice, if any"
												: report?.imosApiLastProcessedDate
													? `Last Processed at ${moment(
														report?.imosApiLastProcessedDate
													).format(
														"DD-MM-YYYY hh:mm a"
													)}`
													: ""
											}`}
										// on click of IMOS status, it should show the IMOS API response in a dialog/modal
										onClick={() => {
											// find the erroneous report in the report.timeline by searching for the imosApiResponse
											// and show the response in a dialog/modal
											const erroneousReport = item;

											if (erroneousReport) {
												props.setErrorDetails({
													reportName:
														erroneousReport?.report_type,
													errorDetails:
														erroneousReport?.imosApiResponse,
													lastProcessedDate:
														report?.imosApiLastProcessedDate,
													reportType: "IMOS",
													docId: report._id,
													vesselId: report.vesselId,
													doc_status:
														report.doc_status,
												});
												props.setErrorDetailsModalOpen(
													true
												);
											} else {
												console.log(
													"IMOS API Response not found in the report"
												);
											}
										}}
									>
										{/* if report type is CARGO then the status is not applicable to it, just show ➖ */}
										{item?.report_type === "CARGO"
											? "➖"
											: item?.imosApiResponse
												? item?.imosApiResponse?.success
													? "✅"
													: "❌"
												: "⏳"}
									</td>
								)}
							</tr>
						))}

						{/* also check if there is standaloneForm available in which check for statementOfFacts and delayOffHireNotice forms  */}
						{/* example {standaloneForm: {statementOfFacts:{},delayOffHireNotice:{} }} */}

						{report?.standaloneForm?.statementOfFacts && (
							<tr className="h-full">
								<td className="border border-gray-200 py-1 px-1 text-[6px] md:text-xs lg:text-[12px]  xl:text-sm lg:w-[86px] xl:w-[90px]">
									SOF
								</td>
								<td className=" border border-gray-200 py-1 px-1 text-[6px] md:text-xs lg:text-[12px]  xl:text-sm lg:w-[100px] xl:w-[230px]">
									Statement of Facts
								</td>
								<td className=" text-gray-500 lg:text-[12px]  xl:text-sm border border-gray-200 py-1 px-1 text-[6px] md:text-xs">
									{
										report?.standaloneForm?.statementOfFacts
											?.sof_eosp_date
									}{" "}
									{
										report?.standaloneForm?.statementOfFacts
											?.sof_eosp_time
									}{" "}
									{
										report?.standaloneForm?.statementOfFacts
											?.sof_eosp_zd
									}
								</td>
								<td
									className="text-center border lg:text-[12px] w-8  xl:text-sm border-gray-200 py-1 px-1 text-[8px] md:text-xs "
									title={`Not Applicable`}
								>
									{/* INFO: we don't SOF to Signal, so show not applicable emoji */}
									➖
								</td>
								<td
									className="text-center cursor-pointer  lg:text-[12px] w-8  xl:text-sm border border-gray-200 py-1 px-1 text-[8px] md:text-xs"
									title={`${report?.standaloneForm?.statementOfFacts
											?.imosApiResponse
											? `Last Processed at ${moment(
												report?.standaloneForm
													?.statementOfFacts
													?.imosApiResponse
													?.lastProcessedDate
											).format("DD-MM-YYYY hh:mm a")}`
											: ""
										}`}
									// on click of IMOS status, it should show the IMOS API response in a dialog/modal
									onClick={() => {
										// find the erroneous report in the report.timeline by searching for the imosApiResponse
										// and show the response in a dialog/modal
										const erroneousReport =
											report?.standaloneForm
												?.statementOfFacts;

										if (erroneousReport) {
											props.setErrorDetails({
												reportName:
													"Statement of Facts",
												errorDetails:
													erroneousReport?.imosApiResponse,
												lastProcessedDate:
													report?.imosApiLastProcessedDate,
												reportType: "IMOS",
												docId: report._id,
												vesselId: report.vesselId,
												doc_status: report.doc_status,
											});
											props.setErrorDetailsModalOpen(
												true
											);
										} else {
											console.log(
												"IMOS API Response not found in the report"
											);
										}
									}}
								>
									{report?.standaloneForm?.statementOfFacts
										?.imosApiResponse
										? report?.standaloneForm
											?.statementOfFacts
											?.imosApiResponse?.success
											? "✅"
											: "❌"
										: "⏳"}
								</td>
							</tr>
						)}

						{report?.standaloneForm?.delayOffHireNotice && (
							<tr className="h-full">
								<td className="border border-gray-200 py-1 px-1 text-[6px] md:text-xs lg:text-[12px]  xl:text-sm lg:w-[86px] xl:w-[90px]">
									DELAY
								</td>
								<td className=" border border-gray-200 py-1 px-1 text-[6px] md:text-xs lg:text-[12px]  xl:text-sm lg:w-[100px] xl:w-[230px]">
									Delay Offhire Notice
								</td>
								<td className=" text-gray-500 lg:text-[12px]  xl:text-sm border border-gray-200 py-1 px-1 text-[6px] md:text-xs">
									{
										report?.standaloneForm
											?.delayOffHireNotice
											?.delayOffhireNotice_start_date
									}{" "}
									{
										report?.standaloneForm
											?.delayOffHireNotice
											?.delayOffhireNotice_start_time
									}{" "}
									{
										report?.standaloneForm
											?.delayOffHireNotice
											?.delayOffhireNotice_start_offset
									}
								</td>
								<td
									className="text-center border lg:text-[12px] w-8  xl:text-sm border-gray-200 py-1 px-1 text-[8px] md:text-xs "
									title={`Not Applicable`}
								>
									{/* INFO: we don't send Delay offhire to Signal, so show not applicable emoji */}
									➖
								</td>
								<td
									className="text-center cursor-pointer  lg:text-[12px] w-8  xl:text-sm border border-gray-200 py-1 px-1 text-[8px] md:text-xs"
									title={`${report?.standaloneForm
											?.delayOffHireNotice
											?.imosApiResponse
											? `Last Processed at ${moment(
												report?.standaloneForm
													?.delayOffHireNotice
													?.imosApiResponse
													?.lastProcessedDate
											).format("DD-MM-YYYY hh:mm a")}`
											: ""
										}`}
									// on click of IMOS status, it should show the IMOS API response in a dialog/modal
									onClick={() => {
										// find the erroneous report in the report.timeline by searching for the imosApiResponse
										// and show the response in a dialog/modal
										const erroneousReport =
											report?.standaloneForm
												?.delayOffHireNotice;

										if (erroneousReport) {
											props.setErrorDetails({
												reportName:
													"Delay Offhire Notice",
												errorDetails:
													erroneousReport?.imosApiResponse,
												lastProcessedDate:
													report?.imosApiLastProcessedDate,
												reportType: "IMOS",
												docId: report._id,
												vesselId: report.vesselId,
												doc_status: report.doc_status,
											});
											props.setErrorDetailsModalOpen(
												true
											);
										} else {
											console.log(
												"IMOS API Response not found in the report"
											);
										}
									}}
								>
									{report?.standaloneForm?.delayOffHireNotice
										?.imosApiResponse
										? report?.standaloneForm
											?.delayOffHireNotice
											?.imosApiResponse?.success
											? "✅"
											: "❌"
										: "⏳"}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</td>
			
			<td className="font-medium text-center text-gray-700 px-0.5 py-0.5 md:px-2 md:py-1 text-[8px] md:text-xs lg:text-sm">
				{getStatus(report)}{" "}
			</td>

			<td className="text-center px-0.5 py-0.5 md:px-2 md:py-1 text-[9px] md:text-xs lg:text-sm">
				<button
					className="p-5 py-1 font-medium text-blue-500 underline bg-transparent border-0 shadow-none ws-input hover:text-blue-700 text-[9px] md-text[12px] lg:text-sm"
					onClick={function onClick() {
						props.onClick(report);
					}}
				>
					View
				</button>
			</td>
			{isUserAllowedToUseCNRTool && (
				<td className="font-medium text-center text-gray-700 px-0.5 py-0.5 md:px-2 md:py-1 text-[8px] md:text-xs lg:text-sm">
					<button
						onClick={(e) => {
							e.stopPropagation();
							setIsToolsModalOpen(true);
						}}
						disabled={isLoading}
						className={classNames(
							"px-3 py-1 text-sm font-medium rounded-md",
							isLoading 
								? "bg-gray-100 text-gray-400 cursor-not-allowed"
								: "text-blue-600 bg-blue-100 hover:bg-blue-200"
						)}
					>
						Tools
					</button>
					<ToolsModal
						isOpen={isToolsModalOpen}
						onClose={() => setIsToolsModalOpen(false)}
						report={report}
						vesselName={vesselName || ""}
						vesselImo={vesselImo || ""}
						onAction={handleToolAction}
						isLoading={isLoading}
					/>
				</td>
			)}
		</tr>
	);
}

function mapStateToProps(store: IStore, ownProps: IReportRowProps) {
	return {
		vesselName: ownProps.vesselName || "",
		vesselImo: ownProps.vesselImo || "",
	};
}

export default connect(mapStateToProps)(ReportRow);
