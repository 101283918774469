import React, { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { EReportType } from "redux/app/logs/@types";
import { IReportErrorDetails } from "./Reports";
import classNames from "classnames";

interface IErrorModalProps {
	errorDetails: IReportErrorDetails | null;
	open: boolean;
	setOpen?: any;
	retryReport: (
		reportType: EReportType,
		docId: string,
		vesselId: string
	) => void;
}

const getFormNotProcessedMessage = (doc_status: string) => {
	switch (doc_status) {
		case "DRAFT":
			return `Form is in draft mode and not submitted yet. Please ask the user to submit the form.`;
		case "SUBMITTED":
			return `Form is submitted but not yet verified. Please ask the user to verify the form.`;
		case "RESUBMIT":
			return `Form is in sent back mode but not yet submitted. Please ask the user to submit and verify the form.`;
		default:
			return "";
	}
};

export default function ErrorModal(props: IErrorModalProps) {
	// const [open, setOpen] = useState(true);
	const { open, setOpen, errorDetails } = props;
	const [formType, setFormType] = useState<
		"SUCCESS" | "WARNING" | "ERROR" | "PENDING" | null
	>(null);

	// console.log({ errorDetails });

	// based on success=true or false, set the formType to be ERROR or SUCCESS
	useEffect(() => {
		if (errorDetails?.errorDetails?.success === false) {
			setFormType("ERROR");
		} else if (errorDetails?.errorDetails?.success === true) {
			// if the form is submitted successfully, but has some warnings then set the formType to be ERROR
			if (errorDetails?.errorDetails?.formErrors) {
				setFormType("WARNING");
			} else {
				setFormType("SUCCESS");
			}
			// setFormType("SUCCESS");
		} else if (errorDetails?.errorDetails) {
			// console.log({ errorDetails });
			if (
				errorDetails?.doc_status === "DRAFT" ||
				errorDetails?.doc_status === "SUBMITTED" ||
				errorDetails?.doc_status === "RESUBMIT"
			) {
				setFormType("PENDING");
			} else {
				setFormType("ERROR");
			}
		} else {
			setFormType("PENDING");
		}
	}, [errorDetails]);

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			className="relative z-10"
		>
			{/* Backdrop */}
			<div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />

			{/* Dialog Container */}
			<div className="fixed inset-0 z-10 flex items-center justify-center p-4 overflow-y-auto">
				<div className="w-1/2 max-w-lg overflow-hidden transform bg-white rounded-lg shadow-xl">
					{/* Dialog Header */}
					<div className="flex items-center p-4 border-b border-gray-200">
						<div className="flex-shrink-0">
							<div
								className={classNames(
									"flex items-center justify-center w-12 h-12 rounded-full",
									{
										"bg-red-100": formType === "ERROR",
										"bg-orange-100": formType === "WARNING",
										"bg-green-100": formType === "SUCCESS",
										"bg-gray-100": formType === "PENDING",
									}
								)}
							>
								<ExclamationTriangleIcon
									className={classNames("w-6 h-6", {
										"text-red-600": formType === "ERROR",
										"text-orange-600":
											formType === "WARNING",
										"text-green-600":
											formType === "SUCCESS",
										"text-gray-600": formType === "PENDING",
									})}
									aria-hidden="true"
								/>
							</div>
						</div>
						<div className="ml-4">
							<Dialog.Title
								as="h3"
								className="text-lg font-medium leading-6 text-gray-900"
							>
								{`${
									formType === "ERROR" ? "Error" : "Info"
								} - ${errorDetails?.reportName} Report`}
							</Dialog.Title>
						</div>
					</div>

					{/* Dialog Content */}

					{formType === "ERROR" || formType === "WARNING" ? (
						<div className="p-4">
							<p className="text-sm text-gray-600">
								{errorDetails?.errorDetails?.message ||
									JSON.stringify(
										errorDetails?.errorDetails
									) ||
									"No error details found"}
							</p>

							{/* formID */}
							<p className="mt-2 text-sm text-gray-500">
								{errorDetails?.errorDetails?.formID
									? `Form Id - ${errorDetails?.errorDetails?.formID}`
									: ""}
							</p>

							{/* formErrors */}
							{errorDetails?.errorDetails?.formErrors ? (
								<div className="mt-2">
									<p className="font-medium">Errors:</p>
									<ul className="list-disc list-inside">
										{errorDetails?.errorDetails?.formErrors?.map(
											(error, index) => (
												<li
													key={index}
													className="text-sm"
												>
													{error}
												</li>
											)
										)}
									</ul>
								</div>
							) : null}

							{/* Last Processed Date */}
							<p className="mt-2 text-sm text-gray-500">
								{errorDetails?.lastProcessedDate
									? `Last Processed at ${moment(
											errorDetails?.lastProcessedDate
									  ).format("DD-MM-YYYY hh:mm a")}`
									: "Yet to be processed ⏳"}
							</p>
						</div>
					) : formType === "SUCCESS" ? (
						<div className="p-4">
							<p className="text-sm text-gray-600">
								Form submitted successfully.
								<br /> Form Id -
								{errorDetails?.errorDetails?.formID} <br />
								{errorDetails?.lastProcessedDate
									? `Last Processed at - ${moment(
											errorDetails?.lastProcessedDate
									  ).format("DD-MM-YYYY hh:mm a")}`
									: `Yet to be processed ⏳`}
							</p>
						</div>
					) : (
						<div className="p-4">
							<p className="text-sm text-gray-600">
								{/* if report is cargo then say it will be included in Departure report */}
								{errorDetails?.reportName === "CARGO" ? (
									<>
										Form will be included in Departure
										Report.
									</>
								) : (
									<>
										{errorDetails?.doc_status === "DRAFT" ||
										errorDetails?.doc_status ===
											"SUBMITTED" ||
										errorDetails?.doc_status ===
											"RESUBMIT" ? (
											<p>
												{getFormNotProcessedMessage(
													errorDetails?.doc_status
												)}
											</p>
										) : (
											<p>
												Form will be processed soon,
												Please wait... ⏳
											</p>
										)}
									</>
								)}
							</p>
						</div>
					)}

					{/* Dialog Footer */}
					<div className="flex justify-end gap-2 p-4 border-t border-gray-200">
						{errorDetails?.doc_status === "DRAFT" ||
						errorDetails?.doc_status === "SUBMITTED" ||
						errorDetails?.doc_status === "RESUBMIT" ? null : (
							<button
								type="button"
								onClick={() => {
									if (
										errorDetails?.reportType &&
										errorDetails?.docId &&
										errorDetails?.vesselId
									) {
										props.retryReport(
											errorDetails?.reportType,
											errorDetails?.docId,
											errorDetails?.vesselId
										);
										setOpen(false);
									}
								}}
								// disabled={
								// 	errorDetails?.doc_status === "DRAFT" ||
								// 	errorDetails?.doc_status === "SUBMITTED" ||
								// 	errorDetails?.doc_status === "RESUBMIT"
								// }
								className="inline-flex items-center justify-center px-4 py-2 mx-1 text-sm font-semibold text-white bg-red-600 rounded-md shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
							>
								Retry
							</button>
						)}

						<button
							type="button"
							onClick={() => setOpen(false)}
							className="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
						>
							Close
						</button>
					</div>
				</div>
			</div>
		</Dialog>
	);
}
