import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "../../../../redux/store";
import { historyActions } from "redux/_core/history";
import { rootRoutes } from "routes";
import classNames from "classnames";
import { IReportCategory } from "redux/app/report/@types";
import authSelectors from "redux/app/auth/selectors";

const reportBaseRoute =
	rootRoutes.children.app.children.reports.children.categories.children;

type OwnProps = {};
type ReportCategoriesProps = PropsFromRedux & OwnProps;
function ReportCategories(props: ReportCategoriesProps) {
	const reportCategoryWithTypes: IReportCategory[] = [
		{
			title: "Gas Emissions",
			types: [
				{ title: "CO2" },
				{ title: "Organic compound" },
				{ title: "SOx and NOx" },
				{ title: "Methane" },
			],
		},
		{
			title: "Waste",
			types: [
				{
					title: "Garbage",
					url: reportBaseRoute.waste.reportForWasteGarbage.url(),
					isDisabled: false,
				},
				{
					title: "Bilge water",
					url: reportBaseRoute.waste.reportForWasteBilgeWater.url(),
					isDisabled: false,
				},
				{
					title: "Cargo Slop",
					url: reportBaseRoute.waste.reportForWasteCargoSlop.url(),
					isDisabled: false,
				},
				{
					title: "Oily residue and sludge",
					isDisabled: false,
					url: reportBaseRoute.waste.reportForWasteORS.url(),
				},
			],
		},
		{
			title: "Other",
			types: [
				{ title: "Refrigerants" },
				{ title: "AER" },
				{ title: "Cargo distance" },
				{ title: "Packaging" },
				{
					title: "Oil spills in water",
					url: reportBaseRoute.other.reportForOilSpillsInWater.url(),
					isDisabled: false,
				},
				{ title: "Spill contained onboard" },
				{ title: "Cleaning chemical usage " },
			],
		},
		{
			title: "Comprehensive Noon Report",
			types: [
				{
					title: "Comprehensive Noon Report",
					url: reportBaseRoute.comprehensiveNoonReport.comprehensiveNoonReport.url(),
					isDisabled: props?.isUserAllowedToAccessCnrReports
						? false
						: true,
				},
			],
		},
		{
			title: "Nav/Nuc",
			types: [
				{
					title: "Nav/Nuc",
					url: reportBaseRoute.navnuc.navnuc.url(),
					isDisabled: false
				},
			],
			isHidden: props.tenantCode !== "EPS"
		},
	];
	return (
		<div className="bg-white m-2 p-2 px-4 rounded-lg h-[calc(100vh-98px)] overflow-y-auto">
			<div className="pt-6">
				{reportCategoryWithTypes.filter(item => !item.isHidden).map((category) => {
					const { title, types } = category;
					return (
						<div className="mb-6" key={title}>
							<h3 className="mb-3 text-lg font-medium">
								{title}
							</h3>
							<div className="grid grid-cols-4 gap-4">
								{types.filter(item => !item.isHidden).map((type) => {
									const { isDisabled = true } = type;
									return (
										<div
											className={classNames(
												"flex justify-center items-center border-[#ACACAC] h-20 rounded-md text-[12px] md:text-base text-center",
												{
													"cursor-pointer shadow hover:shadow-md":
														!isDisabled,
													"cursor-not-allowed bg-gray-100 shadow":
														isDisabled,
												}
											)}
											key={type.title}
											onClick={() => {
												if (isDisabled) return;
												if (type?.url) {
													props.goToUrl(
														type.title,
														type.url
													);
												}
											}}
										>
											{type.title}
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const authStore = store.app.auth;
	const authorisedModules = authSelectors(authStore)?.getAuthorisedModules();
	const isUserAllowedToAccessCnrReports =
		authorisedModules?.includes("cnrReport");
	const tenantCode = authSelectors(authStore)?.getTenantCode();

	return {
		isUserAllowedToAccessCnrReports,
		tenantCode
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		goToUrl(type: string, url: string) {
			dispatch(historyActions.push(`#${type}`, url));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ReportCategories);
