import React, { useEffect, useState, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IStore } from 'redux/store';
import { Dispatch } from 'redux';
import toolActions from 'redux/app/tool/actions';
import { apiActions } from 'redux/_core/api';
import toolAPIs from 'redux/app/tool/api';
import { IReleaseInfo, ITenant } from 'redux/app/tool/@types';
import Select from 'react-select';
import classNames from 'classnames';
import clsx from 'clsx';
import toast, { Toaster } from 'react-hot-toast';
import {
    clearIndicatorStyles,
    controlStyles,
    dropdownIndicatorStyles,
    menuStyles,
    optionStyles,
    placeholderStyles,
} from 'redux/app/feedback/utils';
import { getTenantsOptionList } from 'redux/app/tool/utils';
import { IOption } from 'redux/app/feedback/@types';
import { IVessel } from 'redux/app/feedback/@types';
import ReleaseHistory from './ReleaseHistory';
import HistoryButton from './HistoryButton';

interface IUploadBuildForm {
    frontendVersion: string;
    backendVersion: string;
    changelog: string;
    buildChannel: 'main' | 'hotfix';
    buildFile: File | null;
}

interface IPagination {
    currentPage: number;
    totalPages: number;
    totalItems: number;
    itemsPerPage: number;
}

interface IVesselStore {
    byIds: { [key: string]: IVessel };
    ids: string[];
}

interface IDeployModalProps {
    isOpen: boolean;
    onClose: () => void;
    onDeploy: (buildChannel: 'main' | 'hotfix') => void;
    isLoading: boolean;
    deployType: 'selected' | 'all';
    selectedCount?: number;
    selectedTenantOption?: IOption;
}

type OwnProps = {
    onShowHistory: () => void;
};
type ReleaseManagerProps = PropsFromRedux & OwnProps;

function DeployModal({ isOpen, onClose, onDeploy, isLoading, deployType, selectedCount = 0, selectedTenantOption }: IDeployModalProps) {
    const [buildChannel, setBuildChannel] = useState<'main' | 'hotfix'>('main');

    if (!isOpen) return null;

    const title = deployType === 'selected' 
        ? `Deploy to ${selectedCount} Selected Vessels` 
        : 'Deploy to All Vessels in Tenant';

    const description = deployType === 'selected'
        ? `You are about to deploy a release to ${selectedCount} selected vessels. This action will update the software on these vessels.`
        : `You are about to deploy a release to all vessels in the selected tenant - "${selectedTenantOption?.label}". This action will update the software on all vessels.`;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-[500px] shadow-xl">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
                    <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-gray-600 transition-colors"
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="mb-6">
                    <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mb-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-blue-700">{description}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Build Channel</label>
                        <div className="mt-1 flex gap-4">
                            <div className="flex items-center">
                                <input
                                    id="main-channel"
                                    name="build-channel"
                                    type="radio"
                                    checked={buildChannel === 'main'}
                                    onChange={() => setBuildChannel('main')}
                                    className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                />
                                <label htmlFor="main-channel" className="ml-2 block text-sm text-gray-700">
                                    Main
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="hotfix-channel"
                                    name="build-channel"
                                    type="radio"
                                    checked={buildChannel === 'hotfix'}
                                    onChange={() => setBuildChannel('hotfix')}
                                    className="h-4 w-4 text-orange-600 border-gray-300 focus:ring-orange-500"
                                />
                                <label htmlFor="hotfix-channel" className="ml-2 block text-sm text-gray-700">
                                    Hotfix
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end gap-2">
                    <button
                        type="button"
                        className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={onClose}
                        disabled={isLoading}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className={`inline-flex items-center gap-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${buildChannel === 'main' ? 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500' : 'bg-red-600 hover:bg-red-700 focus:ring-red-500'}`}
                        onClick={() => onDeploy(buildChannel)}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <>
                                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Deploying...
                            </>
                        ) : (
                            <>
                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>
                                Deploy {buildChannel === 'main' ? 'Main' : 'Hotfix'} Build
                            </>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

function ReleaseManager(props: ReleaseManagerProps) {
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [isDeployModalOpen, setIsDeployModalOpen] = useState(false);
    const [deployModalType, setDeployModalType] = useState<'selected' | 'all'>('selected');
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedVesselOption, setSelectedVesselOption] = useState<IOption | null>(null);
    const [selectedVessels, setSelectedVessels] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [uploadForm, setUploadForm] = useState<IUploadBuildForm>({
        frontendVersion: '',
        backendVersion: '',
        changelog: '',
        buildChannel: 'main',
        buildFile: null
    });
    const [buildChannelFilter, setBuildChannelFilter] = useState<IOption | null>(null);
    const [autoUpdateFilter, setAutoUpdateFilter] = useState<IOption | null>(null);
    const [revposFilter, setRevposFilter] = useState('');

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            props.clearReleases();
        };
    }, []);

    useEffect(() => {
        // Load tenants on component mount
        props.loadTenants();
    }, []);

    useEffect(() => {
        // Reset vessel selection and page number when tenant changes
        setSelectedVesselOption(null);
        setCurrentPage(1);
        setSelectedVessels([]);
        setSelectAll(false);
    }, [props.selectedTenantOption]);

    useEffect(() => {
        // Reset page number when vessel selection changes
        setCurrentPage(1);
        setSelectedVessels([]);
        setSelectAll(false);
    }, [selectedVesselOption]);

    // Load releases function
    const loadData = async () => {
        try {
            setIsLoading(true);
            setError(null);
            const tenantId = props.selectedTenantOption?.value;
            const vesselId = selectedVesselOption?.value;
            
            console.log('Loading releases with:', {
                tenantId,
                page: currentPage,
                vesselId,
                buildChannel: buildChannelFilter?.value,
                autoUpdate: autoUpdateFilter?.value,
                revpos: revposFilter
            });
            
            await props.loadReleases(
                tenantId || undefined,
                currentPage,
                vesselId || undefined,
                buildChannelFilter?.value,
                autoUpdateFilter?.value,
                revposFilter || undefined
            );
        } catch (err) {
            setError('Failed to load releases. Please try again.');
            toast.error('Failed to load releases');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Load releases when tenant selection changes or page changes or vessel selection changes
        loadData();
    }, [props.selectedTenantOption, currentPage, selectedVesselOption, buildChannelFilter, autoUpdateFilter, revposFilter]);

    useEffect(() => {
        // Load vessels when tenant changes
        if (props.selectedTenantOption?.value) {
            props.loadVessels(props.selectedTenantOption.value);
        }
    }, [props.selectedTenantOption]);

    const tenantOptions = useMemo(
        () => getTenantsOptionList(props.tenants),
        [props.tenants.ids]
    );

    const vesselOptions = useMemo(() => {
        return props.vessels.ids.map((id: string) => ({
            value: props.vessels.byIds[id].id,
            label: props.vessels.byIds[id].vesselName
        }));
    }, [props.vessels.ids, props.vessels.byIds]);

    // Apply client-side filtering as a fallback mechanism
    const filteredReleases = useMemo(() => {
        let filtered = [...props.releases.data];
        
        // Apply client-side filtering if server filtering fails
        if (buildChannelFilter && filtered.length > 0) {
            filtered = filtered.filter(release => release.buildChannel === buildChannelFilter.value);
        }
        
        if (autoUpdateFilter && filtered.length > 0) {
            const isAutoUpdate = autoUpdateFilter.value === 'true';
            filtered = filtered.filter(release => release.autoUpdate === isAutoUpdate);
        }
        
        if (revposFilter && filtered.length > 0) {
            filtered = filtered.filter(release => release.revpos === parseInt(revposFilter));
        }
        
        return filtered;
    }, [props.releases.data, buildChannelFilter, autoUpdateFilter, revposFilter]);

    const handleSelectAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setSelectAll(checked);
        if (checked) {
            // Select all vessels on the current page
            const deviceIds = filteredReleases.map((release: IReleaseInfo) => release.deviceId);
            setSelectedVessels(deviceIds);
        } else {
            // Deselect all vessels
            setSelectedVessels([]);
        }
    };

    // Update selectAll state when filteredReleases changes
    useEffect(() => {
        if (selectedVessels.length > 0 && filteredReleases.length > 0) {
            // Check if all filtered vessels are selected
            const allSelected = filteredReleases.every(release => 
                selectedVessels.includes(release.deviceId)
            );
            setSelectAll(allSelected);
        } else {
            setSelectAll(false);
        }
    }, [selectedVessels, filteredReleases]);

    const handleVesselCheckboxChange = (deviceId: string) => {
        setSelectedVessels(prev => {
            if (prev.includes(deviceId)) {
                // Remove from selection
                const newSelection = prev.filter(id => id !== deviceId);
                // Update selectAll state
                if (newSelection.length === 0) {
                    setSelectAll(false);
                }
                return newSelection;
            } else {
                // Add to selection
                const newSelection = [...prev, deviceId];
                // Update selectAll state if all vessels on the page are selected
                if (newSelection.length === filteredReleases.length) {
                    setSelectAll(true);
                }
                return newSelection;
            }
        });
    };

    const handleDeployToSelected = () => {
        if (selectedVessels.length === 0) {
            toast.error('Please select at least one vessel');
            return;
        }
        setDeployModalType('selected');
        setIsDeployModalOpen(true);
    };

    const handleDeployToAll = () => {
        if (!props.selectedTenantOption?.value) {
            toast.error('Please select a tenant');
            return;
        }
        setDeployModalType('all');
        setIsDeployModalOpen(true);
    };

    const handleMultiDeploy = async (buildChannel: 'main' | 'hotfix') => {
        try {
            setIsLoading(true);
            setError(null);
            
            if (deployModalType === 'selected') {
                // Deploy to selected vessels
                await props.deployToMultipleVessels(selectedVessels, buildChannel);
                toast.success(`Release deployed to ${selectedVessels.length} vessels`);
            } else {
                // Deploy to all vessels in tenant
                if (!props.selectedTenantOption?.value) {
                    throw new Error('No tenant selected');
                }
                await props.deployToAllVessels(props.selectedTenantOption.value, buildChannel);
                toast.success('Release deployed to all vessels in tenant');
            }
            
            // Close the modal
            setIsDeployModalOpen(false);
            
            // Reset selection
            setSelectedVessels([]);
            setSelectAll(false);
            
            // Reload the releases list
            const tenantId = props.selectedTenantOption?.value;
            const vesselId = selectedVesselOption?.value;
            await props.loadReleases(
                tenantId || undefined,
                currentPage,
                vesselId || undefined
            );
        } catch (error) {
            setError('Failed to deploy release. Please try again.');
            toast.error('Failed to deploy release');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUploadSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        if (!uploadForm.buildFile) {
            return;
        }

        const uploadPromise = (async () => {
            try {
                setIsLoading(true);
                setError(null);
                const formData = new FormData();
                if (uploadForm.buildFile) {
                    formData.append('file', uploadForm.buildFile);
                }
                formData.append('frontendVersion', uploadForm.frontendVersion);
                formData.append('backendVersion', uploadForm.backendVersion);
                formData.append('changelog', uploadForm.changelog);
                formData.append('buildChannel', uploadForm.buildChannel);

                await props.uploadRelease(formData);

                // Reset form and close modal
                setUploadForm({
                    frontendVersion: '',
                    backendVersion: '',
                    changelog: '',
                    buildChannel: 'main',
                    buildFile: null
                });
                setIsUploadModalOpen(false);
                return 'Release uploaded successfully';
            } catch (error) {
                setError('Failed to upload release. Please try again.');
                throw new Error('Failed to upload release');
            } finally {
                setIsLoading(false);
            }
        })();

        toast.promise(uploadPromise, {
            loading: 'Uploading release...',
            success: (message) => message,
            error: (err) => err.message
        });
    };

    const handleDeployRelease = async (deviceId: string, buildChannel: 'main' | 'hotfix') => {
        try {
            setIsLoading(true);
            setError(null);
            await props.deployRelease(deviceId, buildChannel);
            toast.success('Release deployed successfully');
            
            // Reload the releases list
            const tenantId = props.selectedTenantOption?.value;
            const vesselId = selectedVesselOption?.value;
            await props.loadReleases(
                tenantId || undefined,
                currentPage,
                vesselId || undefined
            );
        } catch (error) {
            setError('Failed to deploy release. Please try again.');
            toast.error('Failed to deploy release');
        } finally {
            setIsLoading(false);
        }
    };

    const handleToggleAutoUpdate = async (deviceId: string, currentAutoUpdate: boolean) => {
        try {
            setIsLoading(true);
            await props.toggleAutoUpdate!(deviceId, !currentAutoUpdate);
            toast.success(`Auto-update ${!currentAutoUpdate ? 'enabled' : 'disabled'} successfully`);
            // Reload the releases to reflect the change
            await loadData();
        } catch (error) {
            console.error('Error toggling auto-update:', error);
            toast.error('Failed to toggle auto-update');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const renderPaginationNumbers = () => {
        const { currentPage, totalPages } = props.releases.pagination;
        const pages = [];
        const maxVisiblePages = 5;
        
        // Always show first page
        pages.push(
            <button
                key={1}
                className={classNames(
                    "w-9 h-9 px-2 py-2 flex items-center justify-center rounded-md text-sm font-medium transition-all duration-200 ease-in-out mx-1",
                    currentPage === 1
                        ? "bg-blue-600 text-white shadow-sm"
                        : "bg-white text-gray-700 hover:bg-gray-100 border border-gray-200"
                )}
                onClick={() => handlePageChange(1)}
            >
                1
            </button>
        );

        // Calculate range of visible page numbers
        let startPage = Math.max(2, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages - 1, startPage + maxVisiblePages - 1);
        
        // Adjust start if we're near the end
        if (endPage - startPage < maxVisiblePages - 1) {
            startPage = Math.max(2, endPage - maxVisiblePages + 2);
        }

        // Add ellipsis after first page if needed
        if (startPage > 2) {
            pages.push(
                <span key="ellipsis1" className="w-9 h-9 px-2 py-2 flex items-center justify-center text-gray-500 mx-1">
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                    </svg>
                </span>
            );
        }

        // Add page numbers
        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    className={classNames(
                        "w-9 h-9 px-2 py-2 flex items-center justify-center rounded-md text-sm font-medium transition-all duration-200 ease-in-out mx-1",
                        currentPage === i
                            ? "bg-blue-600 text-white shadow-sm"
                            : "bg-white text-gray-700 hover:bg-gray-100 border border-gray-200"
                    )}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </button>
            );
        }

        // Add ellipsis before last page if needed
        if (endPage < totalPages - 1) {
            pages.push(
                <span key="ellipsis2" className="w-9 h-9 flex items-center justify-center text-gray-500 mx-1">
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                    </svg>
                </span>
            );
        }

        // Always show last page if there is more than one page
        if (totalPages > 1) {
            pages.push(
                <button
                    key={totalPages}
                    className={classNames(
                        "w-9 h-9 px-2 py-2 flex items-center justify-center rounded-md text-sm font-medium transition-all duration-200 ease-in-out mx-1",
                        currentPage === totalPages
                            ? "bg-blue-600 text-white shadow-sm"
                            : "bg-white text-gray-700 hover:bg-gray-100 border border-gray-200"
                    )}
                    onClick={() => handlePageChange(totalPages)}
                >
                    {totalPages}
                </button>
            );
        }

        return pages;
    };

    return (
        <div className="bg-white h-[calc(100vh-98px)] m-2 rounded-md overflow-y-auto p-6">
            <Toaster 
                position="top-right"
                toastOptions={{
                    duration: 3000,
                    style: {
                        background: '#333',
                        color: '#fff',
                    },
                }}
            />
            
            <div className="flex justify-between items-center mb-8">
                <div className="flex items-center gap-4">
                    <button
                        onClick={() => window.history.back()}
                        className="text-gray-600 hover:text-gray-900 transition-colors"
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                    </button>
                    <h2 className="text-2xl font-semibold">Release Manager</h2>
                </div>
                <div className="flex gap-2">
                    {props.isSpecificAdmin && (
                        <button
                            className="inline-flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
                            onClick={() => setIsUploadModalOpen(true)}
                            disabled={isLoading}
                        >
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                            </svg>
                            Upload New Build
                        </button>
                    )}
                    <button
                        className="inline-flex items-center gap-2 bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition-colors"
                        onClick={props.onShowHistory}
                    >
                        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                        </svg>
                        View Release History
                    </button>
                </div>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg mb-6 border border-gray-200">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Filters</h3>
                <div className="flex flex-wrap gap-4">
                    <div className="w-64">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Tenant</label>
                        <Select
                            id="tenants"
                            isClearable
                            isSearchable
                            isDisabled={isLoading}
                            key={"tenants" + props.selectedTenantOption?.value}
                            placeholder={"Search tenants"}
                            unstyled
                            value={props.selectedTenantOption}
                            options={tenantOptions}
                            onChange={(newValue) => {
                                setCurrentPage(1);
                                props.setTenant(newValue ?? undefined);
                            }}
                            className={classNames("text-sm")}
                            menuPosition="fixed"
                            classNames={{
                                option: ({ isFocused, isSelected }) =>
                                    clsx(
                                        isFocused && optionStyles.focus,
                                        isSelected && optionStyles.selected,
                                        optionStyles.base
                                    ),
                                dropdownIndicator: () => dropdownIndicatorStyles,
                                menu: () => menuStyles,
                                clearIndicator: () => clearIndicatorStyles,
                                placeholder: () => placeholderStyles,
                                control: ({ isFocused, isDisabled }) =>
                                    clsx(
                                        isFocused ? controlStyles.focus : controlStyles.nonFocus,
                                        isDisabled ? controlStyles.disabled : controlStyles.base
                                    ),
                                valueContainer: ({ isDisabled }) =>
                                    clsx("pl-1", isDisabled && "bg-gray-200 ml-2"),
                            }}
                        />
                    </div>

                    <div className="w-64">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Vessel</label>
                        <Select
                            id="vessels"
                            isClearable
                            isSearchable
                            isDisabled={isLoading || !props.selectedTenantOption}
                            placeholder={"Search vessels"}
                            unstyled
                            value={selectedVesselOption}
                            options={vesselOptions}
                            onChange={(newValue) => {
                                console.log('Vessel selected:', newValue);
                                setSelectedVesselOption(newValue);
                            }}
                            className={classNames("text-sm")}
                            menuPosition="fixed"
                            classNames={{
                                option: ({ isFocused, isSelected }) =>
                                    clsx(
                                        isFocused && optionStyles.focus,
                                        isSelected && optionStyles.selected,
                                        optionStyles.base
                                    ),
                                dropdownIndicator: () => dropdownIndicatorStyles,
                                menu: () => menuStyles,
                                clearIndicator: () => clearIndicatorStyles,
                                placeholder: () => placeholderStyles,
                                control: ({ isFocused, isDisabled }) =>
                                    clsx(
                                        isFocused ? controlStyles.focus : controlStyles.nonFocus,
                                        isDisabled ? controlStyles.disabled : controlStyles.base
                                    ),
                                valueContainer: ({ isDisabled }) =>
                                    clsx("pl-1", isDisabled && "bg-gray-200 ml-2"),
                            }}
                        />
                    </div>
                    
                    <div className="w-64">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Build Channel</label>
                        <Select
                            id="buildChannel"
                            isClearable
                            isDisabled={isLoading}
                            placeholder={"Filter by channel"}
                            unstyled
                            value={buildChannelFilter}
                            options={[
                                { value: 'main', label: 'Main' },
                                { value: 'hotfix', label: 'Hotfix' }
                            ]}
                            onChange={(newValue) => {
                                setBuildChannelFilter(newValue);
                                setCurrentPage(1);
                            }}
                            className={classNames("text-sm")}
                            menuPosition="fixed"
                            classNames={{
                                option: ({ isFocused, isSelected }) =>
                                    clsx(
                                        isFocused && optionStyles.focus,
                                        isSelected && optionStyles.selected,
                                        optionStyles.base
                                    ),
                                dropdownIndicator: () => dropdownIndicatorStyles,
                                menu: () => menuStyles,
                                clearIndicator: () => clearIndicatorStyles,
                                placeholder: () => placeholderStyles,
                                control: ({ isFocused, isDisabled }) =>
                                    clsx(
                                        isFocused ? controlStyles.focus : controlStyles.nonFocus,
                                        isDisabled ? controlStyles.disabled : controlStyles.base
                                    ),
                                valueContainer: ({ isDisabled }) =>
                                    clsx("pl-1", isDisabled && "bg-gray-200 ml-2"),
                            }}
                        />
                    </div>
                    
                    <div className="w-64">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Update Status</label>
                        <Select
                            id="autoUpdate"
                            isClearable
                            isDisabled={isLoading}
                            placeholder={"Filter by update status"}
                            unstyled
                            value={autoUpdateFilter}
                            options={[
                                { value: 'true', label: 'Pending Updates' },
                                { value: 'false', label: 'No Updates' }
                            ]}
                            onChange={(newValue) => {
                                setAutoUpdateFilter(newValue);
                                setCurrentPage(1);
                            }}
                            className={classNames("text-sm")}
                            menuPosition="fixed"
                            classNames={{
                                option: ({ isFocused, isSelected }) =>
                                    clsx(
                                        isFocused && optionStyles.focus,
                                        isSelected && optionStyles.selected,
                                        optionStyles.base
                                    ),
                                dropdownIndicator: () => dropdownIndicatorStyles,
                                menu: () => menuStyles,
                                clearIndicator: () => clearIndicatorStyles,
                                placeholder: () => placeholderStyles,
                                control: ({ isFocused, isDisabled }) =>
                                    clsx(
                                        isFocused ? controlStyles.focus : controlStyles.nonFocus,
                                        isDisabled ? controlStyles.disabled : controlStyles.base
                                    ),
                                valueContainer: ({ isDisabled }) =>
                                    clsx("pl-1", isDisabled && "bg-gray-200 ml-2"),
                            }}
                        />
                    </div>
                    
                    <div className="w-64">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Revpos</label>
                        <input
                            type="number"
                            placeholder="Filter by revpos number"
                            className="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            value={revposFilter}
                            onChange={(e) => {
                                setRevposFilter(e.target.value);
                                setCurrentPage(1);
                            }}
                            disabled={isLoading}
                        />
                    </div>
                    
                    {props.selectedTenantOption && props.isSpecificAdmin && (
                        <div className="flex items-end">
                            <button
                                className="inline-flex items-center gap-2 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
                                onClick={handleDeployToAll}
                                disabled={isLoading || !props.selectedTenantOption}
                            >
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>
                                Deploy to All Vessels in Tenant
                            </button>
                        </div>
                    )}
                </div>
                
                {(buildChannelFilter || autoUpdateFilter || revposFilter) && (
                    <div className="mt-4 flex flex-wrap gap-2">
                        <span className="text-sm text-gray-500">Active filters:</span>
                        {buildChannelFilter && (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                Channel: {buildChannelFilter.label}
                                <button 
                                    type="button"
                                    className="ml-1.5 inline-flex items-center justify-center h-4 w-4 rounded-full text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none"
                                    onClick={() => setBuildChannelFilter(null)}
                                >
                                    <span className="sr-only">Remove filter</span>
                                    <svg className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </span>
                        )}
                        {autoUpdateFilter && (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                Status: {autoUpdateFilter.label}
                                <button 
                                    type="button"
                                    className="ml-1.5 inline-flex items-center justify-center h-4 w-4 rounded-full text-green-400 hover:bg-green-200 hover:text-green-500 focus:outline-none"
                                    onClick={() => setAutoUpdateFilter(null)}
                                >
                                    <span className="sr-only">Remove filter</span>
                                    <svg className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </span>
                        )}
                        {revposFilter && (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                                Revpos: {revposFilter}
                                <button 
                                    type="button"
                                    className="ml-1.5 inline-flex items-center justify-center h-4 w-4 rounded-full text-purple-400 hover:bg-purple-200 hover:text-purple-500 focus:outline-none"
                                    onClick={() => setRevposFilter('')}
                                >
                                    <span className="sr-only">Remove filter</span>
                                    <svg className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </span>
                        )}
                        <button
                            type="button"
                            className="text-sm text-red-600 hover:text-red-800"
                            onClick={() => {
                                setBuildChannelFilter(null);
                                setAutoUpdateFilter(null);
                                setRevposFilter('');
                                setCurrentPage(1);
                                
                                // Reload the data with no filters
                                const tenantId = props.selectedTenantOption?.value;
                                const vesselId = selectedVesselOption?.value;
                                props.loadReleases(tenantId || undefined, 1, vesselId || undefined);
                            }}
                        >
                            Clear all filters
                        </button>
                    </div>
                )}
            </div>

            {error && (
                <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg mb-6 flex items-center shadow-sm">
                    <svg className="h-5 w-5 text-red-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <span className="font-medium">{error}</span>
                </div>
            )}

            {isLoading && !props.releases.data.length ? (
                <div className="flex flex-col items-center justify-center h-64 bg-white rounded-lg shadow-sm border border-gray-200 p-8">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mb-4"></div>
                    <p className="text-lg font-medium text-gray-700">Loading releases...</p>
                    <p className="text-sm text-gray-500 mt-2">This may take a moment</p>
                </div>
            ) : props.releases.data.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-64 bg-white rounded-lg shadow-sm border border-gray-200 p-8">
                    <div className="bg-gray-100 p-4 rounded-full mb-4">
                        <svg className="w-12 h-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
                        </svg>
                    </div>
                    <p className="text-xl font-medium text-gray-800">No releases found</p>
                    <p className="text-sm text-gray-500 mt-2 text-center max-w-md">
                        Try selecting a different tenant or vessel, or adjust your filters to see more results.
                    </p>
                    <button 
                        className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                        onClick={() => {
                            setBuildChannelFilter(null);
                            setAutoUpdateFilter(null);
                            setRevposFilter('');
                            setCurrentPage(1);
                            
                            // Reload the data with no filters
                            const tenantId = props.selectedTenantOption?.value;
                            const vesselId = selectedVesselOption?.value;
                            props.loadReleases(tenantId || undefined, 1, vesselId || undefined);
                        }}
                    >
                        <svg className="mr-2 -ml-1 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                        Reset Filters
                    </button>
                </div>
            ) : (
                <>
                    <div className="relative">
                        {isLoading && (
                            <div className="absolute inset-0 bg-white bg-opacity-70 flex items-center justify-center z-10 backdrop-blur-sm">
                                <div className="flex flex-col items-center bg-white p-6 rounded-lg shadow-md border border-gray-200">
                                    <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
                                    <p className="mt-4 text-gray-700 font-medium">Updating...</p>
                                </div>
                            </div>
                        )}
                        <div className="shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg bg-white">
                            <div className="flex justify-between items-center p-4 border-b border-gray-200">
                                <div className="flex items-center">
                                    {props.isSpecificAdmin && (
                                        <div className="relative inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-500 mr-3 cursor-pointer"
                                                checked={selectAll}
                                                onChange={handleSelectAllChange}
                                                disabled={isLoading}
                                            />
                                            <span className="text-sm font-medium text-gray-700">
                                                {selectedVessels.length > 0 ? (
                                                    <span className="flex items-center">
                                                        <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded-full mr-2">
                                                            {selectedVessels.length}
                                                        </span>
                                                        vessels selected
                                                    </span>
                                                ) : 'Select all'}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                {selectedVessels.length > 0 && props.isSpecificAdmin && (
                                    <button
                                        className="inline-flex items-center gap-2 bg-blue-500 text-white px-3 py-1.5 rounded hover:bg-blue-600 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed text-sm font-medium"
                                        onClick={handleDeployToSelected}
                                        disabled={isLoading}
                                    >
                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                        </svg>
                                        Deploy to Selected ({selectedVessels.length})
                                    </button>
                                )}
                            </div>
                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead>
                                        <tr className="bg-gray-50">
                                            {props.isSpecificAdmin && (
                                                <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    <span className="sr-only">Select</span>
                                                </th>
                                            )}
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                S.No
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Vessel Name
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Frontend Version
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Backend Version
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Changelog
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Revpos
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Build Channel
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Auto Update
                                            </th>
                                            {props.isSpecificAdmin && (
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Actions
                                                </th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {filteredReleases.map((release: IReleaseInfo, index: number) => (
                                            <tr key={index} className="hover:bg-gray-50 transition-colors duration-150">
                                                {props.isSpecificAdmin && (
                                                    <td className="px-4 py-4 whitespace-nowrap">
                                                        <div className="flex items-center">
                                                            <input
                                                                type="checkbox"
                                                                className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-500 cursor-pointer"
                                                                checked={selectedVessels.includes(release.deviceId)}
                                                                onChange={() => handleVesselCheckboxChange(release.deviceId)}
                                                                disabled={isLoading}
                                                            />
                                                        </div>
                                                    </td>
                                                )}
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-500">
                                                        {((currentPage - 1) * props.releases.pagination.itemsPerPage) + index + 1}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-medium text-gray-900">
                                                        {release.vesselName}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span className="px-2 py-1 inline-flex text-xs leading-5 font-medium rounded-md bg-blue-50 text-blue-700 border border-blue-100">
                                                        {release.frontendVersion}
                                                    </span>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span className="px-2 py-1 inline-flex text-xs leading-5 font-medium rounded-md bg-green-50 text-green-700 border border-green-100">
                                                        {release.backendVersion}
                                                    </span>
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="text-sm text-gray-500 max-w-xs truncate">
                                                        {release.changelog}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-500 font-mono">
                                                        {release.revpos || '-'}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span className={classNames(
                                                        "px-2 py-1 inline-flex items-center text-xs leading-5 font-medium rounded-full",
                                                        (release.buildChannel || 'main') === 'main' 
                                                            ? "bg-green-100 text-green-800 border border-green-200" 
                                                            : "bg-orange-100 text-orange-800 border border-orange-200"
                                                    )}>
                                                        {(release.buildChannel || 'main') === 'main' ? (
                                                            <svg className="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20">
                                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4z" clipRule="evenodd" />
                                                            </svg>
                                                        ) : (
                                                            <svg className="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20">
                                                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                            </svg>
                                                        )}
                                                        {release.buildChannel || 'main'}
                                                    </span>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center justify-between">
                                                        <span className={classNames(
                                                            "px-2 py-1 inline-flex items-center text-xs leading-5 font-medium rounded-full",
                                                            release.autoUpdate 
                                                                ? "bg-blue-100 text-blue-800 border border-blue-200" 
                                                                : "bg-gray-100 text-gray-800 border border-gray-200"
                                                        )}>
                                                            {release.autoUpdate ? (
                                                                <>
                                                                    <svg className="w-3 h-3 mr-1 text-blue-600" fill="currentColor" viewBox="0 0 20 20">
                                                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
                                                                    </svg>
                                                                    Pending
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <svg className="w-3 h-3 mr-1" fill="currentColor" viewBox="0 0 20 20">
                                                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4z" clipRule="evenodd" />
                                                                    </svg>
                                                                    Up to date
                                                                </>
                                                            )}
                                                        </span>
                                                        
                                                            <div className="ml-3 flex items-center">
                                                                <button
                                                                    onClick={() => handleToggleAutoUpdate(release.deviceId, !!release.autoUpdate)}
                                                                    disabled={isLoading}
                                                                    className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${!!release.autoUpdate ? 'bg-blue-600' : 'bg-gray-200'}`}
                                                                    role="switch"
                                                                    aria-checked={!!release.autoUpdate}
                                                                >
                                                                    <span className="sr-only">Toggle auto-update</span>
                                                                    <span
                                                                        aria-hidden="true"
                                                                        className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${!!release.autoUpdate ? 'translate-x-5' : 'translate-x-0'}`}
                                                                    ></span>
                                                                </button>
                                                                <span className="ml-2 text-xs font-medium text-gray-700">
                                                                    {!!release.autoUpdate ? 'Enabled' : 'Disabled'}
                                                                </span>
                                                            </div>
                                                       
                                                    </div>
                                                </td>
                                                {props.isSpecificAdmin && (
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="flex gap-2">
                                                            <button
                                                                className="text-blue-600 hover:text-blue-900 bg-blue-50 hover:bg-blue-100 px-2 py-1 rounded transition-colors text-xs font-medium border border-blue-200"
                                                                onClick={() => handleDeployRelease(release.deviceId, 'main')}
                                                                disabled={isLoading}
                                                            >
                                                                <span className="flex items-center">
                                                                    <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                                                    </svg>
                                                                    Main
                                                                </span>
                                                            </button>
                                                            <button
                                                                className="text-red-600 hover:text-red-900 bg-red-50 hover:bg-red-100 px-2 py-1 rounded transition-colors text-xs font-medium border border-red-200"
                                                                onClick={() => handleDeployRelease(release.deviceId, 'hotfix')}
                                                                disabled={isLoading}
                                                            >
                                                                <span className="flex items-center">
                                                                    <svg className="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                                                                    </svg>
                                                                    Hotfix
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* Pagination */}
                    {props.releases.pagination && (
                        <div className="flex justify-between items-center mt-5 bg-white p-4 border border-gray-200 rounded-lg">
                            <div className="text-sm text-gray-700">
                                Showing <span className="font-medium">{((currentPage - 1) * props.releases.pagination.itemsPerPage) + 1}</span> to <span className="font-medium">{Math.min(currentPage * props.releases.pagination.itemsPerPage, props.releases.pagination.totalItems)}</span> of <span className="font-medium">{props.releases.pagination.totalItems}</span> entries
                            </div>
                            <div className="flex items-center">
                                <button
                                    className={classNames(
                                        "relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 mr-2",
                                        currentPage === 1 && "cursor-not-allowed opacity-50"
                                    )}
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1 || isLoading}
                                >
                                    <span className="sr-only">Previous</span>
                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                    </svg>
                                </button>
                                <div className="flex space-x-1 mx-1">
                                    {renderPaginationNumbers()}
                                </div>
                                <button
                                    className={classNames(
                                        "relative inline-flex items-center px-2 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ml-2",
                                        currentPage === props.releases.pagination.totalPages && "cursor-not-allowed opacity-50"
                                    )}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === props.releases.pagination.totalPages || isLoading}
                                >
                                    <span className="sr-only">Next</span>
                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    )}
                </>
            )}

            {/* Upload Modal */}
            {isUploadModalOpen && props.isSpecificAdmin && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg w-[500px] shadow-xl">
                        <div className="flex justify-between items-center mb-4">
                            <h3 className="text-xl font-semibold">Upload New Build</h3>
                            <button
                                onClick={() => setIsUploadModalOpen(false)}
                                className="text-gray-400 hover:text-gray-600 transition-colors"
                            >
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <form onSubmit={handleUploadSubmit}>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">Frontend Version</label>
                                <input
                                    type="text"
                                    className="w-full border rounded p-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    value={uploadForm.frontendVersion}
                                    onChange={(e) => setUploadForm({...uploadForm, frontendVersion: e.target.value})}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">Backend Version</label>
                                <input
                                    type="text"
                                    className="w-full border rounded p-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    value={uploadForm.backendVersion}
                                    onChange={(e) => setUploadForm({...uploadForm, backendVersion: e.target.value})}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">Changelog</label>
                                <textarea
                                    className="w-full border rounded p-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    value={uploadForm.changelog}
                                    onChange={(e) => setUploadForm({...uploadForm, changelog: e.target.value})}
                                    required
                                    rows={4}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">Build Channel</label>
                                <select
                                    className="w-full border rounded p-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                    value={uploadForm.buildChannel}
                                    onChange={(e) => setUploadForm({...uploadForm, buildChannel: e.target.value as 'main' | 'hotfix'})}
                                >
                                    <option value="main">Main</option>
                                    <option value="hotfix">Hotfix</option>
                                </select>
                            </div>
                            <div className="mb-6">
                                <label className="block text-sm font-medium text-gray-700 mb-1">Build File (ZIP)</label>
                                <input
                                    type="file"
                                    accept=".zip"
                                    onChange={(e) => setUploadForm({...uploadForm, buildFile: e.target.files?.[0] || null})}
                                    required
                                    className="w-full border rounded p-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                            <div className="flex justify-end gap-2">
                                <button
                                    type="button"
                                    className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    onClick={() => setIsUploadModalOpen(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="inline-flex items-center gap-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <>
                                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Uploading...
                                        </>
                                    ) : (
                                        <>
                                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                            </svg>
                                            Upload
                                        </>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Deploy Modal */}
            {props.isSpecificAdmin && (
                <DeployModal
                    isOpen={isDeployModalOpen}
                    onClose={() => setIsDeployModalOpen(false)}
                    onDeploy={handleMultiDeploy}
                    isLoading={isLoading}
                    deployType={deployModalType}
                    selectedCount={selectedVessels.length}
                    selectedTenantOption={props.selectedTenantOption}
                />
            )}
        </div>
    );
}

function mapStateToProps(store: IStore) {
    const toolStore = (store as any).app.tool;
    const authStore = store.app.auth;
    const isAdmin = authStore.user?.roleCategory === "admin";
    const isSpecificAdmin = isAdmin && authStore.user?.email?.toLowerCase() === "abdul.ghani@volteo.com";
    
    return {
        tenants: toolStore?.tenants || { byIds: {}, ids: [] },
        vessels: (toolStore?.vessels || { byIds: {}, ids: [] }) as IVesselStore,
        selectedTenantOption: toolStore?.selectedTenantOption,
        releases: toolStore?.releases || { data: [], pagination: { currentPage: 1, totalPages: 1, totalItems: 0, itemsPerPage: 10 } },
        isAdmin,
        isSpecificAdmin
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        loadTenants() {
            dispatch(toolActions.commands.toolTenantListLoad());
        },
        loadVessels(tenantId: string) {
            dispatch(toolActions.commands.toolTenantSpecificVesselLoad(tenantId));
        },
        loadReleases(tenantId?: string, page: number = 1, vesselId?: string, buildChannel?: string, autoUpdate?: string, revpos?: string) {
            return new Promise((resolve, reject) => {
                dispatch(toolActions.commands.toolReleasesListLoad(tenantId, page, vesselId, buildChannel, autoUpdate, revpos));
                resolve(true);
            });
        },
        uploadRelease(formData: FormData) {
            return new Promise((resolve, reject) => {
                dispatch(apiActions.apiRequest({
                    ...toolAPIs.uploadRelease(formData),
                    postExecute: {
                        onSuccess: resolve,
                        onError: reject
                    }
                }));
            });
        },
        deployRelease(deviceId: string, buildChannel: 'main' | 'hotfix') {
            return new Promise((resolve, reject) => {
                dispatch(apiActions.apiRequest({
                    ...toolAPIs.deployRelease(deviceId, buildChannel),
                    postExecute: {
                        onSuccess: resolve,
                        onError: reject
                    }
                }));
            });
        },
        deployToMultipleVessels(deviceIds: string[], buildChannel: 'main' | 'hotfix') {
            return new Promise((resolve, reject) => {
                dispatch(apiActions.apiRequest({
                    ...toolAPIs.deployToMultipleVessels(deviceIds, buildChannel),
                    postExecute: {
                        onSuccess: resolve,
                        onError: reject
                    }
                }));
            });
        },
        deployToAllVessels(tenantId: string, buildChannel: 'main' | 'hotfix') {
            return new Promise((resolve, reject) => {
                dispatch(apiActions.apiRequest({
                    ...toolAPIs.deployToAllVessels(tenantId, buildChannel),
                    postExecute: {
                        onSuccess: resolve,
                        onError: reject
                    }
                }));
            });
        },
        setTenant(tenantOption?: IOption) {
            dispatch(toolActions.document.toolReleaseTenantOptionSelect(tenantOption));
        },
        clearReleases() {
            dispatch(toolActions.document.toolReleasesListSet([], {
                currentPage: 1,
                totalPages: 1,
                totalItems: 0,
                itemsPerPage: 10
            }));
        },
        toggleAutoUpdate(deviceId: string, autoUpdate: boolean) {
            return new Promise((resolve, reject) => {
                dispatch(apiActions.apiRequest({
                    ...toolAPIs.toggleAutoUpdate(deviceId, autoUpdate),
                    postExecute: {
                        onSuccess: resolve,
                        onError: reject
                    }
                }));
            });
        }
    };
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector> & {
    toggleAutoUpdate?: (deviceId: string, autoUpdate: boolean) => Promise<any>;
};
export default reduxConnector(ReleaseManager); 
