import React, { FC } from 'react';
import classNames from 'classnames';
import InputWrapper from 'components/_common/InputWrapper';

interface IWayshipTextAreaProps {
    title?: string;
    dataKey: string;
    value: string;
    isSubmit?: boolean;
    error?: any;
    className?: string;
	id?: string;
	required?: boolean;
    onChange: (value: string, dataKey: string) => void;
    onBlurHandler?: (value: any, dataKey: string) => void;
}

const WayshipTextArea: FC<IWayshipTextAreaProps> = (props) => {
    const { title, dataKey, value, isSubmit, error, className, id, required, onBlurHandler } = props;

    return (
        <div className={classNames("", className)}>
            <InputWrapper
                className="w-full"
                hasError={
                    error && error.hasOwnProperty('isValid')
                        ? !error.isValid
                        : false
                }
                hasWarning={false}
            >

				<label htmlFor="remarks" className="ws-input__label">
					{title} {required && <span className='text-red-400'>*</span>}
				</label>
				<textarea
					name="remark-input-id"
					id={id ?? 'remarks'}
					className="ws-input"
					placeholder=""
					disabled={isSubmit}
					rows={4}
					value={value || ''}
					onChange={(e) => {
						props.onChange(e.target.value, dataKey);
					}}
					onBlur={(e) => {
						if (onBlurHandler && dataKey) {
							onBlurHandler(e.target.value, dataKey);
						}
					}}
				/>
				<span className="ws-input__error">
					{error?.message || 'some error'}
				</span>
            </InputWrapper>
        </div>
    );
};
export default WayshipTextArea;
