import classNames from 'classnames';
import React from 'react';
import InputWrapper from '../../components/_common/InputWrapper';

type WayshipDateProps = {
    title: string;
    dataKey?: string;
    value: any;
    onChangeHandler?: (value: any, dataKey: string) => void;
    type?: string;
    isSubmit?: boolean;
    onFocus?: (title: string) => void;
    onBlurHandler?: (value: any, dataKey: string) => void;
    onKeyDown?: (e: any) => void;
    required?: boolean;
    error?: any;
    placeholder?: string;
    onLoad?: () => void;
    min?: any;
    max?: any;
};

function WayshipDate(props: WayshipDateProps) {
    const {
        title,
        dataKey,
        value,
        onChangeHandler,
        type,
        isSubmit,
        onFocus,
        onBlurHandler,
        onKeyDown,
        required,
        error,
        placeholder,
        onLoad,
    } = props;

    React.useEffect(() => {
        // if onLoad is defined, call it after 2 seconds of component mounting
        setTimeout(() => {
            onLoad && onLoad();
        }, 2000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <InputWrapper
            className="w-full"
            hasWarning={false}
            inputValue={value}
            hasError={
                error && error.hasOwnProperty('isValid')
                    ? !error.isValid
                    : false
            }
        >
            <label htmlFor="text-input-random" className="ws-input__label">
                {title} {required && <span className='text-red-400'>*</span>}
            </label>
            <input
                type={type ?? 'text'}
                name="text-input-random"
				id={dataKey}
                className={classNames('ws-input', {
                    'ws-input--read-only': isSubmit,
                })}
                placeholder={placeholder || ''}
                value={value || ''}
                onChange={(e) => {
                    if (onChangeHandler && dataKey) {
                        onChangeHandler(e.target.value, dataKey);
                    }
                }}
                disabled={isSubmit}
                onKeyDown={onKeyDown}
                onFocus={() => {
                    if (onFocus) {
                        onFocus(title);
                    } else {
                        console.log('onFocus not provided!');
                    }
                }}
                onBlur={(e) => {
                    if (onBlurHandler && dataKey) {
                        onBlurHandler(e.target.value, dataKey);
                    }
                }}
                required={required ?? false}
                min={props.min}
                max={props.max}
            />
            {/* <ReactJson src={{ value }} /> */}
            <span className="ws-input__error">{error?.message || ''}</span>
            <span className="ws-input__warning">Hello Warning</span>
        </InputWrapper>
    );
}

export default WayshipDate;
