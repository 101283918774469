import NavNucReport from "components/app/report/nav-nuc/nav-nuc/NavNucReport";
import React from "react";

type ScreenNavNucReportProps = React.PropsWithChildren<{}>;

function ScreenNavNucReport(props: ScreenNavNucReportProps) {
	return <NavNucReport />;
}

export default ScreenNavNucReport;
