import React, { useState } from "react";
import { ITabReport } from "redux/app/logs/@types";
import classNames from "classnames";
import moment from "moment";

interface IDateTimeChangeFormProps {
    report: ITabReport;
    onClose: () => void;
    onSubmit: (data: any) => void;
}

const DateTimeChangeForm: React.FC<IDateTimeChangeFormProps> = ({
    report,
    onClose,
    onSubmit,
}) => {
    const [selectedReport, setSelectedReport] = useState<number | null>(null);
    const [fromDate, setFromDate] = useState<string>("");
    const [fromTime, setFromTime] = useState<string>("");
    const [fromTimezone, setFromTimezone] = useState<string>("");
    const [toDate, setToDate] = useState<string>("");
    const [toTime, setToTime] = useState<string>("");
    const [toTimezone, setToTimezone] = useState<string>("");
    const [error, setError] = useState<string | null>(null);

    const TIMEZONES = Array.from({ length: 25 }, (_, i) => {
        const offset = i - 12;
        const sign = offset >= 0 ? "+" : "-";
        const hours = Math.abs(offset).toString().padStart(2, "0");
        return `${sign}${hours}:00`;
    });

    // Handle report selection and auto-populate date/time fields
    const handleReportSelect = (index: number) => {
        setSelectedReport(index);
        const selectedReportData = report.timeline[index];
        setFromDate(selectedReportData.from_local_date || "");
        setFromTime(selectedReportData.from_local_time || "");
        setFromTimezone(selectedReportData.from_zd || "");
        setToDate(selectedReportData.to_local_date || "");
        setToTime(selectedReportData.to_local_time || "");
        setToTimezone(selectedReportData.to_zd || "");
    };

    const validateDateTime = (fromDateTime: moment.Moment, toDateTime: moment.Moment): boolean => {
        const timeline = report.timeline || [];
        const currentReport = timeline[selectedReport!];
        const prevReport = timeline[selectedReport! - 1];
        const nextReport = timeline[selectedReport! + 1];

        // Check if the new datetime maintains chronological order
        if (prevReport) {
            const prevDateTime = moment(`${prevReport.to_local_date} ${prevReport.to_local_time}`, "YYYY-MM-DD HH:mm");
            if (fromDateTime.isBefore(prevDateTime)) {
                setError(`From date/time cannot be before the previous report (${prevReport.report_type})`);
                return false;
            }
        }

        if (nextReport) {
            const nextDateTime = moment(`${nextReport.from_local_date} ${nextReport.from_local_time}`, "YYYY-MM-DD HH:mm");
            if (toDateTime.isAfter(nextDateTime)) {
                setError(`To date/time cannot be after the next report (${nextReport.report_type})`);
                return false;
            }
        }

        // Ensure "to" datetime is not before "from" datetime
        if (toDateTime.isBefore(fromDateTime)) {
            setError("To date/time cannot be before From date/time");
            return false;
        }

        return true;
    };

    const handleSubmit = () => {
        if (selectedReport === null) {
            setError("Please select a report");
            return;
        }

        if (!fromDate || !fromTime || !fromTimezone || !toDate || !toTime || !toTimezone) {
            setError("Please fill in all date, time, and timezone fields");
            return;
        }

        const fromDateTime = moment(`${fromDate} ${fromTime}`, "YYYY-MM-DD HH:mm");
        const toDateTime = moment(`${toDate} ${toTime}`, "YYYY-MM-DD HH:mm");

        if (!validateDateTime(fromDateTime, toDateTime)) {
            return;
        }

        // Create backup before date/time change
        const backup = {
            ...report,
            modelName: "backup",
            backupNote: `Changed date/time for ${report.timeline[selectedReport].report_type}`,
            backupDate: moment().toISOString()
        };

        onSubmit({
            reportIndex: selectedReport,
            fromDate,
            fromTime,
            fromTimezone,
            toDate,
            toTime,
            toTimezone,
            backup
        });
    };

    return (
        <div className="p-4">
            <h3 className="mb-4 text-lg font-medium text-gray-900">
                Change Date/Time
            </h3>

            {/* Report Selection */}
            <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                    Select Report
                </label>
                <select
                    className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md"
                    value={selectedReport ?? ""}
                    onChange={(e) => handleReportSelect(Number(e.target.value))}
                >
                    <option value="">Select a report...</option>
                    {(report.timeline || []).map((item, index) => (
                        <option key={index} value={index}>
                            {item.report_type} ({item.from_local_date} {item.from_local_time} {item.from_zd} to {item.to_local_date} {item.to_local_time} {item.to_zd})
                        </option>
                    ))}
                </select>
            </div>

            {/* From Date/Time/Timezone Selection */}
            <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                    From Date/Time/Timezone
                </label>
                <div className="grid grid-cols-3 gap-4">
                    <div>
                        <input
                            type="date"
                            className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md"
                            value={fromDate}
                            onChange={(e) => setFromDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <input
                            type="time"
                            className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md"
                            value={fromTime}
                            onChange={(e) => setFromTime(e.target.value)}
                        />
                    </div>
                    <div>
                        <select
                            className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md"
                            value={fromTimezone}
                            onChange={(e) => setFromTimezone(e.target.value)}
                        >
                            <option value="">Select timezone...</option>
                            {TIMEZONES.map((tz) => (
                                <option key={tz} value={tz}>
                                    UTC {tz}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            {/* To Date/Time/Timezone Selection */}
            <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                    To Date/Time/Timezone
                </label>
                <div className="grid grid-cols-3 gap-4">
                    <div>
                        <input
                            type="date"
                            className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md"
                            value={toDate}
                            onChange={(e) => setToDate(e.target.value)}
                        />
                    </div>
                    <div>
                        <input
                            type="time"
                            className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md"
                            value={toTime}
                            onChange={(e) => setToTime(e.target.value)}
                        />
                    </div>
                    <div>
                        <select
                            className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md"
                            value={toTimezone}
                            onChange={(e) => setToTimezone(e.target.value)}
                        >
                            <option value="">Select timezone...</option>
                            {TIMEZONES.map((tz) => (
                                <option key={tz} value={tz}>
                                    UTC {tz}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            {error && (
                <div className="p-3 mb-4 text-sm text-red-700 bg-red-100 rounded-md">
                    {error}
                </div>
            )}

            {/* Warning Message */}
            {selectedReport !== null && fromDate && fromTime && fromTimezone && toDate && toTime && toTimezone && (
                <div className="p-3 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-md">
                    Warning: This will change both the from and to date/time/timezone of the selected report.
                    Please ensure this change maintains the chronological sequence of events.
                    A backup will be created before the change.
                </div>
            )}

            {/* Action Buttons */}
            <div className="flex justify-end gap-3">
                <button
                    onClick={onClose}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                    Cancel
                </button>
                <button
                    onClick={handleSubmit}
                    disabled={selectedReport === null || !fromDate || !fromTime || !fromTimezone || !toDate || !toTime || !toTimezone}
                    className={classNames(
                        "px-4 py-2 text-sm font-medium text-white rounded-md",
                        {
                            "bg-blue-600 hover:bg-blue-700": selectedReport !== null && fromDate && fromTime && fromTimezone && toDate && toTime && toTimezone,
                            "bg-gray-300 cursor-not-allowed": selectedReport === null || !fromDate || !fromTime || !fromTimezone || !toDate || !toTime || !toTimezone,
                        }
                    )}
                >
                    Change Date/Time
                </button>
            </div>
        </div>
    );
};

export default DateTimeChangeForm; 