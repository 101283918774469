import {
	IAuthStore,
	IAuthFormLogin,
	IAuth_Get_LoginData,
	IAuthFormResetRequest,
	IAuth_Get_ResetRequestData,
	IAuth_Get_ResetPasswordData,
	IAuth_Get_Jobs,
} from "./types";
import { formSelectors } from "../../_core/form";
import { jobSelectors } from "../../_core/job";
import actions from "./actions";
import { getType } from "typesafe-actions";
import { DEV_EMAIL } from "redux/_common/utils";
const authSelectors = (authStore: IAuthStore) => {
	const isLoggedIn = () => (authStore.authToken ? true : false);
	const isLoggedOut = () => !isLoggedIn();
	const getLoggedInUser = () => authStore.user;
	const getLoginData = (): IAuth_Get_LoginData => {
		return {
			form: formSelectors(authStore._forms.LOGIN),
			loginJob: jobSelectors.getJobStatus(
				authStore,
				getType(actions.login)
			),
		};
	};
	const isPassFieldVisible = () => (authStore.isPassFieldVisible)
	const getResetRequestData = (): IAuth_Get_ResetRequestData => {
		return {
			form: formSelectors(authStore._forms.RESET_REQUEST),
			resetRequestJob: jobSelectors.getJobStatus(
				authStore,
				getType(actions.resetRequest)
			),
		};
	};
	const getResetPasswordData = (): IAuth_Get_ResetPasswordData => {
		return {
			form: formSelectors(authStore._forms.RESET_PASSWORD),
			resetPasswordJob: jobSelectors.getJobStatus(
				authStore,
				getType(actions.resetPassword)
			),
		};
	};

	const getAllJobs = (): IAuth_Get_Jobs => ({
		loginJob: jobSelectors.getJobStatus(authStore, getType(actions.login)),
		resetRequestJob: jobSelectors.getJobStatus(
			authStore,
			getType(actions.resetRequest)
		),
		resetPasswordJob: jobSelectors.getJobStatus(
			authStore,
			getType(actions.resetPassword)
		),
	});

	const isUserAllowedToAccessModule = (
		moduleName?: "feedback" | "tools" | "cnrReport" | string
	): boolean | null => {
		if (!moduleName) {
			return false;
		}
		const user = getLoggedInUser();
		if (user) {
			// if user email is DEV_EMAIL
			if (user.email === DEV_EMAIL) {
				return true;
			}

			// if user roleCategory is "developer" or "support"
			if (
				user.roleCategory === "developer" ||
				user.roleCategory === "support" ||
				user.roleCategory === "admin"
			) {
				// the user's email must have the domain "volteo.com" or "volteomaritime.com"
				if (
					user.email?.toLowerCase().endsWith("@volteo.com") ||
					user.email?.toLowerCase().endsWith("@volteomaritime.com")
				) {
					return true;
				}
				return false;
			}
		}

		// console.log("returning null");
		// user is not present, so forcefully logout user by returning null

		return null;
	};

	const allowedModulesForThirdParty = ["reports"];

	const isUserThirdParty = () => {
		const user = getLoggedInUser();
		if (user) {
			return user.roleCategory === "external";
		}
		return false;
	};

	const isUserAdmin = () => {
		const user = getLoggedInUser();
		if (user) {
			return user.roleCategory === "admin";
		}
		return false;
	};

	const isUserAllowedToUseCNRTool = () => {
		const user = getLoggedInUser();
		if (user) {
			return user.roleCategory === "admin" && (user.email?.toLowerCase() === "abdul.ghani@volteo.com" || user.email?.toLowerCase() === "eps@wayship.com");
		}
		return false;
	};

	const getAuthorisedModules = () => authStore?.authorisedModules;

	const getTenantInfo = () => authStore?.tenantInfo;

	const getTenantCode = () => {
		const tenantInfo = getTenantInfo();
		const tenantId = tenantInfo?._id;

		const tenantConstants: any = {
			"1076c7c10b69e86246f3910b62fc78f5": "TEEKAY",
			"434d9b50-5703-11eb-8d4a-6b516664bdbd": "EPS",
		};
		if (tenantId) {
			if (tenantId in tenantConstants) {
				return tenantConstants[tenantId];
			}
			return tenantInfo?.name;
		}

		return null;
	};

	return {
		isLoggedIn,
		isLoggedOut,
		getLoggedInUser,
		isPassFieldVisible,
		getLoginData,
		getResetRequestData,
		getResetPasswordData,
		getAllJobs,
		isUserAllowedToAccessModule,
		getAuthorisedModules,
		getTenantInfo,
		getTenantCode,
		isUserAdmin,
		isUserThirdParty,
		allowedModulesForThirdParty,
		isUserAllowedToUseCNRTool,
	};
};

export default authSelectors;
