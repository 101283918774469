import React, { useState } from "react";
import { ITabReport } from "redux/app/logs/@types";
import classNames from "classnames";
import moment from "moment";

interface IDeleteReportFormProps {
    report: ITabReport;
    onClose: () => void;
    onSubmit: (data: any) => void;
}

const DeleteReportForm: React.FC<IDeleteReportFormProps> = ({
    report,
    onClose,
    onSubmit,
}) => {
    const [selectedReports, setSelectedReports] = useState<number[]>([]);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = () => {
        if (selectedReports.length === 0) {
            setError("Please select at least one report to delete");
            return;
        }

        // Create backup before deletion
        const backup = {
            ...report,
            modelName: "backup",
            backupNote: `Deleted ${selectedReports.length} reports: ${selectedReports
                .map((index) => report.timeline[index].report_type)
                .join(", ")}`,
            backupDate: moment().toISOString()
        };

        onSubmit({
            reportIndicesToDelete: selectedReports,
            backup
        });
    };

    const toggleReportSelection = (index: number) => {
        setSelectedReports(prev => 
            prev.includes(index)
                ? prev.filter(i => i !== index)
                : [...prev, index]
        );
    };

    const handleSelectAll = () => {
        if (selectedReports.length === (report.timeline || []).length) {
            setSelectedReports([]);
        } else {
            setSelectedReports((report.timeline || []).map((_, i) => i));
        }
    };

    return (
        <div className="p-4">
            <h3 className="mb-4 text-lg font-medium text-gray-900">
                Delete Reports
            </h3>

            {/* Report Selection */}
            <div className="mb-4">
                <div className="flex items-center justify-between mb-2">
                    <label className="text-sm font-medium text-gray-700">
                        Select Reports to Delete
                    </label>
                    <button
                        onClick={handleSelectAll}
                        className="text-sm text-blue-600 hover:text-blue-800"
                    >
                        {selectedReports.length === (report.timeline || []).length
                            ? "Deselect All"
                            : "Select All"}
                    </button>
                </div>
                <div className="border rounded-md divide-y">
                    {(report.timeline || []).map((item, index) => (
                        <div
                            key={index}
                            className={classNames(
                                "flex items-center p-3 hover:bg-gray-50",
                                {
                                    "bg-blue-50": selectedReports.includes(index),
                                }
                            )}
                        >
                            <input
                                type="checkbox"
                                className="w-4 h-4 text-blue-600 border-gray-300 rounded"
                                checked={selectedReports.includes(index)}
                                onChange={() => toggleReportSelection(index)}
                            />
                            <div className="ml-3">
                                <p className="text-sm font-medium text-gray-900">
                                    {item.report_type}
                                </p>
                                <p className="text-sm text-gray-500">
                                    {item.to_local_date} {item.to_local_time}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {error && (
                <div className="p-3 mb-4 text-sm text-red-700 bg-red-100 rounded-md">
                    {error}
                </div>
            )}

            {/* Warning Message */}
            {selectedReports.length > 0 && (
                <div className="p-3 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-md">
                    Warning: This action will delete {selectedReports.length} report(s) and cannot be undone.
                    A backup will be created before deletion.
                </div>
            )}

            {/* Action Buttons */}
            <div className="flex justify-end gap-3">
                <button
                    onClick={onClose}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                    Cancel
                </button>
                <button
                    onClick={handleSubmit}
                    disabled={selectedReports.length === 0}
                    className={classNames(
                        "px-4 py-2 text-sm font-medium text-white rounded-md",
                        {
                            "bg-red-600 hover:bg-red-700": selectedReports.length > 0,
                            "bg-gray-300 cursor-not-allowed": selectedReports.length === 0,
                        }
                    )}
                >
                    Delete Selected
                </button>
            </div>
        </div>
    );
};

export default DeleteReportForm; 