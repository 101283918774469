import React, { useState } from 'react';
import InputWrapper from '../../components/_common/InputWrapper';
import classNames from 'classnames';
import WayshipTextArea from 'components/app/vessel/onboard/ranges/RangeManagementForm/WayshipTextArea';

const WayshipRadioGroup = (props) => {
    const {
        options = [],
        dataKey,
        title,
        value,
        isSubmit,
        required,
        fieldsData,
        error,
    } = props;

    const [selectedOption, setSelectedOption] = useState(value || '');

    const [isNASelected, setIsNASelected] = useState(
        selectedOption === 'NA' ||
            selectedOption === 'N/A' ||
            selectedOption === 'Limitations Observed'
            ? true
            : false
    );

    // TOOD: isNaSelected can also come from config i.e., Limitations Observed or some other option can be taken from config

    React.useEffect(() => {
        // console.log('Setting state data because it changed 🐞');
        setSelectedOption(value);
    }, [value]);

    return (
        <InputWrapper
            className="w-full"
            hasWarning={false}
            inputValue={value}
            hasError={
                error && error.hasOwnProperty('isValid')
                    ? !error.isValid
                    : false
            }
        >
            <label className="ws-input__label" id={dataKey}>
                {title} {required && <span className='text-red-400'>*</span>}
            </label>
            <div className="mb-4 wayship-radio-btn">
                {options &&
                    options.length > 0 &&
                    options.map((option, index) => (
                        <label
                            key={typeof option === 'string' ? option : index}
                            htmlFor={`${dataKey}_${option}`}
                        >
                            <input
                                type="radio"
                                disabled={isSubmit}
                                onChange={() => {
                                    setSelectedOption(option);
                                    props.onChangeHandler(option, dataKey);
                                    if (
                                        option === 'NA' ||
                                        option === 'N/A' ||
                                        option === 'Limitations Observed'
                                    ) {
                                        setIsNASelected(true);
                                    } else {
                                        setIsNASelected(false);
                                    }
                                }}
                                name={`${dataKey}`}
                                value={option}
                                checked={selectedOption === option}
                                id={`${dataKey}_${option}`}
                                required={required === false ? false : true}
                            />
                            <span
                                data-disabled={isSubmit}
                                style={{ cursor: 'pointer' }}
                                className={classNames({
                                    'border-red-300 border':
                                        error && error.hasOwnProperty('isValid')
                                            ? !error.isValid
                                            : false,
                                })}
                            >
                                {option}
                            </span>
                        </label>
                    ))}

                {isNASelected && (
                    <WayshipTextArea
                        dataKey={dataKey + '_remark'}
                        onChange={(a, dataKey) => {
                            props.onChangeHandler(a, dataKey);
                        }}
                        value={fieldsData?.[dataKey + '_remark']?.local_reading}
                        // buttonLabel="Add Remark"
                        isSubmit={isSubmit}
                    />
                )}
            </div>
            <span className="ws-input__error mx-2">{error?.message || ''}</span>
            <span className="ws-input__warning mx-2">Hello Warning</span>
        </InputWrapper>
    );
};
export default WayshipRadioGroup;
