import React, { useState } from 'react';
import ReleaseManager from './ReleaseManager';
import ReleaseHistory from './ReleaseHistory';

const ReleaseManagerContainer = () => {
    const [showReleaseHistory, setShowReleaseHistory] = useState(false);

    // If showing release history, render the ReleaseHistory component with onBack handler
    if (showReleaseHistory) {
        return <ReleaseHistory onBack={() => setShowReleaseHistory(false)} />;
    }

    // Otherwise render the main ReleaseManager with a callback to show history
    return <ReleaseManager onShowHistory={() => setShowReleaseHistory(true)} />;
};

export default ReleaseManagerContainer; 