import React from "react";
import VesselOnboard from "../../../../components/app/vessel/onboard/Onboard";
import { Switch, Route } from "react-router-dom";
import { rootRoutes } from "../../../../routes";
import ScreenVesselOnboardCrew from "./crew";
import ScreenVesselOnboardDevice from "./device";
import ScreenVesselOnboardView from "./view";
import ScreenVesselOnboardTanks from "./tanks";
import ScreenVesselOnboardLogs from "./logs";
import ScreenVesselChecklistLogs from "./checklist";
import ScreenVesselOnboardMarpolList from "./marpol";
import ScreenVesselOnboardDashboard from "./dashboard";
import ScreenVesselOnboardInfo from "./info";
import ScreenVesselOnboardRanges from "./ranges";
import ScreenVesselReportsLogs from "./reports";
import ScreenVesselSealLogs from "./seallogs";
import { useSelector } from "react-redux";
import { authSelectors } from "redux/app/auth";

const {
	dashboard,
	crew,
	device,
	tanks,
	view,
	logs,
	checklist,
	reports,
	seallogs,
	marpol,
	info,
	ranges,
} = rootRoutes.children.app.children.vessel.children.onboard.children;
type ScreenVesselOnboardProps = React.PropsWithChildren<{}>;
const ScreenVesselOnboard: React.FC<ScreenVesselOnboardProps> = (
	props: ScreenVesselOnboardProps
) => {
	// fetch data from redux store
	const isUserThirdParty = useSelector((state: any) =>
		authSelectors(state?.app?.auth).isUserThirdParty()
	);

	const allowedModulesForThirdParty = useSelector(
		(state: any) =>
			authSelectors(state?.app?.auth).allowedModulesForThirdParty
	);
	// console.log({ isUserThirdParty });
	// console.log({ allowedModulesForThirdParty });
	return (
		<VesselOnboard>
			<Switch>
				{isUserThirdParty ? (
					<Route
						path={reports.path}
						component={ScreenVesselReportsLogs}
					/>
				) : (
					<>
						<Route
							path={dashboard.path}
							component={ScreenVesselOnboardDashboard}
						/>
						<Route
							path={crew.path}
							component={ScreenVesselOnboardCrew}
						/>
						<Route
							path={device.path}
							component={ScreenVesselOnboardDevice}
						/>
						<Route
							path={tanks.path}
							component={ScreenVesselOnboardTanks}
						/>
						<Route
							path={logs.path}
							component={ScreenVesselOnboardLogs}
						/>
						<Route
							path={checklist.path}
							component={ScreenVesselChecklistLogs}
						/>
						<Route
							path={reports.path}
							component={ScreenVesselReportsLogs}
						/>
						<Route
							path={seallogs.path}
							component={ScreenVesselSealLogs}
						/>
						<Route
							path={marpol.path}
							component={ScreenVesselOnboardMarpolList}
						/>
						<Route
							path={info.path}
							component={ScreenVesselOnboardInfo}
						/>
						<Route
							path={ranges.path}
							component={ScreenVesselOnboardRanges}
						/>
					</>
				)}
			</Switch>
		</VesselOnboard>
	);
};

export default ScreenVesselOnboard;