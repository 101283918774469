import { commonUtils } from "redux/_common";
import { IAPIRequestMetaData } from "../../_core/api/types";
import extActionTypes from "./action-types";

const {
	LOAD_TASK,
	FORM_UPDATE,
	NAVNUC_SUBMIT
} = extActionTypes;

const extAPIs = {
	getTask(authToken: string): IAPIRequestMetaData<any> {
		return {
			feature: LOAD_TASK,
			method: "POST",
			url: "api/extUsers/authenticate",
			headers: {
				// @ts-ignore
				Authorization: authToken,
				"x-access-token": undefined
			},
		};
	},
	updatePermit(
		authToken: string,
		permitId: string,
		permit: Partial<any>) :  IAPIRequestMetaData<any> {
		return {
			feature: FORM_UPDATE,
			method: "PATCH",
			url: "api/permitLogs/" + permitId,
			body: permit,
			headers: {
				// @ts-ignore
				Authorization: authToken,
				"x-access-token": undefined
			},
		};
	},
	submitNavnuc(formData: FormData) :  IAPIRequestMetaData<any> {
		return {
			feature: extActionTypes.NAVNUC_SUBMIT,
			method: "POST",
			url: `${commonUtils.getAPIBaseUrl()}/api/forms?formName=navnuc`,
			body: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};
	},
	// uploadNavnucAttachment(formData: FormData) :  IAPIRequestMetaData<any> {  //use this
	uploadNavnucAttachment(formData: string) :  IAPIRequestMetaData<any> {
		return {
			feature: extActionTypes.NAVNUC_ATTACHMENT_UPLOAD,
			method: "POST",
			url: "https://67c54b2cc4649b9551b5fd16.mockapi.io/api/v1/attachment",
			body: {name: formData},
			
			//use this
			// body: formData,
			// headers: {
			// 	'Content-Type': 'multipart/form-data',
			// },
		};
	},
};

export default extAPIs;
