import React, { useEffect, useState } from "react";
import { withRouter, RouteProps } from "react-router-dom";
import { rootRoutes } from "../../../../routes";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { historyActions } from "../../../../redux/_core/history";
import { IStore } from "../../../../redux/store";
import {
	vesselOnboardActions,
	vesselOnboardSelectors,
} from "../../../../redux/app/vessel/onboard";
import { vesselActions, vesselSelectors } from "../../../../redux/app/vessel";
import authSelectors from "redux/app/auth/selectors";
import classNames from "classnames";

const {
	crew,
	device,
	tanks,
	view,
	logs,
	checklist,
	reports,
	seallogs,
	marpol,
	dashboard,
	info,
	ranges,
} = rootRoutes.children.app.children.vessel.children.onboard.children;

enum TabId {
	CREW = "crew-tab-id",
	DEVICES = "devices-tab-id",
	TANKS = "tanks-tab-id",
	LOGS = "logs-tab-id",
	CHECKLIST = "checklist-tab-id",
	REPORTS = "reports-tab-id",
	SEALLOGS = "seallogs-tab-id",
	MARPOL = "marpol-tab-id",
	DASHBOARD = "dashboard-tab-id",
	INFO = "info-tab-id",
	RANGES = "ranges-tab-id",
}

interface RouteParams {
	vesselId: string;
	pageNumber?: string;
}

type OwnProps = {
	match: {
		params: RouteParams;
	};
};

// interface PropsFromRedux {
// 	isUserThirdParty: boolean;
// 	setVesselToConfigure: (vesselId: string) => void;
// 	loadVessel: (vesselId: string) => void;
// 	loadVesselInfo: (vesselId: string) => void;
// }

type VesselOnboardProps = React.PropsWithChildren<
	RouteProps & OwnProps & PropsFromRedux
>;

const useVesselInitialization = (
	vesselId: string,
	actions: Pick<
		PropsFromRedux,
		"setVesselToConfigure" | "loadVessel" | "loadVesselInfo"
	>
) => {
	useEffect(() => {
		actions.setVesselToConfigure(vesselId);
		actions.loadVessel(vesselId);
		actions.loadVesselInfo(vesselId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

interface Tab {
	id: string;
	label: string;
	path: string;
	icon: string;
	url: (vesselId: string) => string;
}

const tabs: Tab[] = [
	{
		id: "dashboard",
		label: "Dashboard",
		path: "/dashboard",
		url: dashboard.url,
		icon: "dashboard",
	},
	{
		id: "crew",
		label: "Crew",
		path: "/crew",
		url: crew.url,
		icon: "person",
	},
	{
		id: "devices",
		label: "Devices",
		path: "/devices",
		url: device.url,
		icon: "mobile-phone",
	},
	{
		id: "tanks",
		label: "Tanks",
		path: "/tanks",
		url: tanks.url,
		icon: "database",
	},
	{
		id: "logs",
		label: "Logs",
		path: "/logs",
		url: logs.url,
		icon: "clipboard",
	},
	{
		id: "checklist",
		label: "Checklist",
		path: "/checklist",
		url: checklist.url,
		icon: "form",
	},
	{
		id: "reports",
		label: "CNR",
		path: "/reports",
		url: reports.url,
		icon: "full-stacked-chart",
	},
	{
		id: "seallogs",
		label: "Seal Logs",
		path: "/seallogs",
		url: seallogs.url,
		icon: "lock",
	},
	{
		id: "marpol",
		label: "MARPOL",
		path: "/marpol",
		url: marpol.url,
		icon: "application",
	},
	{
		id: "ranges",
		label: "Ranges",
		path: "/ranges",
		url: ranges.url,
		icon: "numbered-list",
	},
	{
		id: "info",
		label: "Info",
		path: "/info",
		url: info.url,
		icon: "info-sign",
	},
];

const TabNavigation = ({
	activeTab,
	setActiveTab,
	onTabChange,
	vesselId,
	isUserThirdParty,
	allowedModulesForThirdParty,
}: {
	activeTab?: string;
	onTabChange: (tabId: string) => void;
	setActiveTab: (tabId: string) => void;
	vesselId: string;
	isUserThirdParty: boolean;
	allowedModulesForThirdParty: string[];
}) => {
	// console.log("isUserThirdParty", isUserThirdParty);
	// if (isUserThirdParty) {
	// 	tabs = tabs.filter((tab) => {
	// 		return allowedModulesForThirdParty.includes(tab.id);
	// 	});
	// }

	return (
		<div className="w-full overflow-x-auto border-b border-gray-200">
			<nav className="flex mx-6">
				{/* // if user is third party, then only show the allowed modules */}
				{tabs.map((tab) => {
					if (
						isUserThirdParty &&
						!allowedModulesForThirdParty.includes(tab.id)
					) {
						return null;
					}
					return (
						// if user is third party, then only show the allowed modules

						<div
							className={classNames(
								"flex cursor-pointer rounded justify-center items-center gap-0 px-2 hover:bg-lightBlue hover:text-blue-500 border-b-2"
							)}
							key={tab.id}
						>
							<span
								className={classNames(
									`h-5 w-5 bp3-icon bp3-icon-${tab.icon.toLowerCase()}`,
									{
										"border-blue-500 text-blue-600":
											activeTab === tab.id,
										"border-transparent text-gray-700 hover:text-gray-900 hover:border-gray-300":
											activeTab !== tab.id,
									}
								)}
							/>

							<button
								key={tab.id}
								onClick={() => {
									// console.log(
									// 	"tab.url(vesselId)",
									// 	tab.url(vesselId)
									// );
									setActiveTab(tab.id);
									return onTabChange(
										// route to the tab path
										tab.url(vesselId)
									);
								}}
								className={classNames(
									"py-4 mx-2 font-medium text-sm whitespace-nowrap inline-flex items-center",
									{
										"border-blue-500 text-blue-600":
											activeTab === tab.id,
										"border-transparent text-gray-700 hover:text-gray-900 hover:border-gray-300":
											activeTab !== tab.id,
									}
								)}
							>
								{tab.label}
							</button>
						</div>
					);
				})}
			</nav>
		</div>
	);
};

function VesselOnboard(props: VesselOnboardProps) {
	const {
		location,
		children,
		match,
		isUserThirdParty,
		allowedModulesForThirdParty,
		onTabChange,
	} = props;
	const vesselId = match.params.vesselId;
	const pageNumber = match.params.pageNumber ? +match.params.pageNumber : 1;

	useVesselInitialization(vesselId, {
		setVesselToConfigure: props.setVesselToConfigure,
		loadVessel: props.loadVessel,
		loadVesselInfo: props.loadVesselInfo,
	});

	const tabIds = {
		crew: TabId.CREW,
		devices: TabId.DEVICES,
		tanks: TabId.TANKS,
		logs: TabId.LOGS,
		checklist: TabId.CHECKLIST,
		reports: TabId.REPORTS,
		seallogs: TabId.SEALLOGS,
		marpol: TabId.MARPOL,
		dashboard: TabId.DASHBOARD,
		info: TabId.INFO,
		ranges: TabId.RANGES,
	};
	const isCrewRouteActive = location?.pathname.includes("/crew");
	const isDeviceRouteActive = location?.pathname.includes("/device");
	const isTanksRouteActive = location?.pathname.includes("/tanks");
	const isLogsRouteActive = location?.pathname.includes("/logs");
	const isChecklistRouteActive = location?.pathname.includes("/checklist");
	const isReportsRouteActive = location?.pathname.includes("/reports");
	const isSealLogsRouteActive = location?.pathname.includes("/seallogs");
	const isMarpolRouteActive = location?.pathname.includes("/marpol");
	const isDashboardRouteActive = location?.pathname.includes("/dashboard");
	const isInfoRouteActive = location?.pathname.includes("/info");
	const isRangeRouteActive = location?.pathname.includes("/ranges");

	const [activeTabId, setActiveTabId] = useState(getActiveTab());
	const [activeTab, setActiveTab] = useState(
		isUserThirdParty ? allowedModulesForThirdParty[0] : getActiveTab()
	);

	function getActiveTab() {
		if (isDashboardRouteActive) return tabIds.dashboard;
		if (isCrewRouteActive) return tabIds.crew;
		if (isDeviceRouteActive) return tabIds.devices;
		if (isTanksRouteActive) return tabIds.tanks;
		if (isLogsRouteActive) return tabIds.logs;
		if (isChecklistRouteActive) return tabIds.checklist;
		if (isReportsRouteActive) return tabIds.reports;
		if (isSealLogsRouteActive) return tabIds.seallogs;
		if (isMarpolRouteActive) return tabIds.marpol;
		if (isInfoRouteActive) return tabIds.info;
		if (isRangeRouteActive) return tabIds.ranges;
	}

	// function handleTabChange(tabId: string) {
	// 	if (tabId === tabIds.dashboard)
	// 		props.onTabChange(dashboard.url(vesselId));
	// 	if (tabId === tabIds.crew) props.onTabChange(crew.url(vesselId));
	// 	if (tabId === tabIds.devices) props.onTabChange(device.url(vesselId));
	// 	if (tabId === tabIds.tanks) props.onTabChange(tanks.url(vesselId));
	// 	if (tabId === tabIds.logs)
	// 		props.onTabChange(logs.url(vesselId, pageNumber));
	// 	if (tabId === tabIds.checklist)
	// 		props.onTabChange(checklist.url(vesselId, pageNumber));
	// 	if (tabId === tabIds.reports)
	// 		props.onTabChange(reports.url(vesselId, pageNumber));
	// 	if (tabId === tabIds.seallogs)
	// 		props.onTabChange(seallogs.url(vesselId));
	// 	setActiveTabId(tabId);
	// 	if (tabId === tabIds.marpol)
	// 		props.onTabChange(marpol.url(vesselId, pageNumber));
	// 	if (tabId === tabIds.info) props.onTabChange(info.url(vesselId));
	// 	if (tabId === tabIds.ranges) props.onTabChange(ranges.url(vesselId));
	// }

	return (
		<div>
			{/* <div className="my-2 bg-white rounded-md md:m-2 md:px-4">
				<div className="mx-2">
					<Tabs
						id="onboard-tabs"
						animate={true}
						renderActiveTabPanelOnly={true}
						vertical={false}
						large={true}
						selectedTabId={activeTabId}
						className="w-full overflow-x-auto rounded-sm bp3-html-table bp3-interactive"
						onChange={function onTabsChange(tabId) {
							handleTabChange(tabId as string);
						}}
					>
						<Tab
							id={tabIds.dashboard}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-dashboard" />
							}
							disabled={false}
							className="font-medium text-gray-600 "
						>
							Dashboard
						</Tab>
						<Tab
							id={tabIds.crew}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-person" />
							}
							disabled={false}
							className="font-medium text-gray-600 "
						>
							Crew
						</Tab>
						<Tab
							id={tabIds.devices}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-mobile-phone" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Devices
						</Tab>
						<Tab
							id={tabIds.tanks}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-database" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Tanks
						</Tab>
						<Tab
							id={tabIds.logs}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-clipboard" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Logs
						</Tab>
						<Tab
							id={tabIds.checklist}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-form" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Checklists
						</Tab>
						<Tab
							id={tabIds.reports}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-manual" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Reports
						</Tab>
						<Tab
							id={tabIds.seallogs}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-manual" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Seal Logs
						</Tab>
						<Tab
							id={tabIds.marpol}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-application" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Marpol
						</Tab>
						<Tab
							id={tabIds.info}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-info-sign" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Info
						</Tab>
						<Tab
							id={tabIds.ranges}
							title={
								<span className="mr-3 text-lg bp3-icon bp3-icon-numbered-list" />
							}
							disabled={false}
							panelClassName=""
							className="font-medium text-gray-600 "
						>
							Ranges
						</Tab>
						<Tabs.Expander />
					</Tabs>
					<div className="pb-2 border-t border-gray-300" />
				</div>
			</div> */}
			<div className="my-2 bg-white rounded-md md:m-2 md:px-4">
				<div className="mx-2">
					{/* <div className="min-h-full pt-3 pb-6 my-2 mb-6 bg-white rounded-md md:m-2 md:px-4">
				<div className="mx-2"> */}
					<div className="min-h-full pb-6 mb-6 bg-white rounded-md">
						<TabNavigation
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							onTabChange={props.onTabChange}
							vesselId={vesselId}
							isUserThirdParty={isUserThirdParty}
							allowedModulesForThirdParty={
								props.allowedModulesForThirdParty
							}
						/>
						<div className="rounded-md rounded-t-none ">
							{children}
						</div>

						<div className="footer" />
					</div>
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const vesselStore = vesselSelectors._getStore(store);
	const onboardStore = vesselOnboardSelectors._getStore(store);
	const vessel = vesselSelectors.getVesselById(
		ownProps.match.params.vesselId,
		vesselSelectors.getVessels(vesselStore)
	);
	const storeTenantId = store.app.auth.user?.tenantId;

	const isUserThirdParty = authSelectors(store.app.auth).isUserThirdParty();

	const allowedModulesForThirdParty = authSelectors(
		store.app.auth
	).allowedModulesForThirdParty;

	return {
		vessel,
		jobToLoadVessel: vesselOnboardSelectors.jobToLoadVessel(onboardStore),
		isVesselOnboarded: vesselSelectors
			.vesselItemSelectors(vessel)
			.isOnboarded(),
		storeTenantId,
		isUserThirdParty,
		allowedModulesForThirdParty,
	};
}
function mapDispatchToProps(dispatch: Dispatch) {
	return {
		onTabChange(routeUrl: string) {
			// console.log("routeUrl", routeUrl);
			dispatch(historyActions.push("#VesselOnboard", routeUrl));
		},
		setVesselToConfigure(vesselId: string) {
			dispatch(vesselOnboardActions.document.setVesselId(vesselId));
		},
		loadVessel(vesselId: string) {
			dispatch(vesselOnboardActions.command.vesselLoad(vesselId, false));
		},
		loadVesselInfo(vesselId: string) {
			dispatch(vesselActions.infoLoad(vesselId));
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default withRouter(reduxConnector(VesselOnboard));