import React, { useState, useEffect, FC } from "react";
import { ITabReport } from "redux/app/logs/@types";
import classNames from "classnames";
import moment from "moment";
import TimelineVisualization from "./TimelineVisualization";

// Report types
const REPORT_TYPES = ["ARRIVAL", "DEPARTURE", "CARGO", "BUNKER", "PORT", "STEAMING"] as const;
type ReportType = typeof REPORT_TYPES[number];

// Insertion position types
type InsertPosition = "before" | "after";

interface IInsertReportFormProps {
    report: ITabReport;
    vesselName: string;
    vesselImo: string;
    onClose: () => void;
    onSubmit: (data: any) => void;
}

const InsertReportForm: FC<IInsertReportFormProps> = ({
    report,
    vesselName,
    vesselImo,
    onClose,
    onSubmit,
}) => {
    // State for selected report type and index
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [insertPosition, setInsertPosition] = useState<InsertPosition>("after");
    const [hasTimeGap, setHasTimeGap] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const validateInsertion = (
        type: ReportType | null, 
        adjacentReportType: string, 
        position: InsertPosition
    ): { isValid: boolean; errorMessage?: string } => {
        // If no report type is selected, validation doesn't apply
        if (!type) return { isValid: true };
        
        const timeline = report.timeline || [];
        
        // If timeline is empty, validation doesn't apply
        if (timeline.length === 0) return { isValid: true };

        // For PORT and STEAMING, time gap validation is handled separately in checkTimeGap
        // Here, we only validate the report type sequence
        
        // When inserting 'after' a report, check if the current report type can follow the adjacent report
        if (position === "after") {
            switch (type) {
                case "CARGO":
                    return { 
                        isValid: adjacentReportType === "CARGO" || adjacentReportType === "PORT",
                        errorMessage: adjacentReportType === "CARGO" || adjacentReportType === "PORT" 
                            ? undefined 
                            : `Cannot insert CARGO report after ${adjacentReportType}. CARGO can only follow CARGO or PORT.`
                    };
                case "BUNKER":
                    return { 
                        isValid: adjacentReportType === "BUNKER" || adjacentReportType === "PORT",
                        errorMessage: adjacentReportType === "BUNKER" || adjacentReportType === "PORT" 
                            ? undefined 
                            : `Cannot insert BUNKER report after ${adjacentReportType}. BUNKER can only follow BUNKER or PORT.`
                    };
                case "ARRIVAL":
                    return { 
                        isValid: adjacentReportType === "STEAMING",
                        errorMessage: adjacentReportType === "STEAMING" 
                            ? undefined 
                            : `Cannot insert ARRIVAL report after ${adjacentReportType}. ARRIVAL can only follow STEAMING.`
                    };
                case "DEPARTURE":
                    return { 
                        isValid: adjacentReportType === "PORT" || adjacentReportType === "CARGO",
                        errorMessage: adjacentReportType === "PORT" || adjacentReportType === "CARGO" 
                            ? undefined 
                            : `Cannot insert DEPARTURE report after ${adjacentReportType}. DEPARTURE can only follow PORT or CARGO.`
                    };
                case "PORT":
                    // PORT can be inserted after various report types as long as there's a time gap
                    return { 
                        isValid: ["ARRIVAL", "PORT", "CARGO", "BUNKER"].includes(adjacentReportType),
                        errorMessage: ["ARRIVAL", "PORT", "CARGO", "BUNKER"].includes(adjacentReportType) 
                            ? undefined 
                            : `Cannot insert PORT report after ${adjacentReportType}. PORT can only follow ARRIVAL, PORT, CARGO, or BUNKER.`
                    };
                case "STEAMING":
                    // STEAMING can be inserted after various report types as long as there's a time gap
                    return { 
                        isValid: ["DEPARTURE", "STEAMING"].includes(adjacentReportType),
                        errorMessage: ["DEPARTURE", "STEAMING"].includes(adjacentReportType) 
                            ? undefined 
                            : `Cannot insert STEAMING report after ${adjacentReportType}. STEAMING can only follow DEPARTURE or STEAMING.`
                    };
                default:
                    return { isValid: false, errorMessage: `Unknown report type: ${type}` };
            }
        } 
        // When inserting 'before' a report, check if the adjacent report type can follow the current report type
        else {
            switch (adjacentReportType) {
                case "CARGO":
                    return { 
                        isValid: type === "CARGO" || type === "PORT",
                        errorMessage: type === "CARGO" || type === "PORT" 
                            ? undefined 
                            : `Cannot insert ${type} report before CARGO. Only CARGO or PORT can come before CARGO.`
                    };
                case "BUNKER":
                    return { 
                        isValid: type === "BUNKER" || type === "PORT",
                        errorMessage: type === "BUNKER" || type === "PORT" 
                            ? undefined 
                            : `Cannot insert ${type} report before BUNKER. Only BUNKER or PORT can come before BUNKER.`
                    };
                case "ARRIVAL":
                    return { 
                        isValid: type === "STEAMING",
                        errorMessage: type === "STEAMING" 
                            ? undefined 
                            : `Cannot insert ${type} report before ARRIVAL. Only STEAMING can come before ARRIVAL.`
                    };
                case "DEPARTURE":
                    return { 
                        isValid: type === "PORT" || type === "CARGO",
                        errorMessage: type === "PORT" || type === "CARGO" 
                            ? undefined 
                            : `Cannot insert ${type} report before DEPARTURE. Only PORT or CARGO can come before DEPARTURE.`
                    };
                case "PORT":
                    // These report types can come before PORT
                    return { 
                        isValid: ["ARRIVAL", "PORT", "CARGO", "BUNKER"].includes(type),
                        errorMessage: ["ARRIVAL", "PORT", "CARGO", "BUNKER"].includes(type) 
                            ? undefined 
                            : `Cannot insert ${type} report before PORT. Only ARRIVAL, PORT, CARGO, or BUNKER can come before PORT.`
                    };
                case "STEAMING":
                    // These report types can come before STEAMING
                    return { 
                        isValid: ["DEPARTURE", "STEAMING"].includes(type),
                        errorMessage: ["DEPARTURE", "STEAMING"].includes(type) 
                            ? undefined 
                            : `Cannot insert ${type} report before STEAMING. Only DEPARTURE or STEAMING can come before STEAMING.`
                    };
                default:
                    return { isValid: false, errorMessage: `Unknown report type: ${adjacentReportType}` };
            }
        }
    };

    // Check if there's a time gap available for PORT and STEAMING reports
    const checkTimeGap = (): { hasGap: boolean; errorMessage?: string } => {
        // If no report type is selected or not a period-based report, no gap check needed
        if (!selectedType || (selectedType !== "PORT" && selectedType !== "STEAMING")) {
            setHasTimeGap(true);
            return { hasGap: true };
        }

        const timeline = report.timeline || [];
        
        // If timeline is empty, we can add a report
        if (timeline.length === 0) {
            setHasTimeGap(true);
            return { hasGap: true };
        }
        
        // Ensure selectedIndex is within valid range
        if (selectedIndex < 0 || selectedIndex >= timeline.length) {
            setHasTimeGap(false);
            return { 
                hasGap: false, 
                errorMessage: "Invalid selection index" 
            };
        }
        
        // When checking time gaps, we need to consider whether we're inserting before or after
        // For 'after' insertion, we check between selectedIndex and selectedIndex+1
        // For 'before' insertion, we check between selectedIndex-1 and selectedIndex

        // Check for a gap between two reports in the timeline
        if (insertPosition === "after" && selectedIndex < timeline.length - 1) {
            // Same logic as original "inserting between reports" case - checking current and next reports
            const prevReport = timeline[selectedIndex];
            const nextReport = timeline[selectedIndex + 1];
            
            try {
                // Get time strings for clearer debugging
                const prevEndTime = `${prevReport.to_local_date} ${prevReport.to_local_time}`;
                const nextStartTime = `${nextReport.from_local_date} ${nextReport.from_local_time}`;
                
                console.log(`DEBUG: Comparing end ${prevEndTime} with start ${nextStartTime}`);
                
                // Extract hours and minutes for direct comparison
                const [prevHours, prevMinutes] = prevReport.to_local_time.split(':').map(Number);
                const [nextHours, nextMinutes] = nextReport.from_local_time.split(':').map(Number);
                
                // Convert to total minutes for comparison
                const prevTotalMinutes = prevHours * 60 + prevMinutes;
                const nextTotalMinutes = nextHours * 60 + nextMinutes;
                
                // Get dates for comparison (handles day boundaries)
                const prevDate = new Date(prevReport.to_local_date).getTime();
                const nextDate = new Date(nextReport.from_local_date).getTime();
                
                let timeDiffMinutes = 0;
                
                // If on the same day, compare times directly
                if (prevDate === nextDate) {
                    timeDiffMinutes = nextTotalMinutes - prevTotalMinutes;
                } else {
                    // If different days, calculate the full gap
                    const dayDiffMs = nextDate - prevDate;
                    const dayDiffMinutes = dayDiffMs / (1000 * 60);
                    // Add minutes from end of prev day plus minutes into next day
                    timeDiffMinutes = dayDiffMinutes - prevTotalMinutes + nextTotalMinutes;
                }
                
                // A gap of 1 minute or more is valid
                const hasGap = timeDiffMinutes >= 1;
                setHasTimeGap(hasGap);
                
                if (!hasGap) {
                    return {
                        hasGap: false,
                        errorMessage: `Cannot insert ${selectedType} report: No time gap available between the reports at positions ${selectedIndex + 1} and ${selectedIndex + 2}.` +
                        ` The report at position ${selectedIndex + 1} ends at ${prevEndTime} and the next report starts at ${nextStartTime}.`
                    };
                }
                
                return { hasGap: true };
            } catch (e) {
                console.error("Error comparing dates:", e);
                // If there's an error in date parsing, default to allowing insertion
                setHasTimeGap(true);
                return { hasGap: true };
            }
        } 
        // For 'before' insertion between two reports (check previous and current report)
        else if (insertPosition === "before" && selectedIndex > 0) {
            const prevReport = timeline[selectedIndex - 1];
            const nextReport = timeline[selectedIndex];
            
            try {
                // Get time strings for clearer debugging
                const prevEndTime = `${prevReport.to_local_date} ${prevReport.to_local_time}`;
                const nextStartTime = `${nextReport.from_local_date} ${nextReport.from_local_time}`;
                
                console.log(`DEBUG: Comparing end ${prevEndTime} with start ${nextStartTime} for before insertion`);
                
                // Extract hours and minutes for direct comparison
                const [prevHours, prevMinutes] = prevReport.to_local_time.split(':').map(Number);
                const [nextHours, nextMinutes] = nextReport.from_local_time.split(':').map(Number);
                
                // Convert to total minutes for comparison
                const prevTotalMinutes = prevHours * 60 + prevMinutes;
                const nextTotalMinutes = nextHours * 60 + nextMinutes;
                
                // Get dates for comparison (handles day boundaries)
                const prevDate = new Date(prevReport.to_local_date).getTime();
                const nextDate = new Date(nextReport.from_local_date).getTime();
                
                let timeDiffMinutes = 0;
                
                // If on the same day, compare times directly
                if (prevDate === nextDate) {
                    timeDiffMinutes = nextTotalMinutes - prevTotalMinutes;
                } else {
                    // If different days, calculate the full gap
                    const dayDiffMs = nextDate - prevDate;
                    const dayDiffMinutes = dayDiffMs / (1000 * 60);
                    // Add minutes from end of prev day plus minutes into next day
                    timeDiffMinutes = dayDiffMinutes - prevTotalMinutes + nextTotalMinutes;
                }
                
                // A gap of 1 minute or more is valid
                const hasGap = timeDiffMinutes >= 1;
                setHasTimeGap(hasGap);
                
                if (!hasGap) {
                    return {
                        hasGap: false,
                        errorMessage: `Cannot insert ${selectedType} report: No time gap available between the reports at positions ${selectedIndex} and ${selectedIndex + 1}.` +
                        ` The report at position ${selectedIndex} ends at ${prevEndTime} and the next report starts at ${nextStartTime}.`
                    };
                }
                
                return { hasGap: true };
            } catch (e) {
                console.error("Error comparing dates for before insertion:", e);
                setHasTimeGap(true);
                return { hasGap: true };
            }
        }
        // Beginning of timeline special cases 
        else if ((insertPosition === "after" && selectedIndex === timeline.length - 1) ||
                 (insertPosition === "before" && selectedIndex === 0)) {
            
            const reportToCheck = timeline[selectedIndex];
            
            try {
                // Handle before first report or after last report cases
                const isBeforeFirst = insertPosition === "before" && selectedIndex === 0;
                const isAfterLast = insertPosition === "after" && selectedIndex === timeline.length - 1;
                
                let hasGap = false;
                
                if (isBeforeFirst) {
                    // Check if there's a gap before the first report
                    const firstDate = reportToCheck.from_local_date;
                    const firstTime = reportToCheck.from_local_time;
                    const firstZone = reportToCheck.from_zd || '';
                    
                    // Create ISO-8601 formatted string for accurate parsing
                    const firstStartISO = `${firstDate}T${firstTime}:00${firstZone}`;
                    const firstStartTime = new Date(firstStartISO);
                    
                    // Get the report date (selectedDate) start - noon on day before
                    const reportDate = new Date(report.selectedDate);
                    const dayBefore = new Date(reportDate);
                    dayBefore.setDate(dayBefore.getDate() - 1);
                    const dayBeforeStr = dayBefore.toISOString().split('T')[0];
                    const dayBeforeISO = `${dayBeforeStr}T12:00:00${firstZone}`;
                    const dayBeforeNoon = new Date(dayBeforeISO);
                    
                    // Check if there's a gap between noon on the day before and the first report
                    const timeDiffMinutes = (firstStartTime.getTime() - dayBeforeNoon.getTime()) / (1000 * 60);
                    hasGap = timeDiffMinutes >= 1;
                    
                    if (!hasGap) {
                        setHasTimeGap(false);
                        return {
                            hasGap: false,
                            errorMessage: `Cannot insert ${selectedType} report at the beginning: The timeline already starts from the beginning of the reporting period.` +
                            ` The first report starts at ${reportToCheck.from_local_date} ${reportToCheck.from_local_time}.`
                        };
                    }
                } 
                else if (isAfterLast) {
                    // Check if there's a gap after the last report
                    const lastDate = reportToCheck.to_local_date;
                    const lastTime = reportToCheck.to_local_time;
                    const lastZone = reportToCheck.to_zd || '';
                    
                    // Create ISO-8601 formatted string for accurate parsing
                    const lastEndISO = `${lastDate}T${lastTime}:00${lastZone}`;
                    const lastEndTime = new Date(lastEndISO);
                    
                    // Get the report date (selectedDate) end - noon on report date
                    const reportDate = new Date(report.selectedDate);
                    const reportDateStr = reportDate.toISOString().split('T')[0];
                    const reportNoonISO = `${reportDateStr}T12:00:00${lastZone}`;
                    const reportNoon = new Date(reportNoonISO);
                    
                    // Check if there's a gap between the end of the last report and noon
                    const timeDiffMinutes = (reportNoon.getTime() - lastEndTime.getTime()) / (1000 * 60);
                    hasGap = timeDiffMinutes >= 1;
                    
                    if (!hasGap) {
                        setHasTimeGap(false);
                        return {
                            hasGap: false,
                            errorMessage: `Cannot insert ${selectedType} report at the end: The timeline already extends to the end of the reporting period (12:00 noon).` +
                            ` The last report ends at ${reportToCheck.to_local_date} ${reportToCheck.to_local_time}.`
                        };
                    }
                }
                
                setHasTimeGap(true);
                return { hasGap: true };
            } catch (e) {
                console.error("Error checking timeline boundaries:", e);
                setHasTimeGap(true);
                return { hasGap: true };
            }
        }
        
        // Default fallback
        setHasTimeGap(false);
        return { 
            hasGap: false, 
            errorMessage: `Cannot validate time gap for ${selectedType} report: Unexpected timeline position.` 
        };
    };

    // Check for time gaps when type, index, or insert position changes
    useEffect(() => {
        // Always clear error messages when user changes any selection
        setError(null);
        
        if (selectedType) {
            // Re-validate with the new parameters
            const timeline = report.timeline || [];
            if (timeline.length > 0 && selectedIndex >= 0 && selectedIndex < timeline.length) {
                const adjacentReport = timeline[selectedIndex];
                
                // Step 1: Validate report type sequence
                const validationResult = validateInsertion(
                    selectedType as ReportType, 
                    adjacentReport?.report_type, 
                    insertPosition
                );
                
                if (!validationResult.isValid) {
                    setError(validationResult.errorMessage || `Cannot insert ${selectedType} report ${insertPosition} this report.`);
                    setHasTimeGap(false); // Ensure hasTimeGap is false for invalid selections
                    return;
                }
                
                // Step 2: Only for PORT and STEAMING, check time gaps
                if (selectedType === "PORT" || selectedType === "STEAMING") {
                    const result = checkTimeGap();
                    if (!result.hasGap && result.errorMessage) {
                        setError(result.errorMessage);
                        setHasTimeGap(false);
                    } else {
                        // Explicitly set hasTimeGap to true for valid selections
                        setHasTimeGap(true);
                    }
                } else {
                    // For non-period reports, always set hasTimeGap to true when report type sequence is valid
                    setHasTimeGap(true);
                }
            } else {
                // Invalid selection index
                setHasTimeGap(false);
            }
        } else {
            // Reset hasTimeGap when no report type is selected
            setHasTimeGap(false);
        }
    }, [selectedType, selectedIndex, insertPosition]);

    const handleSubmit = () => {
        // Validate that a report type is selected
        if (!selectedType) {
            setError("Please select a report type first.");
            return;
        }
        
        const timeline = report.timeline || [];
        
        // Validate that timeline is not empty
        if (timeline.length === 0) {
            setError("Cannot insert a report into an empty timeline.");
            return;
        }
        
        // Get the adjacent report based on insertion position
        const adjacentReport = timeline[selectedIndex];
        if (!adjacentReport) {
            setError("Invalid selection index.");
            return;
        }

        // Validate report type sequence
        const validationResult = validateInsertion(selectedType as ReportType, adjacentReport?.report_type, insertPosition);
        if (!validationResult.isValid) {
            setError(validationResult.errorMessage || `Cannot insert ${selectedType} report at this position. Please check insertion rules.`);
            return;
        }
        
        // For PORT and STEAMING, also check for time gaps
        if (selectedType === "PORT" || selectedType === "STEAMING") {
            const timeGapResult = checkTimeGap();
            if (!timeGapResult.hasGap) {
                if (timeGapResult.errorMessage) {
                    setError(timeGapResult.errorMessage);
                }
                return;
            }
        }
        
        // Create the new report with proper time values based on insertion position
        let newReport;
        
        if (insertPosition === "after") {
            // When inserting AFTER, use the END time of the adjacent report as the START time of the new report
            newReport = {
                vessel_name: vesselName,
                vessel_imo: vesselImo,
                from_local_date: adjacentReport?.to_local_date,
                from_local_time: adjacentReport?.to_local_time,
                from_zd: adjacentReport?.to_zd,
                report_type: selectedType,
                to_local_date: adjacentReport?.to_local_date,
                to_local_time: adjacentReport?.to_local_time,
                to_zd: adjacentReport?.to_zd
            };
            
            // For period-based reports inserted AFTER the last report in the timeline, 
            // set the end time to noon on the report date
            if ((selectedType === "PORT" || selectedType === "STEAMING") && 
                selectedIndex === timeline.length - 1) {
                
                // Create noon time using the SAME TIMEZONE as the adjacent report
                const reportDate = new Date(report.selectedDate);
                
                // Format the date for the API using same timezone as adjacent report
                newReport.to_local_date = reportDate.toISOString().split('T')[0];
                newReport.to_local_time = "12:00";
                // Keep the same timezone
            }
            // For period-based reports inserted AFTER a report with a next report,
            // set the end time to the start time of the next report
            else if ((selectedType === "PORT" || selectedType === "STEAMING") && 
                     selectedIndex < timeline.length - 1) {
                
                const nextReport = timeline[selectedIndex + 1];
                newReport.to_local_date = nextReport.from_local_date;
                newReport.to_local_time = nextReport.from_local_time;
                newReport.to_zd = nextReport.from_zd;
            }
        } else { // insertPosition === "before"
            // When inserting BEFORE, use the START time of the adjacent report as the END time of the new report
            newReport = {
                vessel_name: vesselName,
                vessel_imo: vesselImo,
                from_local_date: adjacentReport?.from_local_date,
                from_local_time: adjacentReport?.from_local_time,
                from_zd: adjacentReport?.from_zd,
                report_type: selectedType,
                to_local_date: adjacentReport?.from_local_date,
                to_local_time: adjacentReport?.from_local_time,
                to_zd: adjacentReport?.from_zd
            };
            
            // For period-based reports inserted BEFORE the first report in the timeline,
            // set the start time to noon on the day before the report date
            if ((selectedType === "PORT" || selectedType === "STEAMING") && 
                selectedIndex === 0) {
                
                // Create noon time on the day before using the SAME TIMEZONE
                const reportDate = new Date(report.selectedDate);
                const dayBefore = new Date(reportDate);
                dayBefore.setDate(dayBefore.getDate() - 1);
                
                // Format the date for the API
                newReport.from_local_date = dayBefore.toISOString().split('T')[0];
                newReport.from_local_time = "12:00";
                // Keep the same timezone
            }
            // For period-based reports inserted BEFORE a report with a previous report,
            // set the start time to the end time of the previous report
            else if ((selectedType === "PORT" || selectedType === "STEAMING") && 
                     selectedIndex > 0) {
                
                const prevReport = timeline[selectedIndex - 1];
                newReport.from_local_date = prevReport.to_local_date;
                newReport.from_local_time = prevReport.to_local_time;
                newReport.from_zd = prevReport.to_zd;
            }
        }

        // Create backup
        const backup = {
            ...report,
            modelName: "backup",
            backupNote: `Inserted ${selectedType} report ${insertPosition} position ${selectedIndex + 1}`,
            backupDate: moment().toISOString()
        };

        // Calculate insert index based on insertion position
        const insertIndex = insertPosition === "after" ? selectedIndex + 1 : selectedIndex;

        onSubmit({
            newReport,
            insertIndex,
            backup
        });
    };

    // Format a date for display in the UI
    const formatDate = (date: Date): string => {
        return date.toISOString().split('T')[0];
    };

    // Helper to format time for display
    const formatTime = (timeStr: string): string => {
        if (!timeStr) return "--:--";
        return timeStr.substring(0, 5); // Extract HH:MM from HH:MM:SS
    };

    // Handle clicking on a report in the timeline
    const handleReportClick = (index: number) => {
        // Validate index is within bounds
        const timeline = report.timeline || [];
        if (index < 0 || index >= timeline.length) {
            console.warn("Invalid index selected:", index);
            return;
        }
        
        // First clear any error messages when changing selection
        setError(null);
        
        // Update selected index
        setSelectedIndex(index);
        
        // If report type is selected, run validation with new index
        if (selectedType) {
            // Get adjacent report
            const adjacentReport = timeline[index];
            
            // Validate report type sequence
            const validationResult = validateInsertion(
                selectedType as ReportType,
                adjacentReport?.report_type,
                insertPosition
            );
            
            if (!validationResult.isValid) {
                setError(validationResult.errorMessage || `Cannot insert ${selectedType} report ${insertPosition} this report.`);
                setHasTimeGap(false); // Ensure we update hasTimeGap state for invalid selections
                return;
            }
            
            // For PORT and STEAMING, also check time gaps
            if (selectedType === "PORT" || selectedType === "STEAMING") {
                const result = checkTimeGap();
                if (!result.hasGap && result.errorMessage) {
                    setError(result.errorMessage);
                    setHasTimeGap(false); // Ensure hasTimeGap state is false for invalid selections
                } else {
                    // Explicitly set hasTimeGap to true for valid selections
                    setHasTimeGap(true);
                }
            } else {
                // For non-period reports, always set hasTimeGap to true when report type sequence is valid
                setHasTimeGap(true);
            }
        }
    };

    // Determine if the selected report type is period-based
    const isPeriodBasedReport = selectedType === "PORT" || selectedType === "STEAMING";

    return (
        <div className="p-2 sm:p-3">
            <h3 className="mb-2 sm:mb-3 text-base sm:text-lg font-medium text-gray-900">
                Insert New Report
            </h3>

            {/* Timeline visualization - always shown */}
            <TimelineVisualization
                timeline={report.timeline || []}
                selectedDate={report.selectedDate}
                selectedType={selectedType}
                selectedIndex={selectedIndex}
                insertPosition={insertPosition}
                hasTimeGap={hasTimeGap}
                isPeriodBasedReport={isPeriodBasedReport}
                onReportClick={handleReportClick}
            />

            {/* Report Type Selection */}
            <div className={`mt-2 mb-2 sm:mb-3 ${selectedType ? "" : "border p-2 rounded-md bg-blue-50"}`}>
                <label className="block mb-1 text-xs sm:text-sm font-medium text-gray-700">
                    {selectedType ? "Report Type" : "Select Report Type to Insert"}
                </label>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-1 sm:gap-2">
                    {REPORT_TYPES.map((reportType) => {
                        const isPeriod = reportType === "PORT" || reportType === "STEAMING";
                        return (
                            <button
                                key={reportType}
                                className={classNames(
                                    "px-2 py-1 sm:px-3 sm:py-1.5 text-xs sm:text-sm font-medium rounded-md relative",
                                    {
                                        "bg-blue-600 text-white": selectedType === reportType,
                                        "bg-gray-100 text-gray-700 hover:bg-gray-200": selectedType !== reportType,
                                    }
                                )}
                                onClick={() => {
                                    // Clear error when changing report type
                                    setError(null);
                                    setSelectedType(reportType);
                                    
                                    // Reset hasTimeGap - will be recalculated in useEffect
                                    // No need to validate immediately since useEffect will handle it
                                    // when selectedType, selectedIndex and insertPosition change
                                    setHasTimeGap(false);
                                }}
                            >
                                {reportType}
                                {isPeriod && (
                                    <span className="absolute -top-1 -right-1 text-xs px-1 bg-blue-200 text-blue-800 rounded-full" style={{ fontSize: '0.65rem' }}>
                                        period
                                    </span>
                                )}
                            </button>
                        );
                    })}
                </div>
            </div>

            {/* Show info message only after selecting a report type - more compact version */}
            {selectedType && (
                <div className={`p-2 mb-2 text-xs rounded-md ${
                    selectedType === "PORT" || selectedType === "STEAMING" 
                        ? (hasTimeGap ? 'text-blue-700 bg-blue-100' : 'text-red-700 bg-red-100') 
                        : 'text-gray-700 bg-gray-100'
                }`}>
                    {selectedType === "PORT" || selectedType === "STEAMING" ? (
                        hasTimeGap ? (
                            <>
                                <p className="font-medium">{selectedType} reports represent a time period rather than a single event.</p>
                                <p className="mt-1">Will cover the entire time gap between adjacent reports.</p>
                            </>
                        ) : (
                            <>
                                <p className="font-medium">No Time Gap Available</p>
                                <p>Cannot insert {selectedType.toLowerCase()} report - no time gap between the adjacent reports.</p>
                            </>
                        )
                    ) : (
                        <p>{selectedType} reports represent specific events that occur at a single point in time.</p>
                    )}
                </div>
            )}

            {/* Timeline Position Selection - only show after selecting a report type */}
            {selectedType && report.timeline && report.timeline.length > 0 && (
                <div className="mb-2 sm:mb-3">
                    <label className="block mb-1 text-xs sm:text-sm font-medium text-gray-700 flex items-center">
                        Insert Position
                        {isPeriodBasedReport ? (
                            hasTimeGap ? (
                                <span className="ml-2 text-green-500 text-xs flex items-center" style={{ fontSize: '0.65rem' }}>
                                    <svg className="w-2 h-2 mr-1" fill="currentColor" viewBox="0 0 20 20">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                    </svg>
                                    Time gap available
                                </span>
                            ) : (
                                <span className="ml-2 text-red-500 text-xs flex items-center" style={{ fontSize: '0.65rem' }}>
                                    <svg className="w-2 h-2 mr-1" fill="currentColor" viewBox="0 0 20 20">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                    </svg>
                                    No time gap
                                </span>
                            )
                        ) : null}
                    </label>

                    {/* Before/After Toggle */}
                    <div className="mb-2">
                        <div className="flex gap-2 mb-1">
                            <button
                                type="button"
                                className={classNames(
                                    "flex-1 px-2 py-1 text-xs sm:text-sm font-medium rounded-md",
                                    insertPosition === "before"
                                        ? "bg-blue-600 text-white"
                                        : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                                )}
                                onClick={() => {
                                    // Clear errors when changing insertion position
                                    setError(null);
                                    setInsertPosition("before");
                                    
                                    // If we have a report type and valid selection, revalidate
                                    if (selectedType && selectedIndex >= 0 && selectedIndex < (report.timeline || []).length) {
                                        const adjacentReport = report.timeline?.[selectedIndex];
                                        
                                        // Validate report type sequence
                                        const validationResult = validateInsertion(
                                            selectedType as ReportType,
                                            adjacentReport?.report_type,
                                            "before"
                                        );
                                        
                                        if (!validationResult.isValid) {
                                            setError(validationResult.errorMessage || `Cannot insert ${selectedType} report before this report.`);
                                            setHasTimeGap(false); // Ensure hasTimeGap is false for invalid selections
                                            return;
                                        }
                                        
                                        // For PORT and STEAMING, check time gaps
                                        if (selectedType === "PORT" || selectedType === "STEAMING") {
                                            const result = checkTimeGap();
                                            if (!result.hasGap && result.errorMessage) {
                                                setError(result.errorMessage);
                                                setHasTimeGap(false);
                                            } else {
                                                // Explicitly set hasTimeGap to true for valid selections
                                                setHasTimeGap(true);
                                            }
                                        } else {
                                            // For non-period reports, always set hasTimeGap to true when report type sequence is valid
                                            setHasTimeGap(true);
                                        }
                                    }
                                }}
                            >
                                Before Selected Report
                            </button>
                            <button
                                type="button"
                                className={classNames(
                                    "flex-1 px-2 py-1 text-xs sm:text-sm font-medium rounded-md",
                                    insertPosition === "after"
                                        ? "bg-blue-600 text-white"
                                        : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                                )}
                                onClick={() => {
                                    // Clear errors when changing insertion position
                                    setError(null);
                                    setInsertPosition("after");
                                    
                                    // If we have a report type and valid selection, revalidate
                                    if (selectedType && selectedIndex >= 0 && selectedIndex < (report.timeline || []).length) {
                                        const adjacentReport = report.timeline?.[selectedIndex];
                                        
                                        // Validate report type sequence
                                        const validationResult = validateInsertion(
                                            selectedType as ReportType,
                                            adjacentReport?.report_type,
                                            "after"
                                        );
                                        
                                        if (!validationResult.isValid) {
                                            setError(validationResult.errorMessage || `Cannot insert ${selectedType} report after this report.`);
                                            setHasTimeGap(false); // Ensure hasTimeGap is false for invalid selections
                                            return;
                                        }
                                        
                                        // For PORT and STEAMING, check time gaps
                                        if (selectedType === "PORT" || selectedType === "STEAMING") {
                                            const result = checkTimeGap();
                                            if (!result.hasGap && result.errorMessage) {
                                                setError(result.errorMessage);
                                                setHasTimeGap(false);
                                            } else {
                                                // Explicitly set hasTimeGap to true for valid selections
                                                setHasTimeGap(true);
                                            }
                                        } else {
                                            // For non-period reports, always set hasTimeGap to true when report type sequence is valid
                                            setHasTimeGap(true);
                                        }
                                    }
                                }}
                            >
                                After Selected Report
                            </button>
                        </div>
                    </div>

                    <select
                        className={classNames(
                            "w-full px-2 py-1 text-xs sm:text-sm border rounded-md",
                            isPeriodBasedReport && !hasTimeGap
                                ? "border-red-300 bg-red-50"
                                : "border-gray-300"
                        )}
                        value={selectedIndex}
                        onChange={(e) => setSelectedIndex(Number(e.target.value))}
                    >
                        {(report.timeline || []).map((item, index) => (
                            <option key={index} value={index}>
                                {item.report_type} ({item.from_local_date} {formatTime(item.from_local_time)} - {formatTime(item.to_local_time)})
                            </option>
                        ))}
                    </select>
                    
                    {/* Coverage info - more compact */}
                    {isPeriodBasedReport && hasTimeGap && selectedIndex >= 0 && (
                        insertPosition === "after" ? (
                            selectedIndex < (report.timeline || []).length - 1 ? (
                                <div className="mt-1 text-xs text-gray-500" style={{ fontSize: '0.65rem' }}>
                                    Coverage: {formatTime(report.timeline[selectedIndex]?.to_local_time || '')} to {formatTime(report.timeline[selectedIndex + 1]?.from_local_time || '')}
                                </div>
                            ) : (
                                selectedIndex === (report.timeline || []).length - 1 && (
                                    <div className="mt-1 text-xs text-gray-500" style={{ fontSize: '0.65rem' }}>
                                        Coverage: {formatTime(report.timeline[selectedIndex]?.to_local_time || '')} to 12:00
                                    </div>
                                )
                            )
                        ) : (
                            // Before insertion position
                            selectedIndex > 0 ? (
                                <div className="mt-1 text-xs text-gray-500" style={{ fontSize: '0.65rem' }}>
                                    Coverage: {formatTime(report.timeline[selectedIndex - 1]?.to_local_time || '')} to {formatTime(report.timeline[selectedIndex]?.from_local_time || '')}
                                </div>
                            ) : (
                                selectedIndex === 0 && (
                                    <div className="mt-1 text-xs text-gray-500" style={{ fontSize: '0.65rem' }}>
                                        Coverage: 12:00 (previous day) to {formatTime(report.timeline[0]?.from_local_time || '')}
                                    </div>
                                )
                            )
                        )
                    )}
                </div>
            )}

            {error && (
                <div className="p-2 mb-2 rounded-md bg-red-50 border border-red-200">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-red-800">
                                {error.includes("Cannot insert") ? "Cannot Insert Report" : "Error"}
                            </h3>
                            <div className="mt-1 text-xs text-red-700">
                                <p>{error}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Action Buttons */}
            <div className="flex justify-end gap-2">
                <button
                    onClick={onClose}
                    className="px-2 py-1 sm:px-3 sm:py-1.5 text-xs sm:text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                    Cancel
                </button>
                <button
                    onClick={handleSubmit}
                    disabled={!selectedType || (isPeriodBasedReport && !hasTimeGap)}
                    className={classNames(
                        "px-2 py-1 sm:px-3 sm:py-1.5 text-xs sm:text-sm font-medium text-white rounded-md",
                        !selectedType || (isPeriodBasedReport && !hasTimeGap)
                            ? "bg-gray-400 cursor-not-allowed"
                            : "bg-blue-600 hover:bg-blue-700"
                    )}
                >
                    {selectedType ? `Insert ${selectedType}` : "Select a Report Type"}
                </button>
            </div>
        </div>
    );
};

export default InsertReportForm; 
