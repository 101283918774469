import SVGConfigPrintTool from "components/_common/icons/tool/SVGConfigPrintTool";
import SVGReleaseManagementTool from "components/_common/icons/tool/SVGReleaseManagementTool";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { historyActions } from "redux/_core/history";
import { IStore } from "redux/store";
import { rootRoutes } from "routes";

type OwnProps = {};
type ToolListProps = PropsFromRedux & OwnProps;
function ToolList(props: ToolListProps) {
	const handleReleaseClick = () => {
		const url = rootRoutes.children.app.children.tools.children.list.children.releases.url();
		props.goTo("#releases", url);
	};

	const ToolCard = ({ 
		icon, 
		title, 
		description, 
		onClick,
		delay = 0
	}: { 
		icon: React.ReactNode, 
		title: string, 
		description: string, 
		onClick: () => void,
		delay?: number
	}) => {
		const [isVisible, setIsVisible] = useState(false);

		useEffect(() => {
			const timer = setTimeout(() => setIsVisible(true), delay);
			return () => clearTimeout(timer);
		}, [delay]);

		return (
			<div
				className={`group bg-white rounded-2xl p-8 cursor-pointer transition-all duration-500 ease-in-out hover:shadow-lg hover:bg-gradient-to-br from-white to-gray-50 transform ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}
				onClick={onClick}
			>
				<div className="flex flex-col items-center text-center relative before:absolute before:inset-0 before:bg-blue-50/0 before:rounded-xl before:transition-colors before:duration-500 group-hover:before:bg-blue-50/10">
					<div className="relative mb-6 text-[#6B7280] transition-all duration-500 ease-in-out group-hover:text-blue-600 group-hover:rotate-[8deg]">
						{icon}
					</div>
					<h3 className="relative text-lg font-semibold text-gray-900 mb-3 transition-colors duration-500 group-hover:text-blue-600">
						{title}
					</h3>
					<p className="relative text-[15px] leading-relaxed text-gray-600 max-w-xs transition-all duration-500 group-hover:text-gray-700">
						{description}
					</p>
				</div>
			</div>
		);
	};

	return (
		<div className="min-h-[calc(100vh-98px)] bg-gradient-to-br from-gray-50 to-gray-100 m-0">
			<div className="p-6 md:p-8 lg:p-12 max-w-[1600px] mx-auto">
				<div className="mb-12">
					<h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-2">Tools</h1>
					<p className="text-gray-600 text-lg">Access and manage your workspace tools</p>
				</div>
				
				<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 xl:gap-8">
					<ToolCard
						icon={<div className="w-16 h-16 flex items-center justify-center"><SVGConfigPrintTool /></div>}
						title="Config/Print Manager"
						description="Manage and customize your configuration settings and print preferences with our intuitive interface"
						onClick={() => props.goToConfigsAndPrints()}
						delay={100}
					/>

					<ToolCard
						icon={
							<div className="w-16 h-16 flex items-center justify-center">
								<span className='text-5xl text-[#6B7280] bp3-icon bp3-icon-id-number' />
							</div>
						}
						title="Certificate Manager"
						description="Track and manage user training certificates efficiently in one centralized location"
						onClick={() => props.goToTrainingCertificate()}
						delay={200}
					/>

					<ToolCard
						icon={
							<div className="w-16 h-16 flex items-center justify-center">
								<span className="text-5xl text-[#6B7280] bp3-icon bp3-icon-history" />
							</div>
						}
						title="Activity Log"
						description="Monitor and review all configuration and print activities with detailed logging"
						onClick={() => props.goToConfigsAndPrintsActivityLogger()}
						delay={300}
					/>

					<ToolCard
						icon={
							<div className="w-16 h-16 flex items-center justify-center">
								<span className="text-5xl text-[#6B7280] bp3-icon bp3-icon-people" />
							</div>
						}
						title="Groups"
						description="Create, organize, and manage user groups with advanced permission controls"
						onClick={() => props.goTo("#groups", rootRoutes.children.app.children.tools.children.list.children.groups.url())}
						delay={400}
					/>

					<ToolCard
						icon={<div className="w-16 h-16 flex items-center justify-center"><SVGReleaseManagementTool /></div>}
						title="Release Manager"
						description="Streamline your release process with version control and deployment management"
						onClick={handleReleaseClick}
						delay={500}
					/>

					<ToolCard
						icon={
							<div className="w-16 h-16 flex items-center justify-center">
								<span className="text-5xl text-[#6B7280] bp3-icon bp3-icon-timeline-events" />
							</div>
						}
						title="CNR Activity Monitor"
						description="Track all changes made to CNR records across vessels with complete audit trail"
						onClick={() => props.goTo("#cnractivity", rootRoutes.children.app.children.tools.children.list.children.cnractivity.url())}
						delay={600}
					/>
				</div>
			</div>
		</div>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		goToConfigsAndPrints() {
			dispatch(
				historyActions.push(
					"#configsAndPrints",
					rootRoutes.children.app.children.tools.children.list.children.configPrint.url()
				)
			);
		},
		goToConfigsAndPrintsActivityLogger() {
			dispatch(
				historyActions.push(
					"#configsAndPrintsActivityLog",
					rootRoutes.children.app.children.tools.children.list.children.configPrintActivityLog.url()
				)
			);
		},
		goToTrainingCertificate() {
			dispatch(
				historyActions.push(
					"#trainingCertificate",
					rootRoutes.children.app.children.tools.children.list.children.trainingCertificate.url()
				)
			);
		},
		goTo(reference: string, url: string) {
			dispatch(
				historyActions.push(
					reference,
					url
				)
			);
		}
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ToolList);
