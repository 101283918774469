import React, { useState } from "react";
import { ITabReport } from "redux/app/logs/@types";
import classNames from "classnames";
import moment from "moment";

interface IResubmitFormProps {
    report: ITabReport;
    onClose: () => void;
    onSubmit: (data: any) => void;
}

const ResubmitForm: React.FC<IResubmitFormProps> = ({
    report,
    onClose,
    onSubmit,
}) => {
    const [reason, setReason] = useState<string>("");
    const [error, setError] = useState<string | null>(null);

    const validateResubmission = (): boolean => {
        // Check if the report is less than three months old
        const reportDate = moment(report.selectedDate);
        const threeMonthsAgo = moment().subtract(3, 'months');

        if (reportDate.isBefore(threeMonthsAgo)) {
            setError("Reports older than three months cannot be resubmitted. Please use the History process instead.");
            return false;
        }

        return true;
    };

    const handleSubmit = () => {
        if (!reason.trim()) {
            setError("Please provide a reason for resubmission");
            return;
        }

        if (!validateResubmission()) {
            return;
        }

        // Create backup before resubmission
        const backup = {
            ...report,
            modelName: "backup",
            backupNote: `Report sent back for resubmission. Reason: ${reason}`,
            backupDate: moment().toISOString()
        };

        onSubmit({
            reason,
            backup,
            newStatus: "RESUBMIT"
        });
    };

    return (
        <div className="p-4">
            <h3 className="mb-4 text-lg font-medium text-gray-900">
                Send Back for Corrections
            </h3>

            {/* Report Info */}
            <div className="p-4 mb-4 bg-gray-50 rounded-md">
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-500">
                            Selected Date
                        </label>
                        <p className="mt-1 text-sm text-gray-900">
                            {moment(report.selectedDate).format("DD-MM-YYYY")}
                        </p>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-500">
                            Current Status
                        </label>
                        <p className="mt-1 text-sm text-gray-900">
                            {report.doc_status}
                        </p>
                    </div>
                </div>
            </div>

            {/* Reason Input */}
            <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                    Reason for Resubmission
                </label>
                <textarea
                    className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md"
                    rows={4}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Please provide a detailed reason for sending this report back for corrections..."
                />
            </div>

            {error && (
                <div className="p-3 mb-4 text-sm text-red-700 bg-red-100 rounded-md">
                    {error}
                </div>
            )}

            {/* Warning Message */}
            <div className="p-3 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-md">
                Warning: This action will change the report status to "RESUBMIT" and send it back to the master for corrections.
                A backup will be created before the change.
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end gap-3">
                <button
                    onClick={onClose}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                    Cancel
                </button>
                <button
                    onClick={handleSubmit}
                    disabled={!reason.trim()}
                    className={classNames(
                        "px-4 py-2 text-sm font-medium text-white rounded-md",
                        {
                            "bg-blue-600 hover:bg-blue-700": reason.trim(),
                            "bg-gray-300 cursor-not-allowed": !reason.trim(),
                        }
                    )}
                >
                    Send for Resubmission
                </button>
            </div>
        </div>
    );
};

export default ResubmitForm; 