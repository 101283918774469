import React, { useState } from "react";
import { ITabReport } from "redux/app/logs/@types";
import classNames from "classnames";
import moment from "moment";

interface ITimezoneChangeFormProps {
    report: ITabReport;
    onClose: () => void;
    onSubmit: (data: any) => void;
}

const TIMEZONES = Array.from({ length: 25 }, (_, i) => {
    const offset = i - 12;
    const sign = offset >= 0 ? "+" : "-";
    const hours = Math.abs(offset).toString().padStart(2, "0");
    return `${sign}${hours}:00`;
});

const TimezoneChangeForm: React.FC<ITimezoneChangeFormProps> = ({
    report,
    onClose,
    onSubmit,
}) => {
    const [selectedReport, setSelectedReport] = useState<number | null>(null);
    const [fromTimezone, setFromTimezone] = useState<string>("");
    const [toTimezone, setToTimezone] = useState<string>("");
    const [error, setError] = useState<string | null>(null);

    // Handle report selection and auto-populate timezones
    const handleReportSelect = (index: number) => {
        setSelectedReport(index);
        const selectedReportData = report.timeline[index];
        // Auto-populate from and to timezones
        setFromTimezone(selectedReportData.from_zd || "");
        setToTimezone(selectedReportData.to_zd || ""); // Initially set same as from_zd
    };

    const handleSubmit = () => {
        if (selectedReport === null) {
            setError("Please select a report");
            return;
        }

        if (!fromTimezone || !toTimezone) {
            setError("Please select both timezones");
            return;
        }

        // Create backup before timezone change
        const backup = {
            ...report,
            modelName: "backup",
            backupNote: `Changed timezone for ${report.timeline[selectedReport].report_type} from ${fromTimezone} to ${toTimezone}`,
            backupDate: moment().toISOString()
        };

        onSubmit({
            reportIndex: selectedReport,
            fromTimezone,
            toTimezone,
            backup
        });
    };

    return (
        <div className="p-4">
            <h3 className="mb-4 text-lg font-medium text-gray-900">
                Change Timezone
            </h3>

            {/* Report Selection */}
            <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                    Select Report
                </label>
                <select
                    className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md"
                    value={selectedReport ?? ""}
                    onChange={(e) => handleReportSelect(Number(e.target.value))}
                >
                    <option value="">Select a report...</option>
                    {(report.timeline || []).map((item, index) => (
                        <option key={index} value={index}>
                            {item.report_type} ({item.to_local_date} {item.to_local_time} {item.from_zd})
                        </option>
                    ))}
                </select>
            </div>

            {/* Timezone Selection */}
            <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                        From Timezone
                    </label>
                    <select
                        className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md"
                        value={fromTimezone}
                        onChange={(e) => setFromTimezone(e.target.value)}
                    >
                        <option value="">Select timezone...</option>
                        {TIMEZONES.map((tz) => (
                            <option key={tz} value={tz}>
                                UTC {tz}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                        To Timezone
                    </label>
                    <select
                        className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md"
                        value={toTimezone}
                        onChange={(e) => setToTimezone(e.target.value)}
                    >
                        <option value="">Select timezone...</option>
                        {TIMEZONES.map((tz) => (
                            <option key={tz} value={tz}>
                                UTC {tz}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {error && (
                <div className="p-3 mb-4 text-sm text-red-700 bg-red-100 rounded-md">
                    {error}
                </div>
            )}

            {/* Warning Message */}
            {selectedReport !== null && fromTimezone && toTimezone && (
                <div className="p-3 mb-4 text-sm text-yellow-700 bg-yellow-100 rounded-md">
                    Warning: This will change the timezone of the selected report.
                    Please ensure this change maintains the chronological sequence of events.
                    A backup will be created before the change.
                </div>
            )}

            {/* Action Buttons */}
            <div className="flex justify-end gap-3">
                <button
                    onClick={onClose}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                    Cancel
                </button>
                <button
                    onClick={handleSubmit}
                    disabled={selectedReport === null || !fromTimezone || !toTimezone}
                    className={classNames(
                        "px-4 py-2 text-sm font-medium text-white rounded-md",
                        {
                            "bg-blue-600 hover:bg-blue-700": selectedReport !== null && fromTimezone && toTimezone,
                            "bg-gray-300 cursor-not-allowed": selectedReport === null || !fromTimezone || !toTimezone,
                        }
                    )}
                >
                    Change Timezone
                </button>
            </div>
        </div>
    );
};

export default TimezoneChangeForm; 