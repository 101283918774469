import React, { useState } from "react";
import ModalPortal from "components/app/_common/ModalPortal";
import { ITabReport } from "redux/app/logs/@types";
import classNames from "classnames";
import { WrenchIcon } from "@heroicons/react/24/outline";
import InsertReportForm from "./InsertReportForm";
import DeleteReportForm from "./DeleteReportForm";
import TimezoneChangeForm from "./TimezoneChangeForm";
import DateTimeChangeForm from "./DateTimeChangeForm";
import ResubmitForm from "./ResubmitForm";

interface IToolsModalProps {
    isOpen: boolean;
    onClose: () => void;
    report: ITabReport;
    vesselName: string;
    vesselImo: string;
    onAction: (actionType: string, data: any) => void;
    isLoading: boolean;
}

type ActionType = "INSERT" | "DELETE" | "TIMEZONE" | "DATETIME" | "RESUBMIT";

const ToolsModal: React.FC<IToolsModalProps> = ({
    isOpen,
    onClose,
    report,
    vesselName,
    vesselImo,
    onAction,
    isLoading
}) => {
    const [selectedAction, setSelectedAction] = useState<ActionType | null>(null);

    const handleActionSelect = (action: ActionType) => {
        if (isLoading) return;
        setSelectedAction(action);
    };

    const handleActionSubmit = (data: any) => {
        if (isLoading) return;
        if (selectedAction) {
            onAction(selectedAction, data);
        }
    };

    const handleBack = () => {
        if (isLoading) return;
        setSelectedAction(null);
    };

    const handleClose = () => {
        if (isLoading) return;
        onClose();
    };

    const actions = [
        {
            id: "INSERT",
            name: "Insert Reports",
            description: "Insert a new report at any position in the timeline",
            icon: "➕",
        },
        {
            id: "DELETE",
            name: "Delete Reports",
            description: "Remove existing reports from the timeline",
            icon: "🗑️",
        },
        {
            id: "TIMEZONE",
            name: "Change Timezones",
            description: "Adjust the timezone of From/To date/time",
            icon: "🌐",
        },
        {
            id: "DATETIME",
            name: "Change Date/Time",
            description: "Correct the date/time sequence",
            icon: "📅",
        },
        {
            id: "RESUBMIT",
            name: "Send Back for Corrections",
            description: "Send the report back for re-submission",
            icon: "↩️",
        },
    ];

    const renderActionForm = () => {
        switch (selectedAction) {
            case "INSERT":
                return (
                    <InsertReportForm
                        report={report}
                        vesselName={vesselName}
                        vesselImo={vesselImo}
                        onClose={handleBack}
                        onSubmit={handleActionSubmit}
                    />
                );
            case "DELETE":
                return (
                    <DeleteReportForm
                        report={report}
                        onClose={handleBack}
                        onSubmit={handleActionSubmit}
                    />
                );
            case "TIMEZONE":
                return (
                    <TimezoneChangeForm
                        report={report}
                        onClose={handleBack}
                        onSubmit={handleActionSubmit}
                    />
                );
            case "DATETIME":
                return (
                    <DateTimeChangeForm
                        report={report}
                        onClose={handleBack}
                        onSubmit={handleActionSubmit}
                    />
                );
            case "RESUBMIT":
                return (
                    <ResubmitForm
                        report={report}
                        onClose={handleBack}
                        onSubmit={handleActionSubmit}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <ModalPortal
            isActive={isOpen}
            onClose={handleClose}
            width={selectedAction ? "min(800px, 90%)" : "min(600px, 85%)"}
            isOverlayClickCloseable={!selectedAction && !isLoading}
        >
            <div className="bg-white rounded-lg shadow-xl max-h-[90vh] flex flex-col">
                {/* Header */}
                <div className="flex items-center justify-between p-2 sm:p-3 border-b border-gray-200 flex-shrink-0">
                    <div className="flex items-center">
                        <div className="hidden sm:flex items-center justify-center w-8 h-8 bg-blue-100 rounded-full">
                            <WrenchIcon className="w-5 h-5 text-blue-600" />
                        </div>
                        <h3 className="ml-2 text-base sm:text-lg font-medium text-gray-900">
                            {selectedAction ? actions.find(a => a.id === selectedAction)?.name : "Report Management Tools"}
                        </h3>
                    </div>
                    <div className="flex items-center">
                        {selectedAction && !isLoading && (
                            <button
                                onClick={handleBack}
                                className="mr-3 text-xs sm:text-sm text-blue-600 hover:text-blue-800"
                            >
                                ← Back
                            </button>
                        )}
                        <button
                            onClick={handleClose}
                            disabled={isLoading}
                            className={classNames(
                                "text-gray-400 hover:text-gray-500",
                                { "cursor-not-allowed opacity-50": isLoading }
                            )}
                        >
                            <span className="sr-only">Close</span>
                            <span className="text-xl sm:text-2xl">×</span>
                        </button>
                    </div>
                </div>

                {/* Content */}
                <div className="p-3 overflow-y-auto flex-grow">
                    {selectedAction ? (
                        <div>
                            {renderActionForm()}
                        </div>
                    ) : (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                            {actions.map((action) => (
                                <button
                                    key={action.id}
                                    className={classNames(
                                        "flex items-center p-2 sm:p-3 text-left border rounded-lg transition-colors",
                                        isLoading
                                            ? "border-gray-200 bg-gray-50 cursor-not-allowed opacity-50"
                                            : "border-gray-200 hover:bg-gray-50"
                                    )}
                                    onClick={() => handleActionSelect(action.id as ActionType)}
                                    disabled={isLoading}
                                >
                                    <span className="text-xl sm:text-2xl mr-2 sm:mr-3">{action.icon}</span>
                                    <div>
                                        <h4 className="font-medium text-sm sm:text-base text-gray-900">
                                            {action.name}
                                        </h4>
                                        <p className="mt-0.5 text-xs sm:text-sm text-gray-500">
                                            {action.description}
                                        </p>
                                    </div>
                                </button>
                            ))}
                        </div>
                    )}
                </div>

                {/* Footer - Only shown in the main tools view */}
                {!selectedAction && (
                    <div className="flex justify-end gap-2 px-3 py-2 bg-gray-50 rounded-b-lg flex-shrink-0">
                        <button
                            onClick={handleClose}
                            disabled={isLoading}
                            className={classNames(
                                "px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm",
                                isLoading
                                    ? "opacity-50 cursor-not-allowed"
                                    : "hover:bg-gray-50"
                            )}
                        >
                            Close
                        </button>
                    </div>
                )}
            </div>
        </ModalPortal>
    );
};

export default ToolsModal; 
