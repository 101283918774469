import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { rootRoutes } from "../../../routes";
import Tool from "components/app/tool/Tool";
import ScreenToolsList from "./list";
import ScreenConfigAndPrints from "./config";
import ScreentConfigsAndPrintsActivityLog from "./activityLog";
import TrainingCertificate from "./trainingCertificate";
import Groups from "./groups";
import Releases from "./releases";
import CNRActivity from "components/app/tool/cnractivity";

const toolRoutes = rootRoutes.children.app.children.tools.children;
type ScreenToolProps = React.PropsWithChildren<{}>;
function ScreenTool(props: ScreenToolProps) {
	return (
		<Tool>
			<Switch>
				<Route exact path={toolRoutes.list.path} component={ScreenToolsList} />
				<Route
					path={toolRoutes.list.children.configPrint.path}
					component={ScreenConfigAndPrints}
				/>
				<Route
					path={toolRoutes.list.children.configPrintActivityLog.path}
					component={ScreentConfigsAndPrintsActivityLog}
				/>
				<Route
					path={toolRoutes.list.children.trainingCertificate.path}
					component={TrainingCertificate}
				/>
				<Route
					path={
						toolRoutes.list.children.trainingCertificate.children.approvals.path
					}
					component={TrainingCertificate}
				/>
				<Route path={toolRoutes.list.children.groups.path} component={Groups} />
				<Route path={toolRoutes.list.children.releases.path} component={Releases} />
				<Route path={toolRoutes.list.children.cnractivity.path} component={CNRActivity} />
				<Route render={RedirectComponent} />
			</Switch>
		</Tool>
	);
}

function RedirectComponent() {
	return <Redirect to={toolRoutes.list.url()} />;
}
export default ScreenTool;
