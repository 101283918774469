import React from "react";
import classNames from "classnames";
import { INavnucFormData, INavnucRecord } from "redux/app/report/@types";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import { IStore } from "redux/store";
import { commonUtils } from "redux/_common";

type TableDataItem = {
	key: string;
	value: string | number;
};
type OwnProps = {
	columns: INavnucRecord;
	index: number;
	labels: (keyof INavnucFormData)[];
};
type NavnucReportTableColumnProps = PropsFromRedux & OwnProps;
function TableColumns(props: NavnucReportTableColumnProps) {
	const {
		index,
		columns,
		labels,
	} = props;

	return (
		<>
			{/* S. No. */}
			<td
				className={classNames(
					"px-1 text-center py-3 text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]",
				)}
			>
				{index + 1}
			</td>
			{labels.map((label) => {
				const value = columns?.data?.[label];
				if (label === "evidence") {
					return (
						<td
							key={label}
							className={classNames(
								"px-1 text-center py-3 text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]",
							)}
						>
							{columns?.data?.[label]?.map(attachment => {
								const imageUrl = commonUtils.getAPIBaseUrl() + attachment.url
								const imageTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif", "image/webp", "image/svg+xml"];

								return imageTypes.includes(attachment.fileType) ? (
									<img
										key={attachment.attachmentId}
										src={imageUrl}
										alt={label}
										width={150}
										className="cursor-pointer"
										onClick={() => {
											window.open(imageUrl)
										}}
									/>
								) : (
									<a
										key={attachment.attachmentId}
										href={imageUrl}
										target="_blank"
										rel="noopener noreferrer"
										className="text-blue-700"
									>
										{attachment.fileName}
									</a>
								);
							})}
						</td>
					)
				}
				else return (
					<td
						key={label}
						className={classNames(
							"px-1 text-center py-3 text-[12px] border leading-4 tracking-wide border-b border-gray-300 font-redhat-text text-[#2D2D2D]",
						)}
					>
						{value
							? typeof value === "number" ? value.toFixed(2)
								: label !== "vesselName" && typeof value === "string" ? value[0].toUpperCase() + value.replaceAll("_", " ").slice(1)
									: value
							: "-"
						}
					</td>)
			})}
		</>
	);
}

function mapStateToProps(store: IStore, ownProps: OwnProps) {
	return {};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(TableColumns);

