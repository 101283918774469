import extActionTypes  from "./action-types";
import { jobActions } from "../../_core/job";
import { createAction } from "typesafe-actions";
import extConstants from "./constants";

const { LOAD_TASK, SET_FORM, FORM_FIELD, USER_AUTHENTICATED, FORM_FIELD_PATCH, FORM_UPDATE, FORM_SUBMIT } = extActionTypes;

const extActions = {
	extUserAuthenticated: createAction(
		USER_AUTHENTICATED,
		(isAuthenticated: boolean) => ({ isAuthenticated })
	)(),
	loadTask: createAction(LOAD_TASK, (token: string) => token)(),
	setExtForm: createAction(SET_FORM, (form: any) => form)(),
	formFieldsEdit: createAction(FORM_FIELD, (field: any) => field)(),
	formFieldsPatch: createAction(FORM_FIELD_PATCH, (field: any) => field)(),
	formUpdate: createAction(FORM_UPDATE,(field: any) => field)(),
	formSubmit: createAction(FORM_SUBMIT, (isSubmit: boolean) => ({
		isSubmit,
	}))(),
	navnucSubmit: createAction(extActionTypes.NAVNUC_SUBMIT,(formData: Record<string, any>) => ({
			formData
		})
	)(),
	navnucAttachmentUpload: createAction(extActionTypes.NAVNUC_ATTACHMENT_UPLOAD,
		(
			id: string,
			attachment: any,
		) => ({
			id,
			attachment,
		})
	)(),
	_jobs: {
		vesselListLoad: jobActions(extConstants.FEATURE, LOAD_TASK),
		formUpdate: jobActions(extConstants.FEATURE, FORM_UPDATE),
		navnucSubmit: jobActions(extConstants.FEATURE, extActionTypes.NAVNUC_SUBMIT),
		navnucAttachmentUpload: jobActions(extConstants.FEATURE, extActionTypes.NAVNUC_ATTACHMENT_UPLOAD),
	},
};

export default extActions;
