import React, { useEffect } from 'react'
import Table from './_elements/Table';
import { IStore } from 'redux/store';
import reportSelectors from 'redux/app/report/selectors';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import VesselSubTypeSelector from '../../common/VesselSubTypeSelector';
import reportActions from 'redux/app/report/actions';
import { comprehensiveNoonReportAllVesselsHeaders, comprehensiveNoonReportKeyTypes } from 'redux/app/report/constants';
import { IComprehensiveNoonReportFilters } from 'redux/app/report/@types';
import Filter from './_elements/Filter';
import { InsightsPanel } from "./_elements/InsightsPanel";

type OwnProps = {};
type ComprehensiveNoonReportProps = PropsFromRedux & OwnProps;
function ComprehensiveNoonReport(props: ComprehensiveNoonReportProps) {
	const {
		vesselSubType,
		jobToLoadAllVesselsComprehensiveNoonReport,
		rawComprehensiveNoonReportData,
	} = props;

	useEffect(() => {
		// if (vesselSubType)
		props.loadComprehensiveNoonReportAllVesselsData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselSubType]);

	useEffect(() => {
		props.setVesselSubType("");

		return () => {
			props.setVesselSubType("");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isLoading = jobToLoadAllVesselsComprehensiveNoonReport.isLoading;

	const filterTypes = (key: string) => {
		if (comprehensiveNoonReportKeyTypes.dates.includes(key)) return "date";
		if (comprehensiveNoonReportKeyTypes.strings.includes(key))
			return "text";
		if (comprehensiveNoonReportKeyTypes.dropdowns.includes(key))
			return "dropdown";
		return "number";
	};

	const filterLabels: IComprehensiveNoonReportFilters[] = Object.keys(
		comprehensiveNoonReportAllVesselsHeaders
	).map((key) => ({
		label: key,
		key: comprehensiveNoonReportAllVesselsHeaders[key],
		type: filterTypes(comprehensiveNoonReportAllVesselsHeaders[key]),
	}));

	return (
		<div className="relative">
			<div className="flex flex-col">
				<div className="bg-white m-2 rounded-lg h-[calc(100vh-98px)] overflow-y-auto px-5">
					<div className="py-8 pb-0">
						<div className="flex items-center">
							<span
								title="go back"
								onClick={() => {
									window?.history?.back();
								}}
								className="mr-3 text-lg cursor-pointer bp3-icon bp3-icon-arrow-left"
							/>
							<h2 className="text-xl font-medium">
								Comprehensive Noon Report
							</h2>
						</div>
						<div className="flex flex-col w-full gap-4 sm:flex-row sm:items-end ">
							<div className="font-medium text-gray-600 min-w-28 w-36 md:w-56">
								<VesselSubTypeSelector
									onVesselSubTypeSelect={(
										vesselSubType: string
									) => {
										props.setVesselSubType(vesselSubType);
									}}
									isLoading={isLoading}
									selectedVesselSubType={vesselSubType}
									showDropdownLabel={false}
								/>
							</div>
							<div className="flex-grow font-medium text-gray-600">
								<Filter
									filterLabels={filterLabels}
									isLoading={isLoading}
								/>
							</div>
						</div>
					</div>

					<InsightsPanel
						noonReportData={rawComprehensiveNoonReportData}
					/>

					{isLoading ? (
						<div className="flex justify-center mt-20">
							Loading...
						</div>
					) : (
						// ) : isComprehensiveNoonReportEmpty ? (
						// 	<div className="flex items-center justify-center mt-20">
						// 		No data found
						// 	</div>
						<div className="w-full pt-4">
							<Table filterLabels={filterLabels} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const reportStore = reportSelectors._getStore(store);
	const vesselSubType = reportSelectors.getGenericVesselClass(reportStore);
	const jobToLoadAllVesselsComprehensiveNoonReport =
		reportSelectors.jobToLoadAllVesselsComprehensiveNoonReport(reportStore);
	const rawComprehensiveNoonReportData =
		reportSelectors.getRawComprehensiveNoonReportData(reportStore);
	return {
		vesselSubType,
		jobToLoadAllVesselsComprehensiveNoonReport,
		rawComprehensiveNoonReportData,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
	return {
		setVesselSubType(vesselSubType: string) {
			dispatch(reportActions.document.reportVesselClassSet(vesselSubType));
		},
		loadComprehensiveNoonReportAllVesselsData() {
			dispatch(reportActions.commands.comprehensiveNoonReportAllVesselLoad());
		},
	};
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ComprehensiveNoonReport);

