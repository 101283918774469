import React from 'react';

const SVGReleaseManagementTool = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 4L35.2583 10.5V23.5L24 30L12.7417 23.5V10.5L24 4Z" stroke="#666666" strokeWidth="2"/>
            <path d="M24 32L35.2583 38.5V44.5L24 51L12.7417 44.5V38.5L24 32Z" stroke="#666666" strokeWidth="2"/>
            <path d="M24 4V30M35.2583 10.5L24 17M12.7417 10.5L24 17" stroke="#666666" strokeWidth="2"/>
        </svg>
    );
};

export default SVGReleaseManagementTool; 