import React from 'react';

// Report type color definitions with inline styles
const reportTypeColors = {
    'ARRIVAL': { background: '#10B981', text: '#FFFFFF' }, // green-500
    'DEPARTURE': { background: '#8B5CF6', text: '#FFFFFF' }, // purple-500
    'PORT': { background: '#3B82F6', text: '#FFFFFF' }, // blue-500
    'STEAMING': { background: '#6366F1', text: '#FFFFFF' }, // indigo-500
    'CARGO': { background: '#EAB308', text: '#FFFFFF' }, // yellow-500
    'BUNKER': { background: '#F97316', text: '#FFFFFF' }, // orange-500
    'DEFAULT': { background: '#9CA3AF', text: '#FFFFFF' }, // gray-400
    'NEW_INSERTION': { background: '#FEE2E2', border: '1px dashed #F87171', text: '#B91C1C' } // red styling
};

// Format time for display
const formatTime = (timeStr: string) => {
    if (!timeStr) return "--:--";
    return timeStr.substring(0, 5); // Extract HH:MM from HH:MM:SS
};

// Format a date as YYYY-MM-DD
const formatDate = (date: Date): string => {
    if (!date || isNaN(date.getTime())) return "Invalid date";
    return date.toISOString().split('T')[0];
};

// Format time span compactly
const formatTimeSpan = (startTime: string, endTime: string) => {
    if (!startTime || !endTime) return "";
    return `${formatTime(startTime)}-${formatTime(endTime)}`;
};

interface TimelineReport {
    report_type: string;
    from_local_date: string;
    from_local_time: string;
    to_local_date: string;
    to_local_time: string;
    from_zd?: string;
    to_zd?: string;
    [key: string]: any;
}

interface TimelineVisualizationProps {
    timeline: TimelineReport[];
    selectedDate: string;
    selectedType: string | null;
    selectedIndex: number;
    insertPosition?: "before" | "after";
    hasTimeGap: boolean;
    isPeriodBasedReport: boolean;
    onReportClick: (index: number) => void;
}

const TimelineVisualization: React.FC<TimelineVisualizationProps> = ({
    timeline,
    selectedDate,
    selectedType,
    selectedIndex,
    insertPosition = "after",
    hasTimeGap,
    isPeriodBasedReport,
    onReportClick
}) => {
    // Safe report click handler with validation
    const handleReportClick = (index: number) => {
        if (index >= 0 && index < timeline.length) {
            onReportClick(index);
        } else {
            console.warn("Invalid report index:", index);
        }
    };
    
    if (!timeline || timeline.length === 0) {
        // Return a message if there's no timeline data
        return (
            <div className="p-2 bg-gray-100 rounded-md text-center text-gray-500 text-xs">
                No reports found in the timeline. This will be the first report.
            </div>
        );
    }

    // Validate selected index
    const validSelectedIndex = selectedIndex >= 0 && selectedIndex < timeline.length 
        ? selectedIndex 
        : -1;

    const reportDate = new Date(selectedDate);
    const yesterdayDate = new Date(reportDate);
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    
    // Function to check if there's a time gap between reports
    const hasGapBetweenReports = (index: number): boolean => {
        if (index < 0 || index >= timeline.length - 1) return false;
        
        const currentReport = timeline[index];
        const nextReport = timeline[index + 1];
        
        try {
            // Extract hours and minutes for direct comparison
            const [currentHours, currentMinutes] = currentReport.to_local_time.split(':').map(Number);
            const [nextHours, nextMinutes] = nextReport.from_local_time.split(':').map(Number);
            
            // Convert to total minutes for comparison
            const currentTotalMinutes = currentHours * 60 + currentMinutes;
            const nextTotalMinutes = nextHours * 60 + nextMinutes;
            
            // Get dates for comparison (handles day boundaries)
            const currentDate = new Date(currentReport.to_local_date).getTime();
            const nextDate = new Date(nextReport.from_local_date).getTime();
            
            let timeDiffMinutes = 0;
            
            // If on the same day, compare times directly
            if (currentDate === nextDate) {
                timeDiffMinutes = nextTotalMinutes - currentTotalMinutes;
            } else {
                // If different days, calculate the full gap
                const dayDiffMs = nextDate - currentDate;
                const dayDiffMinutes = dayDiffMs / (1000 * 60);
                // Add minutes from end of current day plus minutes into next day
                timeDiffMinutes = dayDiffMinutes - currentTotalMinutes + nextTotalMinutes;
            }
            
            // A gap of 1 minute or more is valid
            return timeDiffMinutes >= 1;
        } catch (e) {
            console.error("Error calculating time gap:", e);
            return false;
        }
    };

    // Format gap time for display
    const formatGapDuration = (index: number): string => {
        if (index < 0 || index >= timeline.length - 1) return "";
        
        const currentReport = timeline[index];
        const nextReport = timeline[index + 1];
        
        try {
            // Extract hours and minutes for direct comparison
            const [currentHours, currentMinutes] = currentReport.to_local_time.split(':').map(Number);
            const [nextHours, nextMinutes] = nextReport.from_local_time.split(':').map(Number);
            
            // Convert to total minutes for comparison
            const currentTotalMinutes = currentHours * 60 + currentMinutes;
            const nextTotalMinutes = nextHours * 60 + nextMinutes;
            
            // Get dates for comparison (handles day boundaries)
            const currentDate = new Date(currentReport.to_local_date).getTime();
            const nextDate = new Date(nextReport.from_local_date).getTime();
            
            let timeDiffMinutes = 0;
            
            // If on the same day, compare times directly
            if (currentDate === nextDate) {
                timeDiffMinutes = nextTotalMinutes - currentTotalMinutes;
            } else {
                // If different days, calculate the full gap
                const dayDiffMs = nextDate - currentDate;
                const dayDiffMinutes = dayDiffMs / (1000 * 60);
                // Add minutes from end of current day plus minutes into next day
                timeDiffMinutes = dayDiffMinutes - currentTotalMinutes + nextTotalMinutes;
            }
            
            if (timeDiffMinutes < 1) return "";
            
            const hours = Math.floor(timeDiffMinutes / 60);
            const minutes = Math.floor(timeDiffMinutes % 60);
            
            if (hours > 0) {
                return `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`;
            }
            return `${minutes}m`;
        } catch (e) {
            console.error("Error formatting gap duration:", e);
            return "";
        }
    };

    // Get the color style for the selected report type
    const getSelectedTypeColor = () => {
        if (!selectedType) return reportTypeColors.DEFAULT;
        return reportTypeColors[selectedType as keyof typeof reportTypeColors] || reportTypeColors.DEFAULT;
    };
    
    return (
        <div className="mb-4">
            <div className="flex justify-between items-center mb-1">
                <h4 className="text-xs font-medium text-gray-700">Timeline</h4>
                <div className="flex text-xs text-gray-500 space-x-2">
                    <span className="whitespace-nowrap">NOON {formatDate(yesterdayDate)}</span>
                    <span>→</span>
                    <span className="whitespace-nowrap">NOON {formatDate(reportDate)}</span>
                </div>
            </div>
            
            {/* Improved Timeline Container */}
            <div className="border border-gray-200 rounded-md overflow-hidden">
                {/* Timeline Reports - Cleaner Card Layout */}
                <div className="flex flex-col divide-y divide-gray-100">
                    {/* Show insertion marker at the very beginning if inserting before first report */}
                    {validSelectedIndex === 0 && insertPosition === "before" && selectedType && hasTimeGap && (
                        <div className="bg-blue-50 py-2 px-3 text-xs border-l-2 border-blue-500">
                            <div className="flex items-center">
                                <div className="mr-2 w-4 h-4 rounded-sm flex-shrink-0" 
                                    style={{ backgroundColor: getSelectedTypeColor().background }}>
                                </div>
                                <div>
                                    <span className="font-medium text-blue-700">
                                        Insert {selectedType} Here
                                    </span>
                                    <div className="text-gray-500 mt-0.5">
                                        From 12:00 (previous day) to {formatTime(timeline[0]?.from_local_time)}
                                    </div>
                                </div>
                                <div className="ml-auto">
                                    <svg className="h-4 w-4 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )}

                    {timeline.map((item, index) => {
                        if (!item) return null;
                        
                        const colorStyle = reportTypeColors[item.report_type as keyof typeof reportTypeColors] || reportTypeColors.DEFAULT;
                        const isSelected = index === validSelectedIndex;
                        const isInsertionPoint = selectedType && index === validSelectedIndex;
                        const timeGapAfter = hasGapBetweenReports(index);
                        const gapDuration = formatGapDuration(index);
                        
                        return (
                            <React.Fragment key={index}>
                                {/* Show "before" insertion marker for this report if selected*/}
                                {isInsertionPoint && insertPosition === "before" && selectedType && hasTimeGap && index > 0 && (
                                    <div className="bg-blue-50 py-2 px-3 text-xs border-l-2 border-blue-500">
                                        <div className="flex items-center">
                                            <div className="mr-2 w-4 h-4 rounded-sm flex-shrink-0" 
                                                style={{ backgroundColor: getSelectedTypeColor().background }}>
                                            </div>
                                            <div>
                                                <span className="font-medium text-blue-700">
                                                    Insert {selectedType} Here
                                                </span>
                                                {isPeriodBasedReport && index > 0 && (
                                                    <div className="text-gray-500 mt-0.5">
                                                        From {formatTime(timeline[index-1]?.to_local_time)} to {formatTime(item.from_local_time)}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="ml-auto">
                                                <svg className="h-4 w-4 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Report item */}
                                <div 
                                    className={`relative w-full p-3 cursor-pointer ${isSelected ? 'bg-blue-50' : 'bg-white hover:bg-gray-50'}`}
                                    onClick={() => handleReportClick(index)}
                                >
                                    <div className="flex items-center">
                                        {/* Color indicator and report type */}
                                        <div className="flex items-center mr-3">
                                            <div 
                                                className="w-4 h-4 rounded-sm mr-2"
                                                style={{ backgroundColor: colorStyle.background }}
                                            ></div>
                                            <span className="font-medium">{item.report_type}</span>
                                            <span className="ml-1 text-xs text-gray-500">#{index + 1}</span>
                                        </div>
                                        
                                        {/* Time display */}
                                        <div className="text-sm text-gray-600">
                                            {formatTime(item.from_local_time)} - {formatTime(item.to_local_time)}
                                        </div>
                                        
                                        {/* Selection indicator */}
                                        {isSelected && (
                                            <div className="ml-auto mr-2 w-2 h-2 rounded-full bg-blue-500"></div>
                                        )}
                                    </div>
                                </div>
                                
                                {/* Show "after" insertion marker if this report is selected */}
                                {isInsertionPoint && insertPosition === "after" && selectedType && hasTimeGap && (
                                    <div className="bg-blue-50 py-2 px-3 text-xs border-l-2 border-blue-500">
                                        <div className="flex items-center">
                                            <div className="mr-2 w-4 h-4 rounded-sm flex-shrink-0" 
                                                style={{ backgroundColor: getSelectedTypeColor().background }}>
                                            </div>
                                            <div>
                                                <span className="font-medium text-blue-700">
                                                    Insert {selectedType} Here
                                                </span>
                                                {isPeriodBasedReport && (
                                                    <div className="text-gray-500 mt-0.5">
                                                        {index < timeline.length - 1 ? (
                                                            <>From {formatTime(item.to_local_time)} to {formatTime(timeline[index+1]?.from_local_time)}</>
                                                        ) : (
                                                            <>From {formatTime(item.to_local_time)} to 12:00</>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="ml-auto">
                                                <svg className="h-4 w-4 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                
                                {/* Time gap indicator */}
                                {timeGapAfter && index < timeline.length - 1 && !isInsertionPoint && (
                                    <div className="bg-gray-50 py-1 px-3 text-xs text-gray-500 flex items-center">
                                        <svg className="w-3 h-3 mr-1 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <span>Gap: {gapDuration}</span>
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
                
                {/* Error or Warning Message */}
                {selectedType && !hasTimeGap && (
                    <div className="bg-red-50 p-2 text-xs text-red-700 border-t border-red-100">
                        <div className="flex items-center">
                            <svg className="w-3 h-3 mr-1 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                            </svg>
                            <span>No time gap available for {selectedType.toLowerCase()} report {insertPosition} selected report</span>
                        </div>
                    </div>
                )}

                {/* Instruction for insertion */}
                {selectedType && hasTimeGap && (
                    <div className="bg-blue-50 p-2 text-xs text-blue-700 border-t border-blue-100">
                        <div className="flex items-center">
                            <svg className="w-3 h-3 mr-1 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                            </svg>
                            <span>The new {selectedType.toLowerCase()} report will be inserted {insertPosition} report #{validSelectedIndex + 1}</span>
                        </div>
                    </div>
                )}
            </div>
            
            {/* Legend - compact row */}
            <div className="flex flex-wrap gap-x-2 gap-y-1 mt-2">
                {Object.entries(reportTypeColors)
                    .filter(([key]) => key !== 'DEFAULT' && key !== 'NEW_INSERTION')
                    .map(([type, colors]) => (
                        <span key={type} className="text-xs flex items-center">
                            <span className="w-2 h-2 inline-block mr-1 rounded-sm" 
                                style={{ background: colors.background }}></span>
                            {type}
                        </span>
                    ))}
            </div>
        </div>
    );
};

export default TimelineVisualization; 