import React, { useState, useEffect } from "react";
import { Spinner, HTMLTable, Button, InputGroup, Tabs, Tab, NonIdealState, Tooltip } from "@blueprintjs/core";
import { connect, ConnectedProps } from "react-redux";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { format } from "date-fns";
import Tool from "../Tool";

type CNRActivityProps = PropsFromRedux;

interface Backup {
  _id: string;
  modelName: string;
  modifiedBy: {
    email: string;
    name: string;
    _id: string;
  };
  backupDate: string;
  reportId: string;
  vesselName?: string;
  vesselId?: string;
  action?: string;
  timeline?: any[];
  docStatus?: string;
  selectedDate?: string;
  backupNote?: string;
  [key: string]: any;
}

function CNRActivity(props: CNRActivityProps) {
  const [backups, setBackups] = useState<Backup[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentTab, setCurrentTab] = useState<string>("all");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [totalFilteredRecords, setTotalFilteredRecords] = useState<number>(0);
  
  // Fetch backups whenever pagination, search, or tab changes
  useEffect(() => {
    fetchBackups();
  }, [currentPage, pageSize, searchQuery, currentTab]);
  
  const fetchBackups = async () => {
    try {
      setLoading(true);
      
      // Construct query parameters for server-side filtering and pagination
      const queryParams = new URLSearchParams({
        modelName: 'backup',
        page: currentPage.toString(),
        pageSize: pageSize.toString(),
      });
      
      // Add search query if provided
      if (searchQuery) {
        queryParams.append('searchQuery', searchQuery);
      }
      
      // Add action filter if not "all"
      if (currentTab !== "all") {
        queryParams.append('actionType', currentTab);
      }
      
      const response = await axios.get(`/api/backups?${queryParams.toString()}`);
      const data = response.data;
      
      if (data && Array.isArray(data.backups)) {
        // Log a sample of the backup data structure to help with debugging
        if (data.backups.length > 0) {
          const sample = data.backups[0];
          console.log('Sample backup data structure:', {
            _id: sample._id,
            hasVesselName: !!sample.vesselName,
            hasTimeline: !!sample.timeline,
            hasData: !!sample.data,
            dataKeys: sample.data ? Object.keys(sample.data) : [],
            backupKeys: sample.data?.backup ? Object.keys(sample.data.backup) : []
          });
        }
        
        // Process each backup to ensure vessel names and action types are properly extracted
        const processedBackups = data.backups.map((backup: Backup) => {
          const vesselName = getVesselName(backup);
          const actionType = getActionTypeFromBackup(backup);
          
          return {
            ...backup,
            extractedVesselName: vesselName !== "Unknown Vessel" ? vesselName : undefined,
            // Store the inferred action type for consistent display and filtering
            inferredAction: actionType,
            // If there's no backupNote but we can determine the action, create a generic note
            backupNote: backup.backupNote || 
              (actionType !== "UNKNOWN" ? `${actionType.charAt(0) + actionType.slice(1).toLowerCase()} operation` : undefined)
          };
        });
        
        setBackups(processedBackups);
        setTotalRecords(data.totalRecords || 0);
        setTotalFilteredRecords(data.filteredRecords || 0);
      } else {
        setError("Unexpected response format");
      }
    } catch (err: any) {
      setError(err.message || "Failed to fetch backup data");
      console.error("Error fetching backups:", err);
    } finally {
      setLoading(false);
    }
  };
  
  const getActionTypeFromBackup = (backup: Backup): string => {
    // Try to determine the action type from the backup data
    if (backup.action) {
      return backup.action;
    }
    
    // Look at specific fields that might indicate the action type
    if (typeof backup.data?.reportIndicesToDelete !== 'undefined') {
      return "DELETE";
    } else if (typeof backup.data?.newReport !== 'undefined') {
      return "INSERT";
    } else if (typeof backup.data?.reportIndex !== 'undefined' && typeof backup.data?.fromTimezone !== 'undefined') {
      return "TIMEZONE";
    } else if (typeof backup.data?.reportIndex !== 'undefined' && typeof backup.data?.fromDate !== 'undefined') {
      return "DATETIME";
    } else if (typeof backup.data?.reason !== 'undefined' && typeof backup.data?.newStatus !== 'undefined') {
      return "RESUBMIT";
    }
    
    // Try to infer action type from backupNote if available
    if (backup.backupNote) {
      const note = backup.backupNote.toLowerCase();
      
      // Check for patterns in notes that indicate specific action types
      if (note.includes('inserted') || note.includes('added a new')) {
        return "INSERT";
      } else if (note.includes('deleted') || note.includes('removing') || note.includes('removed')) {
        return "DELETE";
      } else if (note.includes('timezone') || (note.includes('changed') && note.includes('to') && note.includes('from'))) {
        return "TIMEZONE";
      } else if (note.includes('date/time') || note.includes('updated date')) {
        return "DATETIME";
      } else if (note.includes('resubmit') || note.includes('resubmitted') || note.includes('reason')) {
        return "RESUBMIT";
      }
    }
    
    return "UNKNOWN";
  };
  
  const getDateFormatted = (dateString: string) => {
    try {
      return format(new Date(dateString), "MMM dd, yyyy 'at' hh:mm a");
    } catch (e) {
      return dateString;
    }
  };
  
  const getVesselName = (backup: Backup): string => {
    // Check if vesselName is directly available on the backup
    if (backup.vesselName) {
      // console.log(`Using direct vesselName: ${backup.vesselName}`);
      return backup.vesselName;
    }
    
    // Check if it's in the timeline data
    if (backup.timeline && backup.timeline.length > 0) {
      const firstReport = backup.timeline[0];
      if (firstReport.vessel_name) {
        // console.log(`Found vessel name in timeline: ${firstReport.vessel_name}`);
        return firstReport.vessel_name;
      }
    }
    
    // Check if it's in the original data
    if (backup.data && backup.data.newReport && backup.data.newReport.vessel_name) {
      // console.log(`Found vessel name in newReport: ${backup.data.newReport.vessel_name}`);
      return backup.data.newReport.vessel_name;
    }
    
    // Check backup data for vessel name in various potential locations
    if (backup.data) {
      if (backup.data.backup && backup.data.backup.vesselName) {
        // console.log(`Found vessel name in backup.data.backup: ${backup.data.backup.vesselName}`);
        return backup.data.backup.vesselName;
      }
      
      if (backup.data.backup && backup.data.backup.timeline && 
          backup.data.backup.timeline.length > 0 && 
          backup.data.backup.timeline[0].vessel_name) {
        // console.log(`Found vessel name in backup.data.backup.timeline: ${backup.data.backup.timeline[0].vessel_name}`);
        return backup.data.backup.timeline[0].vessel_name;
      }
    }
    
    // Uncomment for debugging
    // console.log('Could not find vessel name for backup', backup._id, backup);
    return "Unknown Vessel";
  };
  
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0); // Scroll to top when changing page
  };
  
  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-center h-64">
          <Spinner />
        </div>
      );
    }
    
    if (error) {
      return (
        <div className="flex items-center justify-center h-64">
          <NonIdealState
            icon="error"
            title="Error fetching data"
            description={error}
            action={<Button icon="refresh" onClick={fetchBackups}>Try Again</Button>}
          />
        </div>
      );
    }
    
    if (backups.length === 0) {
      return (
        <div className="flex items-center justify-center h-64">
          <NonIdealState
            icon="search"
            title="No matching records found"
            description="Try adjusting your search or filter criteria"
          />
        </div>
      );
    }
    
    return (
      <>
        <div className="overflow-x-auto -mx-4 sm:-mx-6 md:mx-0">
          <div className="inline-block min-w-full align-middle px-4 sm:px-6 md:px-0">
            <HTMLTable striped bordered className="w-auto">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-3 px-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Vessel</th>
                  <th className="py-3 px-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                  <th className="py-3 px-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modified By</th>
                  <th className="py-3 px-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Modified Date</th>
                  <th className="py-3 px-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Report Date</th>
                  <th className="py-3 px-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Notes</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {backups.map(backup => (
                  <tr key={backup._id}>
                    <td className="py-2 px-3 whitespace-nowrap">{backup.extractedVesselName || getVesselName(backup)}</td>
                    <td className="py-2 px-3 whitespace-nowrap">
                      <span className={`font-semibold ${getActionTypeColor(backup.inferredAction || getActionTypeFromBackup(backup))}`}>
                        {backup.inferredAction || getActionTypeFromBackup(backup)}
                      </span>
                    </td>
                    <td className="py-2 px-3 whitespace-nowrap">{backup.modifiedBy?.name || backup.modifiedBy?.email || "Unknown User"}</td>
                    <td className="py-2 px-3 whitespace-nowrap">{getDateFormatted(backup.backupDate)}</td>
                    <td className="py-2 px-3 whitespace-nowrap">{backup.selectedDate ? backup.selectedDate.split("T")[0] : "N/A"}{backup?.intlDateLineRecord ? " (IDL Repeat)" : ""}</td>
                    <td className="py-2 px-3">
                      {backup.backupNote ? (
                        <Tooltip content={backup.backupNote} position="bottom" disabled={backup.backupNote.length < 40}>
                          <div className="truncate w-[200px] sm:w-[250px] md:w-[300px] lg:w-[350px]">{backup.backupNote}</div>
                        </Tooltip>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </HTMLTable>
          </div>
        </div>
        
        {totalFilteredRecords > pageSize && (
          <div className="flex justify-center my-4 px-4">
            <div className="flex flex-wrap items-center gap-1 sm:gap-2">
              <Button
                icon="chevron-left"
                disabled={currentPage === 0}
                onClick={() => handlePageChange(currentPage - 1)}
                small={window.innerWidth < 640}
              />
              
              {Array.from({ length: Math.min(5, Math.ceil(totalFilteredRecords / pageSize)) }, (_, i) => {
                // Calculate which page numbers to show
                const pageCount = Math.ceil(totalFilteredRecords / pageSize);
                const totalPagesToShow = Math.min(window.innerWidth < 640 ? 3 : 5, pageCount);
                
                // For small number of pages, show all
                if (pageCount <= totalPagesToShow) {
                  return (
                    <Button
                      key={i}
                      intent={currentPage === i ? "primary" : "none"}
                      onClick={() => handlePageChange(i)}
                      small={window.innerWidth < 640}
                    >
                      {i + 1}
                    </Button>
                  );
                }
                
                // For many pages, show a window around current page
                let pagesToShow = [];
                if (window.innerWidth < 640) {
                  // On mobile show fewer page buttons
                  if (currentPage < 1) {
                    pagesToShow = [0, 1, 2];
                  } else if (currentPage > pageCount - 2) {
                    pagesToShow = [pageCount-3, pageCount-2, pageCount-1];
                  } else {
                    pagesToShow = [currentPage-1, currentPage, currentPage+1];
                  }
                } else {
                  // Desktop layout
                  if (currentPage < 2) {
                    pagesToShow = [0, 1, 2, 3, 4];
                  } else if (currentPage > pageCount - 3) {
                    pagesToShow = [pageCount-5, pageCount-4, pageCount-3, pageCount-2, pageCount-1];
                  } else {
                    pagesToShow = [currentPage-2, currentPage-1, currentPage, currentPage+1, currentPage+2];
                  }
                }
                
                // Only return valid indexes
                if (i < totalPagesToShow) {
                  const pageNum = pagesToShow[i];
                  return (
                    <Button
                      key={pageNum}
                      intent={currentPage === pageNum ? "primary" : "none"}
                      onClick={() => handlePageChange(pageNum)}
                      small={window.innerWidth < 640}
                    >
                      {pageNum + 1}
                    </Button>
                  );
                }
                return null;
              })}
              
              <Button
                icon="chevron-right"
                disabled={currentPage >= Math.ceil(totalFilteredRecords / pageSize) - 1}
                onClick={() => handlePageChange(currentPage + 1)}
                small={window.innerWidth < 640}
              />
            </div>
          </div>
        )}
      </>
    );
  };
  
  const getActionTypeColor = (actionType: string): string => {
    switch(actionType.toUpperCase()) {
      case "INSERT": return "text-green-600";
      case "DELETE": return "text-red-600";
      case "TIMEZONE": return "text-blue-600";
      case "DATETIME": return "text-purple-600";
      case "RESUBMIT": return "text-orange-600";
      default: return "text-gray-600";
    }
  };
  
  return (
    <Tool>
      <Helmet>
        <title>CNR Activity Monitor | Wayship</title>
      </Helmet>
      
      <div className="p-4 sm:p-6 md:p-8 max-w-[1600px] mx-auto">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-4 sm:mb-6">
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">CNR Activity Monitor</h1>
            <p className="text-sm sm:text-base text-gray-600">
              Track all changes made to CNR records across vessels
            </p>
          </div>
          
          <div className="mt-4 md:mt-0 w-full md:w-auto">
            <Button 
              icon="refresh" 
              intent="primary" 
              onClick={fetchBackups}
              disabled={loading}
              className="w-full md:w-auto"
            >
              Refresh Data
            </Button>
          </div>
        </div>
        
        <div className="mb-4 sm:mb-6">
          <InputGroup
            large={window.innerWidth >= 640}
            leftIcon="search"
            placeholder="Search by vessel name, user, action type, or notes..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full"
          />
        </div>
        
        <div className="overflow-x-auto -mx-4 sm:-mx-6 md:mx-0 mb-4 sm:mb-6">
          <div className="inline-block min-w-full px-4 sm:px-6 md:px-0">
            <Tabs
              id="actionTypeTabs"
              selectedTabId={currentTab}
              onChange={(newTabId) => {
                setCurrentTab(newTabId.toString());
                setCurrentPage(0); // Reset to first page when changing tabs
              }}
              className="mb-0"
            >
              <Tab id="all" title="All Actions" />
              <Tab id="insert" title="Insert" />
              <Tab id="delete" title="Delete" />
              <Tab id="timezone" title="Timezone" />
              <Tab id="datetime" title="Date/Time" />
              <Tab id="resubmit" title="Resubmit" />
              <Tabs.Expander />
            </Tabs>
          </div>
        </div>
        
        <div className="bg-white rounded-lg shadow overflow-hidden">
          {renderContent()}
        </div>
        
        <div className="mt-4 text-xs sm:text-sm text-gray-500 flex flex-col sm:flex-row justify-between items-start sm:items-center">
          <div className="mb-2 sm:mb-0">
            Showing {backups.length} of {totalFilteredRecords} records 
            {totalFilteredRecords !== totalRecords && ` (filtered from ${totalRecords} total)`}
          </div>
          
          {totalFilteredRecords > 0 && (
            <div>
              Page {currentPage + 1} of {Math.max(1, Math.ceil(totalFilteredRecords / pageSize))}
            </div>
          )}
        </div>
      </div>
    </Tool>
  );
}

function mapStateToProps() {
  return {};
}

const reduxConnector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(CNRActivity); 