import { IListObjectStore } from "redux/_common/type";
import { IUser } from "../user/@types";
import feedbackConstants, { FILTER_SECTIONS } from "./constants";
import {
	ETicketTabType,
	ETypeOfFilter,
	IFilterState,
	IVesselUser,
	TFeedback,
	TTaskTemplate,
} from "./@types";
import { Range, createStaticRanges } from "react-date-range";
import {
	addDays,
	addMonths,
	endOfDay,
	endOfMonth,
	endOfQuarter,
	endOfWeek,
	startOfDay,
	startOfMonth,
	startOfQuarter,
	startOfWeek,
} from "date-fns";
import moment from "moment";

export const trimStringWithEllipsis = (str: string, count: number) => {
	if (str.length < count) return str;
	if (str.length >= count) return str.slice(0, count) + "...";
};

export const getPriorityClass = (priority: string) => {
	let text = priority + " priority";
	let className = "";
	switch (priority) {
		case "High":
			className =
				"inline-flex items-center px-1 py-1 text-xs font-medium text-red-700 bg-red-100 rounded-md";
			break;
		case "Medium":
			className =
				"inline-flex items-center px-1 py-1 text-xs font-medium text-yellow-800 bg-yellow-100 border-yellow-800 rounded-md";
			break;
		case "Low":
			className =
				"inline-flex items-center px-1 py-1 text-xs font-medium text-gray-600 bg-gray-100 rounded-md";
			break;
		default:
			className = "";
	}
	return { text, className };
};

export const feedbackStatusColor = {
	Open: "text-red-700",
	"In progress": "text-yellow-800",
	Closed: "text-green-600",
	Reopened: "text-red-700",
};

export const getStatusClass = (status: string) => {
	let text = status;
	let className = "";
	switch (status) {
		case "Open":
			className =
				"inline-flex items-center px-1 py-1 text-xs font-medium text-red-700 bg-red-100 rounded-md";
			break;
		case "In Progress":
			className =
				"inline-flex items-center px-1 py-1 text-xs font-medium text-yellow-800 bg-yellow-100 rounded-md";
			break;
		case "Closed":
			className =
				"inline-flex items-center px-1 py-1 text-xs font-medium text-green-600 bg-gray-100 rounded-md";
			break;
		case "Reopened":
			className =
				"inline-flex items-center px-1 py-1 text-xs font-medium text-red-700 bg-red-100 rounded-md";
			break;
		default:
			className = "";
	}
	return { text, className };
};

export const priorityColorMapper = (priority?: string) => {
	const colorMapper = {
		High: "#FF2B2B",
		Medium: "#20B938",
		Low: "#84BA12",
	};
	return priority && priority in colorMapper
		? colorMapper[priority as keyof typeof colorMapper]
		: colorMapper["Low"];
};

export const getUserOptions = (users: IListObjectStore<IUser>) => {
	return users?.ids
		?.filter(
			(userId) =>
				users.byIds[userId].roleCategory.toLowerCase() ===
					"developer" ||
				users.byIds[userId].roleCategory.toLowerCase() === "admin"
		)
		.map((userId) => users.byIds[userId])
		.sort((a, b) => a.name.localeCompare(b.name));
};

export const optionStyles = {
	base: "hover:cursor-pointer px-3 py-2 rounded",
	focus: "bg-gray-100 active:bg-gray-200 cursor-pointer",
	selected: "after:content-['✔'] after:ml-2 after:text-green-500 text-gray-500",
};
export const dropdownIndicatorStyles =
	"p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black";
export const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg";
export const clearIndicatorStyles =
	"text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800 cursor-pointer";
export const placeholderStyles = "text-gray-500 pl-1 py-0.5";

export const multiValueStyles =
	"bg-gray-100 rounded items-center py-0.5 pl-2 pr-1 gap-1.5 m-0.5";
export const multiValueLabelStyles = "leading-6 py-0.5";
export const multiValueRemoveStyles =
	"border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md";

export const controlStyles = {
	base: "border rounded-sm  bg-white hover:cursor-pointer",
	focus: "border-primary-600 ring-1 ring-primary-500",
	nonFocus: "border-gray-300 hover:border-gray-400",
	disabled: "bg-gray-200 cursor-not-allowed",
};
export const selectInputStyles = "pl-1 py-0.5";

export const getVesselUsers = (crews: IListObjectStore<IVesselUser>) => {
	return crews.ids.map((crewId) => ({
		label: crews.byIds[crewId].name,
		value: crewId,
	}));
};

export const getSegregatedTasks = (tasks: IListObjectStore<TTaskTemplate>) => {
	const segregatedTasks = {} as { [key: string]: string[] };
	tasks?.ids?.forEach((taskId) => {
		const task = tasks.byIds[taskId];
		const taskType = task.type;
		if (!(taskType in segregatedTasks)) {
			segregatedTasks[taskType] = [];
		}
		segregatedTasks[taskType].push(task.title);
	});
	segregatedTasks[feedbackConstants.CHECKLIST] =
		segregatedTasks[feedbackConstants.PERMIT];
	delete segregatedTasks.PERMIT;
	segregatedTasks[feedbackConstants.MARPOL] = [
		"ORB1",
		"ORB2",
		"Garbage Record Part1",
		"Ballast Water Record",
		"Annex VI",
	];
	segregatedTasks[feedbackConstants.OTHERS] = [];
	segregatedTasks[feedbackConstants.SIGNAL_REPORT] = [
		"Comprehensive Noon Report",
	];
	return segregatedTasks;
};

export function isFeedbackEmpty(userFeedback: TFeedback) {
	const { author, title, section, feedback, subsection, FE, BE } = userFeedback;
	const isEmpty =
		author === "" ||
		title === "" ||
		section === "" ||
		feedback === "" ||
		FE === "" ||
		BE === "";
	if (section === feedbackConstants.OTHERS) {
		return isEmpty;
	} else {
		return isEmpty || subsection === "";
	}
}

export const filterSections = [
	FILTER_SECTIONS.SECTION,
	FILTER_SECTIONS.ASSIGNED_TO,
	FILTER_SECTIONS.STATUS,
	FILTER_SECTIONS.PRIORITY,
	FILTER_SECTIONS.UNREAD,
];

export const getPossibleSections = (tasks: IListObjectStore<TTaskTemplate>) => {
	const segregatedTasks = getSegregatedTasks(tasks);

	const possibleSections = Object.keys(segregatedTasks).map((section) => ({
		label: section,
		value: section,
	}));
	return possibleSections;
};

export const getPossibleSubSections = (
	tasks: IListObjectStore<TTaskTemplate>,
	selectedSection: string = ""
) => {
	const segregatedTasks = getSegregatedTasks(tasks);

	const possibleSubSections =
		selectedSection && selectedSection in segregatedTasks
			? segregatedTasks[selectedSection]?.map((subSection) => ({
					label: subSection,
					value: subSection,
			  }))
			: [];
	return possibleSubSections;
};

const validStatus = ["Open", "In Progress", "Closed", "Reopened"];
const validPriorities = ["High", "Medium", "Low"];

export const priorityOptions = validPriorities.map((option) => ({
	label: option,
	value: option,
}));

export const statusOptions = validStatus.map((option) => ({
	label: option,
	value: option,
}));

export const checkIfFiltersPresent = (
	feedbackFilters: Pick<IFilterState, Exclude<keyof IFilterState, "vesselId">>
) => {
	"vesselId" in feedbackFilters && delete feedbackFilters.vesselId;
	const validFilterState = {} as Partial<typeof feedbackFilters>;
	Object.keys(feedbackFilters)
		.filter((key) => typeof (feedbackFilters as any)[key] !== "undefined")
		.forEach((key) => {
			// @ts-ignore
			validFilterState[key] = feedbackFilters[key];
		});

	const areFiltersPresent = !!Object.keys(validFilterState)?.length;
	return areFiltersPresent;
};

export const defineds = {
	startOfWeek: startOfWeek(new Date()),
	endOfWeek: endOfWeek(new Date()),
	startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
	endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
	startOfToday: startOfDay(new Date()),
	endOfToday: endOfDay(new Date()),
	startOfYesterday: startOfDay(addDays(new Date(), -1)),
	endOfYesterday: endOfDay(addDays(new Date(), -1)),
	startOfMonth: startOfMonth(new Date()),
	endOfMonth: endOfMonth(new Date()),
	startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
	endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
	startOfQuarter: startOfQuarter(addMonths(new Date(), -3)),
	endOfQuarter: endOfQuarter(addMonths(new Date(), -3)),
	past30days: moment().subtract(1, "month").toDate(),
	past7days: moment().subtract(6, "days").toDate(),
	past15days: moment().subtract(14, "days").toDate(),
};

export const defaultStaticRanges = createStaticRanges([
	{
		label: "All time",
		range: () => ({
			startDate: undefined,
			endDate: undefined,
		}),
		isSelected(range: Range) {
			return (
				typeof range.startDate === "undefined" &&
				typeof range.endDate === "undefined"
			);
		},
		hasCustomRendering: true,
	},
	{
		label: "Today",
		range: () => ({
			startDate: defineds.startOfToday,
			endDate: defineds.endOfToday,
		}),
		isSelected(range: Range) {
			return (
				range.startDate === defineds.startOfToday &&
				range.endDate === defineds.endOfToday
			);
		},
		hasCustomRendering: true,
	},
	{
		label: "Yesterday",
		range: () => ({
			startDate: defineds.startOfYesterday,
			endDate: defineds.endOfYesterday,
		}),
		isSelected(range) {
			return (
				range.startDate === defineds.startOfYesterday &&
				range.endDate === defineds.endOfYesterday
			);
		},
		hasCustomRendering: true,
	},

	{
		label: "This Week",
		range: () => ({
			startDate: defineds.startOfWeek,
			endDate: defineds.endOfWeek,
		}),
		isSelected(range) {
			return (
				range.startDate === defineds.startOfWeek &&
				range.endDate === defineds.endOfWeek
			);
		},
		hasCustomRendering: true,
	},
	{
		label: "Past 7 days",
		range: () => ({
			startDate: defineds.past7days,
			endDate: defineds.endOfToday,
		}),
		isSelected(range) {
			return (
				range.startDate === defineds.past7days &&
				range.endDate === defineds.endOfToday
			);
		},
		hasCustomRendering: true,
	},
	{
		label: "Last Week",
		range: () => ({
			startDate: defineds.startOfLastWeek,
			endDate: defineds.endOfLastWeek,
		}),
		isSelected(range) {
			return (
				range.startDate === defineds.startOfLastWeek &&
				range.endDate === defineds.endOfLastWeek
			);
		},
		hasCustomRendering: true,
	},
	{
		label: "This Month",
		range: () => ({
			startDate: defineds.startOfMonth,
			endDate: defineds.endOfMonth,
		}),
		isSelected(range) {
			return (
				range.startDate === defineds.startOfMonth &&
				range.endDate === defineds.endOfMonth
			);
		},
		hasCustomRendering: true,
	},
	{
		label: "Past 30 days",
		range: () => ({
			startDate: defineds.past30days,
			endDate: defineds.endOfToday,
		}),
		isSelected(range) {
			return (
				range.startDate === defineds.past30days &&
				range.endDate === defineds.endOfToday
			);
		},
		hasCustomRendering: true,
	},
	{
		label: "Last Month",
		range: () => ({
			startDate: defineds.startOfLastMonth,
			endDate: defineds.endOfLastMonth,
		}),
		isSelected(range) {
			return (
				range.startDate === defineds.startOfLastMonth &&
				range.endDate === defineds.endOfLastMonth
			);
		},
		hasCustomRendering: true,
	},
]);

export function getCreatedAt(range: Range, selectedLabel?: string) {
	const { startDate, endDate } = range;

	if (
		selectedLabel !== "Today" &&
		selectedLabel !== "Yesterday" &&
		moment(startDate).format("YYYY-MM-DD") !==
			moment(endDate).format("YYYY-MM-DD")
	) {
		return startDate && endDate
			? {
					$gte: moment(startDate).format("YYYY-MM-DD") + "T00:00:00:000Z",
					$lte: moment(endDate).format("YYYY-MM-DD") + "T00:00:00:000Z",
			  }
			: undefined;
	} else {
		return startDate && endDate
			? {
					$gte: moment(startDate).format("YYYY-MM-DD") + "T00:00:00:000Z",
					$lte: moment(endDate).format("YYYY-MM-DD") + "T23:59:59.999Z",
			  }
			: undefined;
	}
}

export const filters = (
	range: Range,
	tab: ETicketTabType,
	assignedTo: string,
	selectedDateLabel?: string
) => {
	return [
		{
			stats: false,
			typeOfFilter: ETypeOfFilter.HIGH_PRIORITY,
			assignedTo: tab === ETicketTabType.MY_TICKETS ? assignedTo : undefined,
			selector: {
				priority: "High",
				status: {
					$ne: "Closed",
				},
				createdAt: getCreatedAt(range, selectedDateLabel),
			},
		},
		{
			stats: false,
			typeOfFilter: ETypeOfFilter.WAITING_FOR_REPLY,
			assignedTo: tab === ETicketTabType.MY_TICKETS ? assignedTo : undefined,
			selector: {
				user_new_message: true,
				status: {
					$ne: "Closed",
				},
				createdAt: getCreatedAt(range, selectedDateLabel),
			},
		},
		{
			stats: false,
			typeOfFilter: ETypeOfFilter.DUE_THIS_WEEK,
			assignedTo: tab === ETicketTabType.MY_TICKETS ? assignedTo : undefined,
			selector: {
				dueDate: {
					$gte:
						moment().subtract(7, "days").format("YYYY-MM-DD") +
						"T00:00:00:000Z",
					$lte: moment().format("YYYY-MM-DD") + "T00:00:00:000Z",
				},
				status: {
					$ne: "Closed",
				},
				createdAt: getCreatedAt(range, selectedDateLabel),
			},
		},
		{
			stats: false,
			typeOfFilter: ETypeOfFilter.TICKETS_OVERDUE,
			assignedTo: tab === ETicketTabType.MY_TICKETS ? assignedTo : undefined,
			selector: {
				dueDate: {
					$lte: moment().format("YYYY-MM-DD") + "T00:00:00:000Z",
				},
				status: {
					$ne: "Closed",
				},
				createdAt: getCreatedAt(range, selectedDateLabel),
			},
		},
		{
			stats: false,
			typeOfFilter: ETypeOfFilter.TICKETS_TO_BE_ASSIGNED,
			selector: {
				status: "Open",
				createdAt: getCreatedAt(range, selectedDateLabel),
			},
		},
	];
};

export function getFormattedDate(range: Range) {
	const label = defaultStaticRanges.find((staticRange) =>
		staticRange.isSelected(range)
	)?.label;
	if (label) {
		return label;
	}
	return `${moment(range.startDate, "DD MMM, YYYY").format(
		"DD MMM, YYYY"
	)} - ${moment(range.endDate, "DD MMM, YYYY").format("DD MMM, YYYY")}`;
}
