const LOAD_TASK = "@ext/LOADTASK";
const SET_FORM = "@ext/SET_FORM";
const FORM_FIELD = "@ext/FORM_FIELD";
const FORM_FIELD_PATCH = "@ext/FORM_FIELD_PATCH";
const FORM_UPDATE = "@ext/FORM_UPDATE";
const USER_AUTHENTICATED = "@ext/USER_AUTHENTICATED";
const FORM_SUBMIT = "@ext/FORM_SUBMIT"
const NAVNUC_SUBMIT = "@ext/NAVNUC_SUBMIT"
const NAVNUC_ATTACHMENT_UPLOAD = "@ext/NAVNUC_ATTACHMENT_UPLOAD"
const extActionTypes = {
	LOAD_TASK,
	SET_FORM,
	FORM_FIELD,
	USER_AUTHENTICATED,
	FORM_FIELD_PATCH,
	FORM_UPDATE,
	FORM_SUBMIT,
	NAVNUC_SUBMIT,
	NAVNUC_ATTACHMENT_UPLOAD
};

export default extActionTypes;
