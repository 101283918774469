import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import reportType from './reportType.jpg'
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { IStore } from "redux/store";
import WayshipNumber from "components/app/vessel/onboard/ranges/RangeManagementForm/WayshipNumber";
import WayshipDate from "components/_common/WayshipDate";
import WayshipTextArea from "components/app/vessel/onboard/ranges/RangeManagementForm/WayshipTextArea";
import InputWrapper from "components/_common/InputWrapper";
import MultimediaPreviewer from "components/app/feedback/view/_elements/MultimediaPreviewer";
import { RouteComponentProps } from "react-router-dom";
import WayshipText from "components/_common/WayshipText";
import WayshipRadioGroup from "components/_common/WayshipRadioGroup";
import { extActions } from "redux/app/ext";
import { jobSelectors } from "redux/_core/job";
import extActionTypes from "redux/app/ext/action-types";

interface INavnucForm {
    id: string;
    question: string;
    required: boolean;
    type: "text" | "textarea" | "date" | "number" | "radio" | "upload";
    options: string[];
    acceptedfileTypes?: string;
    imageUrl?: string;
}

const form: INavnucForm[] = [
	{
        id: "vesselName",
        question: "Vessel's name",
        required: true,
        type: "text",
        options: [],
    },
    {
        id: "type_of_report",
        question: "What are you reporting?",
        required: true,
        type: "radio",
        options: [
            "Unsafe Condition",
            "Unsafe Act",
            "Near Miss",
        ],
        imageUrl: reportType,
    },
    {
        id: "rank_of_person_related_to_act",
        question: "Rank of the person who is related to the Nearmiss / Unsafe act",
        required: false,
        type: "radio",
        options: [
            "Master",
            "Chief Officer",
            "Second Officer",
            "Third Officer",
        ],
    },
    {
        id: "name_of_reporter",
        question: "Your name / staff code",
        required: false,
        type: "text",
        options: [],
    },
    {
        id: "rank_of_reporter",
        question: "What is your rank?",
        required: false,
        type: "radio",
        options: [
            "Master",
            "Chief Officer",
            "Second Officer",
            "Third Officer",
        ],
    },
    {
        id: "date_of_occurrence",
        question: "Date of Nearmiss / Unsafe act",
        required: true,
        type: "date",
        options: [],
    },
    {
        id: "type_of_activity",
        question: "Type of Activity",
        required: true,
        type: "radio",
        options: [
            "At anchor",
            "Maneuvering",
            "Open sea navigation",
            "Restricted Area Navigation",
        ],
    },
    {
        id: "possible_consequences",
        question: "What could have been the consequence if the Near Miss had turned into an incident?",
        required: true,
        type: "radio",
        options: [
            "Allision",
            "Collision",
            "Grounding",
        ],
    },
    {
        id: "vessel_draft",
        question: "What was vessel's draft?",
        required: true,
        type: "number",
        options: [],
    },
    {
        id: "depth_of_water",
        question: "What was the depth of water?",
        required: true,
        type: "number",
        options: [],
    },
    {
        id: "name_of_port_allision_point",
        question: "Name of the Port / Allision point",
        required: true,
        type: "text",
        options: [],
    },
    {
        id: "name_of_other_vessel",
        question: "Name of the other vessel",
        required: true,
        type: "text",
        options: [],
    },
    {
        id: "description",
        question: "Describe what happened.",
        required: true,
        type: "textarea",
        options: [],
    },
    {
        id: "evidence",
        question: "Submit supporting evidence",
        required: true,
        type: "upload",
        options: [],
        // acceptedfileTypes = '.pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.gif,.bmp',
    },
]
interface IRendererProps {
    config?: {
        label: string;
        type:
        | 'text'
        | 'textarea'
        | 'date'
        | 'number'
        | 'radio'
        | 'upload';
        required: boolean;
        dataKey: string;
        options?: any;
        isDisabled?: boolean;
        acceptedfileTypes?: string
    };
    formData: Record<string, any> | undefined;
    setFormData: (formData: Record<string, any>) => void
}

const Renderer: FC<IRendererProps> = (props) => {
    const { config, formData, setFormData } = props;
    if (!config) return null; 

    const { type, dataKey, label, required, isDisabled, options, acceptedfileTypes } = config;
    const value = formData?.responses?.find((item: any) => item.id === dataKey)?.value;
    const error = formData?.errors?.[dataKey];

    const checkError = () => {
        if ((required && !value) || (required && type === "upload" && value.length === 0)) {
            setFormData({
                ...formData,
                errors: {
                    ...formData?.errors,
                    [dataKey]: { isValid: false, message: "Please fill in required field" }
                }
            });
        } else if (type === "number" && value < 0) {
            setFormData({
                ...formData,
                errors: {
                    ...formData?.errors,
                    [dataKey]: { isValid: false, message: "Value cannot be negative" }
                }
            });
        } else if (type === "text" && value.length > 200) {
            setFormData({
                ...formData,
                errors: {
                    ...formData?.errors,
                    [dataKey]: { isValid: false, message: `Exceeding character limit ${value.length}/200` }
                }
            });
        } else if (type === "textarea" && value.length > 1000) {
            setFormData({
                ...formData,
                errors: {
                    ...formData?.errors,
                    [dataKey]: { isValid: false, message: `Exceeding character limit ${value.length}/1000` }
                }
            });
        }
    };
    const resetError = () => {
        if (formData?.errors?.[dataKey]) {
            delete formData?.errors?.[dataKey];
        }
    };

    const handleChange = (v: any, dataKey: string, type: string) => {
        if (error) resetError();

        const existingResponses = formData?.responses || [];
        const responseIndex = existingResponses.findIndex((item: any) => item.id === dataKey);

        let updatedResponses = [...existingResponses];

        if (responseIndex !== -1) {
            updatedResponses[responseIndex] = { ...updatedResponses[responseIndex], value: v };
        } else {
            updatedResponses.push({ id: dataKey, value: v, type: type});
        }

        setFormData({ ...formData, responses: updatedResponses });
    };
    switch (type) {
        case 'text':
            return (
                <WayshipText
                    title={label}
                    required={required}
                    value={value}
                    dataKey={dataKey}
                    isSubmit={isDisabled}
                    error={error}
                    onChangeHandler={(v, dataKey) => {
                        handleChange(v, dataKey, type);
                    }}
                    onBlurHandler={() => {
                        checkError();
                    }}
                />
            );
        case 'number':
            return (
                <WayshipNumber
                    title={label}
                    required={required}
                    value={value}
                    dataKey={dataKey}
                    isSubmit={isDisabled}
                    error={error}
                    isWholeNumber={false}
                    step={1}
                    onChangeHandler={(v, dataKey) => {
                        handleChange(v, dataKey, type);
                    }}
                    onBlurHandler={() => {
                        checkError();
                    }}
                />
            );
        case 'date':
            return (
                <WayshipDate
                    title={label}
                    required={required}
                    value={
                        value ? moment(value).format('YYYY-MM-DD') : undefined
                    }
                    type="date"
                    dataKey={dataKey}
                    isSubmit={isDisabled}
                    error={error}
                    onChangeHandler={(v, dataKey) => {
                        // If date value is empty, set dataKey to null to avoid backend errors with empty date strings.
                        if (!v) handleChange(null, dataKey, type);
                        const formattedDate = moment(v).toISOString();
                        handleChange(formattedDate, dataKey, type);
                    }}
                    onBlurHandler={() => {
                        checkError();
                    }}
                />
            );
        case 'textarea':
            return (
                <WayshipTextArea
                    title={label}
                    required={required}
                    className="w-full"
                    value={value}
                    dataKey={dataKey}
                    id={dataKey}
                    error={error}
                    onChange={(v, dataKey) => {
                        handleChange(v, dataKey, type);
                    }}
                    isSubmit={isDisabled}
                    onBlurHandler={() => {
                        checkError();
                    }}
                />
            );
        case 'radio':
            return (
                <WayshipRadioGroup
                    dataKey={dataKey}
                    title={label}
                    required={required}
                    options={options}
                    value={value}
                    isBorderRemoved={true}
                    isSubmit={isDisabled}
                    error={error}
                    onChangeHandler={(v: string, dataKey: string) => {
                        handleChange(v, dataKey, type);
                    }}
                />
            )
        case 'upload':
            return (
                <InputWrapper
                    className="w-full"
                    inputValue={value}
                    hasError={
                        error && error.hasOwnProperty('isValid')
                            ? !error.isValid
                            : false
                    }
                >
                    <label className='flex justify-between w-full items-center cursor-pointer ws-input__label' htmlFor={dataKey}>
                        <div>
                            {label} {required && <span className='text-red-400'>*</span>}
                        </div>
                        <span className='bp3-icon bp3-icon-paperclip text-gray-600 bg-blue-100 p-4 rounded-lg' />
                    </label>
                    <div className='flex flex-col items-start gap-4 w-full'>
                        <input
                            title='Upload'
                            type="file"
                            id={dataKey}
                            required={required ?? false}
                            className="w-full m-0 border-0 shadow-none ws-input hidden"
                            disabled={isDisabled}
                            accept={acceptedfileTypes}
                            multiple
                            onChange={(event) => {
                                if (!event.target.files) return;
                                const selectedFiles = Array.from(event.target.files);

                                const fileSize = 1000000;
                                const isOversized = selectedFiles.some((file) => {
									if (file.size > fileSize) {
										alert(`${file.name} is too large! Limit: ${fileSize / (1000 * 1000)}MB`);
									}
									return file.size > fileSize;
								});
								if (!isOversized) handleChange(selectedFiles, dataKey, type);
                            }}
                            onBlur={() => {
                                if (dataKey) {
                                    checkError();
                                }
                            }}
                        />

                        {value?.length > 0 && (
                            <div className="p-4 pt-2 bg-blue-100 rounded ws-input">
                                {value?.map((file: File, index: number) => (
                                    <MultimediaPreviewer
                                        key={index}
                                        content_type={file.type}
                                        title={file.name}
                                        url={URL.createObjectURL(file)}
                                        className='flex justify-center mb-2'
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                    <span className="ws-input__error">{error?.message || ''}</span>
                </InputWrapper>
            )
        default:
            return null;
    }
};

type OwnProps = {};
type NavnucFormProps = OwnProps & PropsFromRedux & RouteComponentProps;
function NavnucForm(props: NavnucFormProps) {
    const { jobToAttachmentUpload, jobToSubmitForm } = props;
    const [formData, setFormData] = useState<Record<string, any>>({ errors: [], responses: [] });
    const checkErrors = () => {
        const requiredKeys = form.filter(item => item.required).map(item => ({ id: item.id, type: item.type }));
        const newErrors: Record<string, { isValid: boolean; message: string }> = {};
        requiredKeys.forEach((item) => {
            const value = formData?.responses?.find(((response: any) => response.id === item.id));
            if (!value || (item.type === "upload" && value.length === 0)) {
                newErrors[item.id] = { isValid: false, message: "Please fill in required field" };
            }
        });
        setFormData({
            ...formData,
            errors: {
                ...formData?.errors,
                ...newErrors
            }
        });

        if (!newErrors.length) delete formData?.errors
        return newErrors;
    };
    const resetForm = () => {
        setFormData({ errors: [], responses: [] });
    }
    useEffect(() => {
        if (jobToSubmitForm.isSuccess) {
            resetForm();
        }
    }, [jobToSubmitForm])
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const errors = Object.keys(checkErrors());

        if (errors.length) {
            return document.getElementById(errors[0])?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        props.submitForm(formData)
    };

    return (
        <div className="bg-white py-8 px-6 md:px-12 mx-2 my-4 md:my-8 md:mx-16 rounded-lg shadow">
            <div className="flex justify-between">
                <div className="text-gray-600 mb-4">
                    <h1 className="text-2xl text-gray-900">Submit NAVNUC Anonymously</h1>
                    <p>
                        Please use this form to report any NAVNUC (Navigation Nearmiss, Unsafe-Act or Condition).
                    </p>
                    <p>Your identity will remain confidential.</p>
                </div>
            </div>
            <form onSubmit={handleSubmit} className="flex flex-col gap-2" noValidate>
                {form.map((item: INavnucForm, index: number) => {
                    return (
                        <div
                            key={item.id}
                            className={classNames(
                                'flex flex-col min-w-[150px] items-center',
                                {
                                    'md:w-1/2':
                                        item.type === 'date' ||
                                        item.type === 'number'
                                }
                            )}
                        >
                            <Renderer
                                config={{
                                    label: `${index + 1}) ${item.question}`,
                                    type: item.type,
                                    options: item.options,
                                    required: item.required,
                                    dataKey: item.id,
                                    acceptedfileTypes: item?.acceptedfileTypes
                                }}
                                key={item.id}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            {item.imageUrl && <img src={item.imageUrl} alt={item.question} width={800}/>}
                        </div>
                    )
                })}
                <button
                    className="ws-button w-full md:w-1/4 my-8 self-end min-w-[150px]"
                    disabled={jobToAttachmentUpload.isLoading || jobToSubmitForm.isLoading}
                >
                    {jobToAttachmentUpload.isLoading || jobToSubmitForm.isLoading ? "Submitting..." : "Submit"}
                </button>
            </form>
        </div>
    )
	
};
function mapStateToProps(store: IStore, ownProps: OwnProps) {
	const extStore: any = store.app.extStore;
    const jobToSubmitForm = jobSelectors.getJobStatus(extStore, extActionTypes.NAVNUC_SUBMIT);
    const jobToAttachmentUpload = jobSelectors.getJobStatus(extStore, extActionTypes.NAVNUC_ATTACHMENT_UPLOAD);

	return {
        jobToAttachmentUpload,
		extStore,
		jobToSubmitForm,
	};
}
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps) {
    return {
        submitForm(formData: Record<string, any>) {
            return dispatch(
                extActions.navnucSubmit(formData)
            );
        },
    };
}
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(NavnucForm);
