import { useState, useEffect } from "react";
import axios from "axios";
import commonUtils from "redux/_common/utils";

export function useVesselInsights(noonReportData: any) {
	const [insights, setInsights] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		async function fetchInsights() {
			if (!noonReportData) return;
			if (noonReportData.length === 0) return;
			try {
				setLoading(true);
				const response = await axios.post(
					`${commonUtils.getAPIBaseUrl()}/api/vesselinsights`,
					{ data: noonReportData }
				);
				const insights = response.data.insights;
				setInsights(insights);
			} catch (err) {
				setError("Failed to generate insights");
			} finally {
				setLoading(false);
			}
		}

		fetchInsights();
	}, [noonReportData]);

	return { insights, loading, error };
}
