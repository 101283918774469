import { IJobHOCState } from "redux/_core/job/types";

export interface IVessel {
	_id: string;
	callSign: string;
	imoNumber: string;
	vesselName: string;
	vesselPrefix: string;
	vesselSubType: string;
	vesselType: string;
}

export enum EORSAggregateRecord {
	INCINERATOR = "INCINERATOR",
	SHORE_DISPOSAL = "SHORE DISPOSAL",
	WATER_EVAPORATION = "WATER EVAPORATION",
	SLOP_TANK = "SLOP TANK",
	BOILER = "BOILER",
}

export interface IORSMonthWiseData {
	month: number;
	totalQuantityDisposed: number;
}
export interface IORSAggregateRecord {
	disposalMethod: EORSAggregateRecord;
	tenantId: string;
	monthWiseData: IORSMonthWiseData[];
}

export interface IORSVesselSpecificDateRange {
	year: number;
	month: number;
}

export interface IORSVesselSpecificCategory {
	disposalMethod: EORSAggregateRecord;
	totalQuantityDisposed: number;
}

export interface IORBVesselSpecificInfo extends IRawGarbageVesselInfo {
	vesselType: string;
}

export interface IORSVesselSpecificAggregateRecord {
	dateRange: IORSVesselSpecificDateRange;
	lastAggregatedAt: string;
	orsCategories: IORSVesselSpecificCategory[];
	tenantId: string;
	vessel: IORBVesselSpecificInfo;
}

export interface ICargoSlopMonthWiseRecord {
	month: number;
	totalQuantityDischarged: number;
}

export interface ICargoSlopAggregateRecord {
	tenantId: string;
	monthWiseData: ICargoSlopMonthWiseRecord[];
}

export interface ICargoSlopVesselSpecificAggregateRecord {
	tenantId: string;
	monthWiseData: ICargoSlopMonthWiseRecord[];
	vessel: IVessel;
	lastAggregatedAt: string;
	cumulativeQuantityDischarged: number;
}

export interface IOilSpillsInWaterMonthWiseRecord {
	month: number;
	totalQuantityDischarged: number;
}

export interface IOilSpillsInWaterAggregateRecord {
	tenantId: string;
	monthWiseData: IOilSpillsInWaterMonthWiseRecord[];
}

export interface IOilSpillsInWaterVesselSpecificAggregateRecord {
	tenantId: string;
	monthWiseData: IOilSpillsInWaterMonthWiseRecord[];
	vessel: IVessel;
	lastAggregatedAt: string;
	cumulativeQuantityDischarged: number;
}

export interface IComprehensiveNoonReportRecord {
	_id: string,
	name: string,
	vessel: string,
	vesselId: string,
	doc_status: string,
	daysSinceLastReport: number,
	daysSinceVerificationPending: number,
	reportDate: string,
	firstSubmittedAt: string,
	voyage: string,
	status: string,
	condition: string,
	elapsedHours: string,
	distance: string,
	sog: string,
	stw: string,
	rpm: string,
	beaufort: string,
	vlsfoRob: string,
	mgoRob: string,
	ifoRob: string,
	ulsfoRob: string,
	lngRob: string,
	ethaneRob: string,
	lpgbRob: string,
	lpgpRob: string
	biolngRob: string,
	methanolRob: string,
	bioBlendRob: string
	reportedConsumption: number,
}

export interface IComprehensiveNoonReportVesselSpecificDataRecord {
	value: string,
	date: string,
	id: string
}

export interface IComprehensiveNoonReportGraphData {
	graphDataType: string,
	graphMeasurement?: string
	isBarGraph: boolean,
	isModalVisible: boolean
}

export interface IComprehensiveNoonReportFilters {
	label: string,
	key: string,
	type: "text" | "number" | "date" | "dropdown",
};

export interface IReportStore extends IJobHOCState {
	garbage: {
		rawGarbageData: IGarbageRawRecord[] | null;
		vesselId: string;
		year: string;
		allVesselsGarbageDischargeData: IGarbageDischargeAllVesselsRecord[];
		allVesselsGarbageCategoryData: IGarbageCategoryAllVesselsRecord[];
	};
	bilgeWater: {
		quarterLabels: string[];
		rawBilgeWaterData: IBilgeWaterRecord[] | null;
		vesselId: string;
		year: string;
		allVesselBilgeWaterData: IBilgeWaterAllVesselAggregateRecord[];
	};
	ors: {
		rawOrsData: IORSAggregateRecord[];
		rawOrsVesselSpecificData: IORSVesselSpecificAggregateRecord[];
		vesselId: string;
		year: string;
	};
	cargoSlop: {
		rawCargoSlopData: ICargoSlopAggregateRecord[];
		rawCargoSlopVesselSpecificData: ICargoSlopVesselSpecificAggregateRecord[];
	};
	oilSpillsInWater: {
		rawOilSpillsInWaterData: IOilSpillsInWaterAggregateRecord[];
		rawOilSpillsInWaterVesselSpecificData: IOilSpillsInWaterVesselSpecificAggregateRecord[];
	};
	comprehensiveNoonReport: {
		rawComprehensiveNoonReportData: IComprehensiveNoonReportRecord[];
		rawComprehensiveNoonReportVesselSpecificData: IComprehensiveNoonReportVesselSpecificDataRecord[];
	};
	navnuc: {
		rawNavnucData: INavnucRecord[],
	};
	vesselId: string;
	year: string;
	vesselType: string;
	vesselClass: string;
	filters: Record<string, any>;
	pagination: IPagination;
}

export enum GARBAGE_DISCHARGE_CATEGORIES {
	GARBAGE_DISCHARGED_INTO_SEA = "GARBAGE_DISCHARGED_INTO_SEA",
	GARBAGE_INCINERATED = "GARBAGE_INCINERATED",
	GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS = "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS",
}

export enum IRawGarbageDischargeLabel {
	"FOOD_WASTES" = "Food wastes",
}

export interface IRawGarbageDischargeRecord {
	label: string; //Fix me: IRawGarbageDischargeLabel;
	estimatedAmountDischarged: number;
	value: string;
	category: string;
}

export interface IRawGarbageVesselInfo {
	_id: string;
	callSign: string;
	imoNumber: string;
	vesselName: string;
	vesselPrefix: string;
	vesselSubType: string;
	vesselType: string;
}

export interface IRawGarbageDateRange {
	month: number;
	year: number;
}

export interface IRawGarbageCategory {
	category: string; //Fix me - label enum
	garbageDischarged: number;
	label: string; //Fix me - enum
}

export interface IRawGarbageDischargeCategory {
	category: string; //GARBAGE_DISCHARGE_CATEGORIES;
	discharged: number;
	garbageRecords: IRawGarbageDischargeRecord[];
}

export interface IGarbageRawRecord {
	_id: string;
	tenantId: string;
	garbageDischargeCategories: IRawGarbageDischargeCategory[];
	vessel: IRawGarbageVesselInfo;
	totalGarbageDischarged: number;
	lastAggregatedAt: string;
	dateRange: IRawGarbageDateRange;
	garbageCategories: IRawGarbageCategory[];
}

export type IMonthwiseRawGarbageObj = {
	[month: string]: IGarbageRawRecord;
};

export interface IMonthWiseRawGarbageRow {
	[key: string]: Record<string, IRawGarbageDischargeCategory> | Object;
}

export interface IBilgeWaterRecord {
	_id: string;
	disposalMethod: string;
	tenantId: string;
	totalQuantityDisposed: number;
	vessel: IVessel;
	lastAggregatedAt: string;
	dateRange: {
		month: number;
		year: number;
	};
}

export interface IBilgeWaterAllVesselMonthData {
	month: number;
	totalQuantityDisposed: number;
}

export interface IBilgeWaterAllVesselAggregateRecord {
	disposalMethod: EBilgeWaterType;
	tenantId: string;
	monthWiseData: IBilgeWaterAllVesselMonthData[];
}

export interface ICustomTooltipProps {
	key: string;
	name: string;
	color: string;
}

export enum EDischargeTypes {
	GARBAGE_INCINERATED = "GARBAGE_INCINERATED",
	GARBAGE_DISCHARGED_INTO_SEA = "GARBAGE_DISCHARGED_INTO_SEA",
	GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS = "GARBAGE_DISCHARGED_TO_RECEPTION_ASHORE_OR_OTHER_SHIPS",
	ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA = "ACCIDENTAL_OR_LOSS_OF_GARBAGE_INTO_SEA",
}

export enum EGarbageCategoryTypes {
	PLASTICS = "A",
	FOOD_WASTES = "B",
	DOMESTIC_WASTE = "C",
	COOKING_OIL = "D",
	INCINERATOR_ASHES = "E",
	OPERATIONAL_WASTES = "F",
	EWASTE = "I",
}

export interface IGarbageDishcargeMonthWiseData {
	month: number;
	amountDischarged: number;
}
export interface IGarbageDischargeAllVesselsRecord {
	dischargeCategory: EDischargeTypes;
	tenantId: string;
	monthWiseData: IGarbageDishcargeMonthWiseData[];
}

export interface IGarbageCategoryMonthWiseData {
	month: number;
	amountDischarged: number;
}
export interface IGarbageCategoryAllVesselsRecord {
	category: EGarbageCategoryTypes;
	label: string;
	tenantId: string;
	monthWiseData: IGarbageCategoryMonthWiseData[];
}

export interface IGarbageCategoryGraphTransformedData {
	name?: string;
	A: number;
	B: number;
	C: number;
	D: number;
	E: number;
	F: number;
	I: number;
}

export enum EBilgeWaterType {
	OWS_OVERBOARD = "OWS Overboard",
	TRANSFER_TO_SLOP = "Transfer to Slop",
}

export interface IBilgeWaterGraphFinalData {
	quarter: string;
	transferToSlop: number;
	owsOverboard: number;
}

export interface IGarbageDischargeGraphFinalData {
	quarter: string;
	disposedAshore: number;
	dischargedToSea: number;
	incinerated: number;
	accidentalRelease: number;
}

export interface IGarbageCategoryGraphFinalData {
	quarter: string;
	A: number;
	B: number;
	C: number;
	D: number;
	E: number;
	F: number;
	I: number;
}

export interface IORSGraphFinalData {
	quarter: string;
	incinerated: number;
	evaporated: number;
	boiler: number;
	transferToSlop: number;
	disposedAshore: number;
	total?: string;
}

export interface IReportCategoryType {
	title: string;
	url?: string;
	isDisabled?: boolean;
	isHidden?: boolean;
}

export interface IReportCategory {
	title: string;
	types: IReportCategoryType[];
	isHidden?: boolean;
}

export interface ICargoSlopGraphFinalData {
	quarter: string;
	totalDischarged: number;
}

export interface IOilSpillsInWaterGraphFinalData {
	quarter: string;
	totalDischarged: number;
}

export interface INavnucRecord {
	_id: string;
	_rev: string
	modelName: string;
	formName: string;
	data: INavnucFormData;
	createdAt: Date;
	updatedAt: Date;
}
export interface INavnucFormData {
	vesselName: string;
	type_of_report: string;
	date_of_occurrence: string;
	type_of_activity: string;
	possible_consequences: string;
	vessel_draft: number;
	depth_of_water: number;
	name_of_port_allision_point: string;
	description: string;
	name_of_other_vessel: string;
	rank_of_person_related_to_act: string;
	name_of_reporter: string;
	rank_of_reporter: string;
	evidence: INavnucEvidence[];
}
export interface INavnucForms{
	forms: INavnucRecord[];
	pagination: IPagination;
}

export interface INavnucEvidence{
	fileName: string;
	fileType: string;
	fileSize: string;
	attachmentId: string;
	docId: string;
	url: string;
}

export interface IPagination {
	total: number;
	limit: number;
	skip: number;
	hasMore: boolean;
}