import { jobActions } from "redux/_core/job";
import { ActionType, createAction, getType } from "typesafe-actions";
import { CONFIG_TABS, PRINT_TABS, toolConstants } from "./constants";
import {
	ICPActivityLog,
	IChecklistsListItem,
	IConfigInfo,
	IConfigListItem,
	IGroupInfo,
	IIndividualGroup,
	ILogListItem,
	IPermitsListItem,
	IPrintChecklistsListItem,
	IPrintInfo,
	IPrintListItem,
	IPrintLogListItem,
	IPrintPermitsListItem,
	IReleaseInfo,
	ITenant,
	TConfigType,
	TGroupModeType,
	TPrintType,
	TToolType,
	TVesselsPossibleLocation,
} from "./@types";
import { IListObjectStore } from "redux/_common/type";
import { IOption, IVessel, IVesselUser } from "../feedback/@types";
import { apiActions } from "redux/_core/api";
import toolAPIs from "./api";
import { IStore } from 'redux/store';
import { AnyAction } from 'redux';
import { Dispatch } from 'redux';

export enum EToolActions {
	TOOL_CONFIG_LIST_LOAD = "@tool/config/list/LOAD",
	TOOL_CONFIG_LOAD = "@tool/config/LOAD",
	TOOL_CONFIG_CREATE = "@tool/config/CREATE",
	TOOL_CONFIG_UPDATE = "@tool/config/UPDATE",
	TOOL_CONFIG_COPY = "@tool/config/COPY",
	TOOL_TENANTS_LIST_LOAD = "@tool/tenants/list/LOAD",
	TOOL_TENANT_SPECIFIC_VESSELS_LOAD = "@tool/vessels/:tenantId/LOAD",
	TOOL_VESSEL_SPECIFIC_USER_EDIT = "@tool/vessels/:tenantId/user/EDIT",
	TOOL_USER_TRAINING_CERTIFICATE_APPROVE = "@tool/user/training-certificate-approve",
	TOOL_TENANT_SPECIFIC_ADD_TRAINING_USERS_FROM_CSV = "@tool/tenant/:tenantId/training-users-from-csv/ADD",
	TOOL_VESSEL_SPECIFIC_USERS_LOAD = "@tool/vessels/:vesselId/users/LOAD",
	TOOL_VESSEL_SPECIFIC_USERS_SET = "@tool/vessels/:vesselId/users/SET",
	TOOL_EMPTY_CONFIG_LIST_FOR_ALL_CATEGORY = "@tool/config-list/EMPTY",
	TOOL_CONFIG_DELETE = "@tool/config/DELETE",
	TOOL_CONFIG_SEARCH_CURRENT_PAGE_SET = "@tool/config/on-search/current-page-1/SET",

	// PRINT command actions
	TOOL_PRINT_LIST_LOAD = "@tool/print/list/LOAD",
	TOOL_PRINT_LOAD = "@tool/print/LOAD",
	TOOL_PRINT_CREATE = "@tool/print/CREATE",
	TOOL_PRINT_UPDATE = "@tool/print/UPDATE",
	TOOL_PRINT_COPY = "@tool/print/COPY",
	TOOL_EMPTY_PRINT_LIST_FOR_ALL_CATEGORY = "@tool/print-list/EMPTY",
	TOOL_PRINT_DELETE = "@tool/print/DELETE",
	TOOL_PRINT_SEARCH_CURRENT_PAGE_SET = "@tool/print/on-search/current-page-1/SET",

	// TOOL specific command actions
	TOOL_CONFIG_PRINT_ACTIVITY_LOG_LOAD = "@tool/config-print/activity-log/LOAD",
	TOOL_LIST_COUNT_LOAD = "@tool/list/count/LOAD",

	// TOOL_CONFIG_LIST_SET = "@tool/config/list/SET",
	TOOL_CONFIG_LOGS_LIST_SET = "@tool/config/logs/list/SET",
	TOOL_CONFIG_CHECKLISTS_LIST_SET = "@tool/config/checklists/list/SET",
	TOOL_CONFIG_PERMITS_LIST_SET = "@tool/config/permits/list/SET",
	TOOL_TYPE_SET = "@tool/type/SET",
	TOOL_CONFIG_TAB_SET = "@tool/config/tab/SET",
	TOOL_CONFIG_ITEMS_SELECT = "@tool/config/items/SELECT",
	TOOL_CONFIRM_BOX_TOGGLE = "@tool/config-print/confirm-box/TOGGLE",
	TOOL_CONFIG_FORM_EXPANDER_TOGGLE = "@tool/config-form/expander/TOGGLE",
	TOOL_COPY_MODAL_TOGGLE = "@tool/copy-modal/TOGGLE",
	TOOL_UPDATE_MODAL_TOGGLE = "@tool/update-modal/TOGGLE",
	TOOL_CONFIG_ADD_MODAL_TOGGLE = "@tool/config-add-modal/TOGGLE",
	TOOL_CONFIG_VIEW_MODAL_TOGGLE = "@tool/config-view-modal/TOGGLE",
	TOOL_TENANTS_LIST_SET = "@tool/tenant/List/SET",
	TOOL_TENANT_SPECIFIC_VESSELS_SET = "@tool/:tenantId/vessels/SET",
	TOOL_TRAINING_APPROVE_CERTIFICATE = "@tool/training-approve-certificate",
	TOOL_CONFIG_PRINT_TENANT_SELECT = "@tool/config-print/tenandId/SELECT",
	TOOL_CONFIG_PRINT_VESSEL_SELECT = "@tool/config-print/vessel/SELECT",
	TOOL_CONFIG_LOG_PAGINATION_TOTAL_ITEMS_SET = "@tool/config-log/pagination/total-items/SET",
	TOOL_CONFIG_LOG_PAGINATION_ITEMS_PER_PAGE_SET = "@tool/config-log/pagination/items-per-page/SET",
	TOOL_CONFIG_LOG_PAGINATION_CURRENT_PAGE_SET = "@tool/config-log/pagination/current-page/SET",
	TOOL_CONFIG_LOG_PAGINATION_RESET = "@tool/config-log/pagination/RESET",
	TOOL_CONFIG_CHECKLIST_PAGINATION_TOTAL_ITEMS_SET = "@tool/config-checklist/pagination/total-items/SET",
	TOOL_CONFIG_CHECKLIST_PAGINATION_ITEMS_PER_PAGE_SET = "@tool/config-checklist/pagination/items-per-page/SET",
	TOOL_CONFIG_CHECKLIST_PAGINATION_CURRENT_PAGE_SET = "@tool/config-checklist/pagination/current-page/SET",
	TOOL_CONFIG_PERMIT_PAGINATION_TOTAL_ITEMS_SET = "@tool/config-permit/pagination/total-items/SET",
	TOOL_CONFIG_PERMIT_PAGINATION_ITEMS_PER_PAGE_SET = "@tool/config-permit/pagination/items-per-page/SET",
	TOOL_CONFIG_PERMIT_PAGINATION_CURRENT_PAGE_SET = "@tool/config-permit/pagination/current-page/SET",
	TOOL_CONFIG_INFO_SET = "@tool/:config-id/info/SET",
	TOOL_CONFIG_ERROR_MESSAGE_SET = "@tool/config/error-messsage/SET",
	TOOL_CONFIG_COPY_TENANT_SPECIFIC_VESSELS_SET = "@tool/config-copy/:tenantId/vessels/SET",
	TOOL_CONFIG_COPY_TENANT_SELECT = "@tool/config-copy/tenandId/SELECT",
	TOOL_CONFIG_COPY_GROUP_SELECT = "@tool/config-copy/groupId/SELECT",
	TOOL_CONFIG_COPY_VESSELS_SELECT = "@tool/config-copy/vessels/SELECT",
	TOOL_CONFIG_ID_ON_ACTION_SELECT = "@tool/config-action/:configId/SELECT",
	TOOL_CONFIG_PRINT_SEARCH_INPUT_SET = "@tool/config-print/search-input/SET",

	// PRINT specific document actions
	TOOL_PRINT_LOGS_LIST_SET = "@tool/print/logs/list/SET",
	TOOL_PRINT_CHECKLISTS_LIST_SET = "@tool/print/checklists/list/SET",
	TOOL_PRINT_PERMITS_LIST_SET = "@tool/print/permits/list/SET",
	TOOL_PRINT_TAB_SET = "@tool/print/tab/SET",
	TOOL_PRINT_ITEMS_SELECT = "@tool/print/items/SELECT",
	TOOL_PRINT_FORM_EXPANDER_TOGGLE = "@tool/print-form/expander/TOGGLE",
	TOOL_PRINT_COPY_MODAL_TOGGLE = "@tool/print/copy-modal/TOGGLE",
	TOOL_PRINT_UPDATE_MODAL_TOGGLE = "@tool/print/update-modal/TOGGLE",
	TOOL_PRINT_ADD_MODAL_TOGGLE = "@tool/print/add-modal/TOGGLE",
	TOOL_PRINT_VIEW_MODAL_TOGGLE = "@tool/print-view-modal/TOGGLE",
	TOOL_PRINT_LOG_PAGINATION_TOTAL_ITEMS_SET = "@tool/print-log/pagination/total-items/SET",
	TOOL_PRINT_LOG_PAGINATION_ITEMS_PER_PAGE_SET = "@tool/print-log/pagination/items-per-page/SET",
	TOOL_PRINT_LOG_PAGINATION_CURRENT_PAGE_SET = "@tool/print-log/pagination/current-page/SET",
	TOOL_PRINT_CHECKLIST_PAGINATION_TOTAL_ITEMS_SET = "@tool/print-checklist/pagination/total-items/SET",
	TOOL_PRINT_CHECKLIST_PAGINATION_ITEMS_PER_PAGE_SET = "@tool/print-checklist/pagination/items-per-page/SET",
	TOOL_PRINT_CHECKLIST_PAGINATION_CURRENT_PAGE_SET = "@tool/print-checklist/pagination/current-page/SET",
	TOOL_PRINT_PERMIT_PAGINATION_TOTAL_ITEMS_SET = "@tool/print-permit/pagination/total-items/SET",
	TOOL_PRINT_PERMIT_PAGINATION_ITEMS_PER_PAGE_SET = "@tool/print-permit/pagination/items-per-page/SET",
	TOOL_PRINT_PERMIT_PAGINATION_CURRENT_PAGE_SET = "@tool/print-permit/pagination/current-page/SET",
	TOOL_PRINT_INFO_SET = "@tool/:print-id/info/SET",
	TOOL_PRINT_ERROR_MESSAGE_SET = "@tool/print/error-messsage/SET",
	TOOL_PRINT_COPY_TENANT_SPECIFIC_VESSELS_SET = "@tool/print-copy/:tenantId/vessels/SET",
	TOOL_PRINT_COPY_TENANT_SELECT = "@tool/print-copy/tenandId/SELECT",
	TOOL_PRINT_COPY_GROUP_SELECT = "@tool/print-copy/groupId/SELECT",
	TOOL_PRINT_COPY_VESSELS_SELECT = "@tool/print-copy/vessels/SELECT",
	TOOL_PRINT_ID_ON_ACTION_SELECT = "@tool/print-action/:printId/SELECT",

	// TOOL specific document actions
	TOOL_CONFIG_PRINT_ACTIVITY_LIST_SET = "@tool/cp/activitylist/SET",
	TOOL_CP_ACTIVITY_LOG_PAGINATION_TOTAL_ITEMS_SET = "@tool/cp-activity-log/pagination/total-items/SET",
	// TOOL_CP_ACTIVITY_LOG_PAGINATION_ITEMS_PER_PAGE_SET = "@tool/cp-activity-log/pagination/items-per-page/SET",
	TOOL_CP_ACTIVITY_LOG_PAGINATION_CURRENT_PAGE_SET = "@tool/cp-activity-log/pagination/current-page/SET",

	// activity log - card view
	TOOL_CONFIG_PRINT_CARD_ACTIVITY_LIST_SET = "@tool/cp/card/activitylist/SET",
	TOOL_CONFIG_PRINT_CARD_ACTIVITY_LIST_RESET = "@tool/cp/card/activitylist/RESET",
	TOOL_CP_CARD_ACTIVITY_LOG_PAGINATION_CURRENT_PAGE_SET = "@tool/cp-activity-log/card/pagination/current-page/SET",

	// GROUPs
	TOOL_GROUPS_LIST_LOAD = "@tool/groups/list/LOAD",
	TOOL_GROUPS_GROUP_CREATE = "@tool/groups/group/CREATE",
	TOOL_GROUPS_GROUP_UPDATE = "@tool/groups/group/UPDATE",

	TOOL_GROUPS_GROUP_MODAL_TOGGLE = "@tool/groups/group-modal/TOGGLE",
	TOOL_GROUPS_MODE_SET = "@tool/groups/mode/SET",
	TOOL_GROUPS_TENANT_OPTION_SELECT = "@tool/groups/tenant-option/SELECT",
	TOOL_GROUPS_VESSEL_OPTIONS_SELECT = "@tool/groups/vessel-options/SELECT",
	TOOL_GROUPS_CONFIRM_BOX_TOGGLE = "@tool/groups/confirm-box-modal/TOGGLE",
	TOOL_GROUPS_GROUP_LIST_SET = "@tool/groups/groupList/SET",
	TOOL_GROUPS_GROUP_DETAILS_SET = "@tool/groups/group-details/SET",
	TOOL_GROUPS_ID_OF_GROUP_TO_BE_UPDATED = "@tool/group/id-of-group-tobe-updated/SET",
	TOOL_GROUPS_PAGINATION_TOTAL_ITEMS_SET = "@tool/groups/pagination/total-items/SET",
	TOOL_GROUPS_PAGINATION_ITEMS_PER_PAGE_SET = "@tool/groups/pagination/items-per-page/SET",
	TOOL_GROUPS_PAGINATION_CURRENT_PAGE_SET = "@tool/groups/pagination/current-page/SET",
	TOOL_RELEASES_LIST_SET = "@tool/releases/list/SET",
	TOOL_RELEASES_LIST_LOAD = "@tool/releases/list/LOAD",
	TOOL_RELEASE_TENANT_OPTION_SELECT = "@tool/release/tenant-option/SELECT",
	TOOL_RELEASE_UPLOAD = "@tool/release/UPLOAD",
	TOOL_RELEASE_DEPLOY = "@tool/release/DEPLOY",
	TOOL_RELEASE_MULTI_DEPLOY = "@tool/release/MULTI_DEPLOY",
	TOOL_RELEASE_TENANT_DEPLOY = "@tool/release/TENANT_DEPLOY",
	TOOL_RELEASE_HISTORY_LOAD = "@tool/release/history/LOAD",
	TOOL_RELEASE_HISTORY_SET = "@tool/release/history/SET",
	TOOL_RELEASE_TOGGLE_AUTO_UPDATE = "@tool/release/TOGGLE_AUTO_UPDATE"
}

const toolActions = {
	commands: {
		toolConfigListLoad: createAction(
			EToolActions.TOOL_CONFIG_LIST_LOAD,
			(configType: TConfigType, vesselId?: string) => ({
				configType,
				vesselId,
			})
		)(),
		toolConfigLoad: createAction(
			EToolActions.TOOL_CONFIG_LOAD,
			(configId: string) => ({
				configId,
			})
		)(),
		toolConfigCreate: createAction(
			EToolActions.TOOL_CONFIG_CREATE,
			(config: any, changeLog) => ({ config, changeLog })
		)(),
		toolConfigUpdate: createAction(
			EToolActions.TOOL_CONFIG_UPDATE,
			(config: any, changeLog) => ({
				config,
				changeLog,
			})
		)(),
		toolConfigCopy: createAction(
			EToolActions.TOOL_CONFIG_COPY,
			(itemsToBeCopied: IConfigListItem[]) => ({ itemsToBeCopied })
		)(),
		toolConfigDelete: createAction(
			EToolActions.TOOL_CONFIG_DELETE,
			(configId: string) => ({
				configId,
			})
		)(),
		toolConfigOnSearchCurrentPageTo1Set: createAction(
			EToolActions.TOOL_CONFIG_SEARCH_CURRENT_PAGE_SET
		)(),
		toolTenantListLoad: createAction(EToolActions.TOOL_TENANTS_LIST_LOAD)(),
		toolUserTrainingCertificateApprove: createAction(
			EToolActions.TOOL_USER_TRAINING_CERTIFICATE_APPROVE,
			(userId: string) => ({
				userId,
			})
		)(),
		toolTenantSpecificAddTrainingUsersFromCSV: createAction(
			EToolActions.TOOL_TENANT_SPECIFIC_ADD_TRAINING_USERS_FROM_CSV,
			(tenantId: string, file: File) => ({
				file,
				tenantId,
			})
		)(),
		toolVesselSpecificUsersLoad: createAction(
			EToolActions.TOOL_VESSEL_SPECIFIC_USERS_LOAD,
			(tenantId?: string, vesselId?: string, isTrainingRequired?: boolean) => ({
				tenantId,
				vesselId,
				isTrainingRequired,
			})
		)(),
		toolTenantSpecificVesselLoad: createAction(
			EToolActions.TOOL_TENANT_SPECIFIC_VESSELS_LOAD,
			(
				tenantId: string,
				possibleLocation: TVesselsPossibleLocation = "config-prints"
			) => ({
				tenantId,
				possibleLocation,
			})
		)(),
		toolVesselSpecificUserEdit: createAction(
			EToolActions.TOOL_VESSEL_SPECIFIC_USER_EDIT,
			(userId: string, date: string) => ({
				userId,
				date,
			})
		)(),
		toolConfigListEmptyForAllCategory: createAction(
			EToolActions.TOOL_EMPTY_CONFIG_LIST_FOR_ALL_CATEGORY
		)(),
		toolPrintListLoad: createAction(
			EToolActions.TOOL_PRINT_LIST_LOAD,
			(printType: TPrintType, vesselId?: string) => ({
				printType,
				vesselId,
			})
		)(),
		toolPrintLoad: createAction(
			EToolActions.TOOL_PRINT_LOAD,
			(printId: string) => ({
				printId,
			})
		)(),
		toolPrintCreate: createAction(
			EToolActions.TOOL_PRINT_CREATE,
			(print: any, changeLog) => ({ print, changeLog })
		)(),
		toolPrintUpdate: createAction(
			EToolActions.TOOL_PRINT_UPDATE,
			(print: any, changeLog, newFileRequired?: boolean) => ({
				print,
				changeLog,
				newFileRequired,
			})
		)(),
		toolPrintCopy: createAction(
			EToolActions.TOOL_PRINT_COPY,
			(itemsToBeCopied: IPrintListItem[]) => ({ itemsToBeCopied })
		)(),
		toolPrintDelete: createAction(
			EToolActions.TOOL_PRINT_DELETE,
			(printId: string) => ({
				printId,
			})
		)(),
		toolPrintListEmptyForAllCategory: createAction(
			EToolActions.TOOL_EMPTY_PRINT_LIST_FOR_ALL_CATEGORY
		)(),
		toolPrintOnSearchCurrentPageTo1Set: createAction(
			EToolActions.TOOL_PRINT_SEARCH_CURRENT_PAGE_SET
		)(),
		toolCPActivityLogLoad: createAction(
			EToolActions.TOOL_CONFIG_PRINT_ACTIVITY_LOG_LOAD,
			(isCardView: boolean = false) => ({ isCardView })
		)(),
		toolCountListLoad: createAction(
			EToolActions.TOOL_LIST_COUNT_LOAD,
			(modelName: string, typeOfTool: "cp-activity-log") => ({
				modelName,
				typeOfTool,
			})
		)(),
		toolGroupsListLoad: createAction(
			EToolActions.TOOL_GROUPS_LIST_LOAD,
			(tenantId?: string) => ({ tenantId })
		)(),
		toolGroupsGroupCreate: createAction(
			EToolActions.TOOL_GROUPS_GROUP_CREATE
		)(),
		toolGroupsGroupUpdate: createAction(
			EToolActions.TOOL_GROUPS_GROUP_UPDATE
		)(),
		toolReleasesListLoad: createAction(
			EToolActions.TOOL_RELEASES_LIST_LOAD,
			(tenantId?: string, page: number = 1, vesselId?: string, buildChannel?: string, autoUpdate?: string, revpos?: string) => ({
				tenantId,
				page,
				vesselId,
				buildChannel,
				autoUpdate,
				revpos
			})
		)(),
		toolReleaseUpload: createAction(
			EToolActions.TOOL_RELEASE_UPLOAD,
			(formData: FormData) => ({ formData })
		)(),
		toolReleaseDeploy: createAction(
			EToolActions.TOOL_RELEASE_DEPLOY,
			(vesselId: string, buildChannel: 'main' | 'hotfix') => ({ vesselId, buildChannel })
		)(),
		toolReleaseMultiDeploy: createAction(
			EToolActions.TOOL_RELEASE_MULTI_DEPLOY,
			(deviceIds: string[], buildChannel: 'main' | 'hotfix') => ({ deviceIds, buildChannel })
		)(),
		toolReleaseTenantDeploy: createAction(
			EToolActions.TOOL_RELEASE_TENANT_DEPLOY,
			(tenantId: string, buildChannel: 'main' | 'hotfix') => ({ tenantId, buildChannel })
		)(),
		toolReleaseHistoryLoad: createAction(
			EToolActions.TOOL_RELEASE_HISTORY_LOAD
		)(),
		toolReleaseToggleAutoUpdate: createAction(
			EToolActions.TOOL_RELEASE_TOGGLE_AUTO_UPDATE,
			(deviceId: string, autoUpdate: boolean) => ({ deviceId, autoUpdate })
		)()
	},
	document: {
		toolConfigLogsListSet: createAction(
			EToolActions.TOOL_CONFIG_LOGS_LIST_SET,
			(logs: IListObjectStore<ILogListItem>) => ({ logs })
		)(),
		toolConfigChecklistsListSet: createAction(
			EToolActions.TOOL_CONFIG_CHECKLISTS_LIST_SET,
			(checklists: IListObjectStore<IChecklistsListItem>) => ({
				checklists,
			})
		)(),
		toolConfigPermitsListSet: createAction(
			EToolActions.TOOL_CONFIG_PERMITS_LIST_SET,
			(permits: IListObjectStore<IPermitsListItem>) => ({ permits })
		)(),
		toolTypeSet: createAction(
			EToolActions.TOOL_TYPE_SET,
			(toolType: TToolType) => ({
				toolType,
			})
		)(),
		toolConfigTabSet: createAction(
			EToolActions.TOOL_CONFIG_TAB_SET,
			(configTab: CONFIG_TABS) => ({
				configTab,
			})
		)(),
		toolConfigItemsSelect: createAction(
			EToolActions.TOOL_CONFIG_ITEMS_SELECT,
			(selectedItems: string[]) => ({
				selectedItems,
			})
		)(),
		confirmBoxToggle: createAction(EToolActions.TOOL_CONFIRM_BOX_TOGGLE)(),
		copyModalToggle: createAction(EToolActions.TOOL_COPY_MODAL_TOGGLE)(),
		updateModalToggle: createAction(EToolActions.TOOL_UPDATE_MODAL_TOGGLE)(),
		configAddModalToggle: createAction(
			EToolActions.TOOL_CONFIG_ADD_MODAL_TOGGLE
		)(),
		configViewModalToggle: createAction(
			EToolActions.TOOL_CONFIG_VIEW_MODAL_TOGGLE
		)(),
		configFormExpanderToggle: createAction(
			EToolActions.TOOL_CONFIG_FORM_EXPANDER_TOGGLE,
			(isExpanded?: boolean) => ({ isExpanded })
		)(),
		toolTenantSpecificVesselsSet: createAction(
			EToolActions.TOOL_TENANT_SPECIFIC_VESSELS_SET,
			(vessels: IListObjectStore<IVessel>) => ({
				vessels,
			})
		)(),
		toolTenantListSet: createAction(
			EToolActions.TOOL_TENANTS_LIST_SET,
			(tenants: IListObjectStore<ITenant>) => ({
				tenants,
			})
		)(),
		toolVesselSpecificUsersSet: createAction(
			EToolActions.TOOL_VESSEL_SPECIFIC_USERS_SET,
			(vesselSpecificUsers: IListObjectStore<IVesselUser>) => ({
				vesselSpecificUsers,
			})
		)(),
		toolTrainingApproveCertificate: createAction(
			EToolActions.TOOL_TRAINING_APPROVE_CERTIFICATE,
			(approveCertificate: boolean) => ({
				approveCertificate,
			})
		)(),
		toolConfigPrintTenandOptionSelect: createAction(
			EToolActions.TOOL_CONFIG_PRINT_TENANT_SELECT,
			(tenantOption?: IOption) => ({
				tenantOption,
			})
		)(),
		toolConfigPrintVesselOptionSelect: createAction(
			EToolActions.TOOL_CONFIG_PRINT_VESSEL_SELECT,
			(vesselOption?: IOption) => ({
				vesselOption,
			})
		)(),
		toolConfigCopyModalTenantSpecificVesselsSet: createAction(
			EToolActions.TOOL_CONFIG_COPY_TENANT_SPECIFIC_VESSELS_SET,
			(vessels: IListObjectStore<IVessel>) => ({
				vessels,
			})
		)(),
		toolConfigCopyTenandOptionSelect: createAction(
			EToolActions.TOOL_CONFIG_COPY_TENANT_SELECT,
			(tenantOption?: IOption) => ({
				tenantOption,
			})
		)(),
		toolConfigCopyGroupOptionSelect: createAction(
			EToolActions.TOOL_CONFIG_COPY_GROUP_SELECT,
			(groupOption?: IOption) => ({
				groupOption,
			})
		)(),
		toolConfigCopyVesselOptionsSelect: createAction(
			EToolActions.TOOL_CONFIG_COPY_VESSELS_SELECT,
			(vesselOptions?: IOption[]) => ({
				vesselOptions,
			})
		)(),
		toolConfigLogPaginationTotalItemsSet: createAction(
			EToolActions.TOOL_CONFIG_LOG_PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		toolConfigLogPaginationItemsPerPageSet: createAction(
			EToolActions.TOOL_CONFIG_LOG_PAGINATION_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		toolConfigLogPaginationCurrentPageSet: createAction(
			EToolActions.TOOL_CONFIG_LOG_PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		toolConfigLogpaginationReset: createAction(
			EToolActions.TOOL_CONFIG_LOG_PAGINATION_RESET
		)(),
		toolConfigChecklistPaginationTotalItemsSet: createAction(
			EToolActions.TOOL_CONFIG_CHECKLIST_PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		toolConfigChecklistPaginationItemsPerPageSet: createAction(
			EToolActions.TOOL_CONFIG_CHECKLIST_PAGINATION_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		toolConfigChecklistPaginationCurrentPageSet: createAction(
			EToolActions.TOOL_CONFIG_CHECKLIST_PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		toolConfigPermitPaginationTotalItemsSet: createAction(
			EToolActions.TOOL_CONFIG_PERMIT_PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		toolConfigPermitPaginationItemsPerPageSet: createAction(
			EToolActions.TOOL_CONFIG_PERMIT_PAGINATION_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		toolConfigPermitPaginationCurrentPageSet: createAction(
			EToolActions.TOOL_CONFIG_PERMIT_PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		toolConfigInfoSet: createAction(
			EToolActions.TOOL_CONFIG_INFO_SET,
			(configInfo?: IConfigInfo) => ({ configInfo })
		)(),
		toolConfigErrorMessageSet: createAction(
			EToolActions.TOOL_CONFIG_ERROR_MESSAGE_SET,
			(errorMessage: string) => ({ errorMessage })
		)(),
		toolConfigIdOnActionSelect: createAction(
			EToolActions.TOOL_CONFIG_ID_ON_ACTION_SELECT,
			(configId: string) => ({ configId })
		)(),
		toolConfigPrintSearchInputSet: createAction(
			EToolActions.TOOL_CONFIG_PRINT_SEARCH_INPUT_SET,
			(searchInput: string) => ({ searchInput })
		)(),

		// actions specific to print
		toolPrintLogsListSet: createAction(
			EToolActions.TOOL_PRINT_LOGS_LIST_SET,
			(logs: IListObjectStore<IPrintLogListItem>) => ({ logs })
		)(),
		toolPrintChecklistsListSet: createAction(
			EToolActions.TOOL_PRINT_CHECKLISTS_LIST_SET,
			(checklists: IListObjectStore<IPrintChecklistsListItem>) => ({
				checklists,
			})
		)(),
		toolPrintPermitsListSet: createAction(
			EToolActions.TOOL_PRINT_PERMITS_LIST_SET,
			(permits: IListObjectStore<IPrintPermitsListItem>) => ({ permits })
		)(),
		toolPrintTabSet: createAction(
			EToolActions.TOOL_PRINT_TAB_SET,
			(printTab: PRINT_TABS) => ({
				printTab,
			})
		)(),
		toolPrintItemsSelect: createAction(
			EToolActions.TOOL_PRINT_ITEMS_SELECT,
			(selectedItems: string[]) => ({
				selectedItems,
			})
		)(),
		printCopyModalToggle: createAction(
			EToolActions.TOOL_PRINT_COPY_MODAL_TOGGLE
		)(),
		printUpdateModalToggle: createAction(
			EToolActions.TOOL_PRINT_UPDATE_MODAL_TOGGLE
		)(),
		printAddModalToggle: createAction(
			EToolActions.TOOL_PRINT_ADD_MODAL_TOGGLE
		)(),
		printViewModalToggle: createAction(
			EToolActions.TOOL_PRINT_VIEW_MODAL_TOGGLE
		)(),
		printFormExpanderToggle: createAction(
			EToolActions.TOOL_PRINT_FORM_EXPANDER_TOGGLE,
			(isExpanded?: boolean) => ({ isExpanded })
		)(),
		toolPrintLogPaginationTotalItemsSet: createAction(
			EToolActions.TOOL_PRINT_LOG_PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		toolPrintLogPaginationItemsPerPageSet: createAction(
			EToolActions.TOOL_PRINT_LOG_PAGINATION_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		toolPrintLogPaginationCurrentPageSet: createAction(
			EToolActions.TOOL_PRINT_LOG_PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		toolPrintChecklistPaginationTotalItemsSet: createAction(
			EToolActions.TOOL_PRINT_CHECKLIST_PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		toolPrintChecklistPaginationItemsPerPageSet: createAction(
			EToolActions.TOOL_PRINT_CHECKLIST_PAGINATION_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		toolPrintChecklistPaginationCurrentPageSet: createAction(
			EToolActions.TOOL_PRINT_CHECKLIST_PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		toolPrintPermitPaginationTotalItemsSet: createAction(
			EToolActions.TOOL_PRINT_PERMIT_PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		toolPrintPermitPaginationItemsPerPageSet: createAction(
			EToolActions.TOOL_PRINT_PERMIT_PAGINATION_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		toolPrintPermitPaginationCurrentPageSet: createAction(
			EToolActions.TOOL_PRINT_PERMIT_PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		toolPrintInfoSet: createAction(
			EToolActions.TOOL_PRINT_INFO_SET,
			(printInfo?: IPrintInfo) => ({ printInfo })
		)(),
		toolPrintErrorMessageSet: createAction(
			EToolActions.TOOL_PRINT_ERROR_MESSAGE_SET,
			(errorMessage: string) => ({ errorMessage })
		)(),
		toolPrintIdOnActionSelect: createAction(
			EToolActions.TOOL_PRINT_ID_ON_ACTION_SELECT,
			(printId: string) => ({ printId })
		)(),
		toolPrintCopyModalTenantSpecificVesselsSet: createAction(
			EToolActions.TOOL_PRINT_COPY_TENANT_SPECIFIC_VESSELS_SET,
			(vessels: IListObjectStore<IVessel>) => ({
				vessels,
			})
		)(),
		toolPrintCopyTenandOptionSelect: createAction(
			EToolActions.TOOL_PRINT_COPY_TENANT_SELECT,
			(tenantOption?: IOption) => ({
				tenantOption,
			})
		)(),
		toolPrintCopyGroupOptionSelect: createAction(
			EToolActions.TOOL_PRINT_COPY_GROUP_SELECT,
			(groupOption?: IOption) => ({
				groupOption,
			})
		)(),
		toolPrintCopyVesselOptionsSelect: createAction(
			EToolActions.TOOL_PRINT_COPY_VESSELS_SELECT,
			(vesselOptions?: IOption[]) => ({
				vesselOptions,
			})
		)(),
		toolCPActivityLogListSet: createAction(
			EToolActions.TOOL_CONFIG_PRINT_ACTIVITY_LIST_SET,
			(activityList: IListObjectStore<ICPActivityLog>) => ({
				activityList,
			})
		)(),
		toolCPCardActivityLogListSet: createAction(
			EToolActions.TOOL_CONFIG_PRINT_CARD_ACTIVITY_LIST_SET,
			(activityList: ICPActivityLog[]) => ({ activityList })
		)(),
		toolCPCardActivityLogListReset: createAction(
			EToolActions.TOOL_CONFIG_PRINT_CARD_ACTIVITY_LIST_RESET
		)(),
		toolCPActivityLogPaginationTotalItemsSet: createAction(
			EToolActions.TOOL_CP_ACTIVITY_LOG_PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		toolCPActivityLogPaginationCurrentPageSet: createAction(
			EToolActions.TOOL_CP_ACTIVITY_LOG_PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		toolCPCardActivityLogPaginationCurrentPageSet: createAction(
			EToolActions.TOOL_CP_CARD_ACTIVITY_LOG_PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		groupModalToggle: createAction(
			EToolActions.TOOL_GROUPS_GROUP_MODAL_TOGGLE,
			(isGroupModalVisible?: boolean) => ({ isGroupModalVisible })
		)(),
		groupModeSet: createAction(
			EToolActions.TOOL_GROUPS_MODE_SET,
			(mode: TGroupModeType) => ({ mode })
		)(),
		groupConfirmBoxToggle: createAction(
			EToolActions.TOOL_GROUPS_CONFIRM_BOX_TOGGLE,
			(isConfirmBoxModalOpen?: boolean) => ({
				isConfirmBoxModalOpen,
			})
		)(),
		toolGroupListSet: createAction(
			EToolActions.TOOL_GROUPS_GROUP_LIST_SET,
			(groupList: IListObjectStore<IGroupInfo>) => ({ groupList })
		)(),
		toolGroupsGroupDetailsSet: createAction(
			EToolActions.TOOL_GROUPS_GROUP_DETAILS_SET,
			(groupDetails: IIndividualGroup) => ({ groupDetails })
		)(),
		toolGroupsIdOfGroupToBeUpdated: createAction(
			EToolActions.TOOL_GROUPS_ID_OF_GROUP_TO_BE_UPDATED,
			(idOfGroupToBeUpdated: string) => ({ idOfGroupToBeUpdated })
		)(),
		toolGroupsPaginationTotalItemsSet: createAction(
			EToolActions.TOOL_GROUPS_PAGINATION_TOTAL_ITEMS_SET,
			(totalItems: number) => ({ totalItems })
		)(),
		toolGroupsPaginationItemsPerPageSet: createAction(
			EToolActions.TOOL_GROUPS_PAGINATION_ITEMS_PER_PAGE_SET,
			(itemsPerPage: number) => ({ itemsPerPage })
		)(),
		toolGroupsPaginationCurrentPageSet: createAction(
			EToolActions.TOOL_GROUPS_PAGINATION_CURRENT_PAGE_SET,
			(currentPage: number) => ({ currentPage })
		)(),
		toolReleaseTenantOptionSelect: createAction(
			EToolActions.TOOL_RELEASE_TENANT_OPTION_SELECT,
			(tenantOption?: IOption) => ({ tenantOption })
		)(),
		toolReleasesListSet: createAction(
			EToolActions.TOOL_RELEASES_LIST_SET,
			(data: IReleaseInfo[], pagination: any) => ({ data, pagination })
		)(),
		toolReleaseHistorySet: createAction(
			EToolActions.TOOL_RELEASE_HISTORY_SET,
			(history: any) => ({ history })
		)()
	},
};

export const toolJobActions = {
	toolConfigListLoad: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolConfigListLoad)
	),
	toolConfigLoad: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolConfigLoad)
	),
	toolConfigCreate: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolConfigCreate)
	),
	toolConfigUpdate: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolConfigUpdate)
	),
	toolConfigCopy: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolConfigCopy)
	),
	toolTenantSpecificAddTrainingUsersFromCSV: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolTenantSpecificAddTrainingUsersFromCSV)
	),
	toolTenantListLoad: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolTenantListLoad)
	),
	toolUserTrainingCertificateApprove: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolUserTrainingCertificateApprove)
	),
	toolVesselSpecificUsersLoad: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolVesselSpecificUsersLoad)
	),
	toolVesselSpecificUserEdit: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolVesselSpecificUserEdit)
	),
	toolTenantSpecificVesselsListLoad: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolTenantSpecificVesselLoad)
	),
	toolConfigDelete: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolConfigDelete)
	),
	toolPrintListLoad: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolPrintListLoad)
	),
	toolPrintLoad: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolPrintLoad)
	),
	toolPrintCreate: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolPrintCreate)
	),
	toolPrintUpdate: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolPrintUpdate)
	),
	toolPrintCopy: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolPrintCopy)
	),
	toolPrintDelete: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolPrintDelete)
	),
	toolCPActivityLog: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolCPActivityLogLoad)
	),
	toolListCountLoad: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolCountListLoad)
	),
	toolGroupsListLoad: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolGroupsListLoad)
	),
	toolGroupsGroupCreate: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolGroupsGroupCreate)
	),
	toolGroupsGroupUpdate: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolGroupsGroupUpdate)
	),
	toolReleasesListLoad: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolReleasesListLoad)
	),
	toolReleaseUpload: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolReleaseUpload)
	),
	toolReleaseDeploy: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolReleaseDeploy)
	),
	toolReleaseMultiDeploy: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolReleaseMultiDeploy)
	),
	toolReleaseTenantDeploy: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolReleaseTenantDeploy)
	),
	toolReleaseHistoryLoad: jobActions(
		toolConstants.FEATURE,
		getType(toolActions.commands.toolReleaseHistoryLoad)
	)
};

export type TToolActions = ActionType<typeof toolActions>;
export default toolActions;
