import classNames from 'classnames';
import InfiniteProgress from 'components/_common/InfiniteProgress';
import Pagination from "components/_common/NumberedPagination";
import React, { FC, useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { historyActions } from 'redux/_core/history';
import { IStore } from 'redux/store';
import { rootRoutes } from 'routes';
import ModalPortal from 'components/app/_common/ModalPortal';
import { EReportType, ITabReport } from "redux/app/logs/@types";
import { logActions, logSelectors } from "redux/app/logs";
import ReportRow from "./ReportRow";
import TemplateRenderer from "./TemplateRenderer";
import ErrorModal from "./ErrorModal";
import authSelectors from "redux/app/auth/selectors";
import { Toaster } from 'react-hot-toast';

type ReportsOwnProps = {
	pageNumber: string;
	vesselId?: string;
};

type ReportsProps = PropsFromRedux & ReportsOwnProps;

export interface IReportErrorDetails {
	reportName: string;
	errorDetails: {
		formID: string | null;
		success: boolean;
		formStatus: string | null;
		formErrors: Array<string> | null;
		message: string;
	};
	lastProcessedDate: string;
	reportType: EReportType;
	docId: string;
	vesselId: string;
	doc_status: "DRAFT" | "SUBMITTED" | "VERIFIED" | "APPROVED" | "RESUBMIT";
}

const Reports: FC<ReportsProps> = (props) => {
	const {
		reports,
		paginationOfReports,
		pageNumber,
		jobToLoadReports,
		jobToFilterReports,
		vesselId,
		isUserThirdParty,
		isUserAdmin,
		isUserAllowedToUseCNRTool,
		vesselName,
		vesselImo,
	} = props;
	const setIsDragRef = useRef((value: boolean) => {});

	const [reportOperation, setReportOperation] = useState<{
		isModalOpen: boolean;
	}>({
		isModalOpen: false,
	});

	const [errorDetailsModalOpen, setErrorDetailsModalOpen] = useState(false);

	const [errorDetails, setErrorDetails] =
		useState<IReportErrorDetails | null>(null);

	function resetOperations() {
		setReportOperation((reportOperation) => {
			return {
				...reportOperation,
				isModalOpen: false,
			};
		});
	}

	useEffect(() => {
		// INFO: when user selects a new vessel in the list will run again
		props.loadReportCount(vesselId || "");
		props.loadReports(
			paginationOfReports.currentPage !== +pageNumber
				? +pageNumber
				: paginationOfReports.currentPage,
			true,
			vesselId || ""
		);

		return () => {
			props.resetPageNumber();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vesselId, paginationOfReports.currentPage, pageNumber]);

	function selectReport(report: ITabReport | null) {
		if (report) {
			setReportOperation((reportOperation) => {
				return {
					...reportOperation,
					isModalOpen: true,
				};
			});
			props.loadReport(report, vesselId || "");
		}
	}

	function refreshReports() {
		props.loadReportCount(vesselId || "");
		props.loadReports(
			paginationOfReports.currentPage !== +pageNumber
				? +pageNumber
				: paginationOfReports.currentPage,
			true,
			vesselId || ""
		);

	}

	return (
		<div className="min-h-full py-6 pt-0 mb-6 bg-white rounded-md">
			<Toaster 
				position="top-right"
				toastOptions={{
					duration: 3000,
					style: {
						background: '#363636',
						color: '#fff',
					},
					success: {
						duration: 3000,
						iconTheme: {
							primary: '#4ade80',
							secondary: '#fff',
						},
					},
					error: {
						duration: 5000,
						iconTheme: {
							primary: '#ef4444',
							secondary: '#fff',
						},
					},
				}}
			/>
			<div className="flex items-center justify-center w-full p-3 mb-3 text-sm text-center text-white bg-red-500 rounded-lg">
				<h5 className="font-medium">
					⚠️ <span className="font-semibold">Notice:</span> From 10th
					January 2025, only verified reports will be sent to Signal
					and IMOS. For non-verified reports, contact C/E for
					verification. Click the status icon for details.
				</h5>
			</div>
			<div
				className={classNames(
					"flex flex-row items-end justify-between  my-6 "
				)}
			>
				<div>
					<h3 className="text-2xl font-medium text-gray-700 font-redhat-display">
						Comprehensive Noon Reports (CNR)
					</h3>
					<h6 className="text-sm text-gray-600">
						{jobToLoadReports.isLoading
							? "Loading reports please wait"
							: ""}
					</h6>
				</div>
			</div>
			<div className="mb-6 overflow-x-auto">
				<InfiniteProgress
					isLoading={
						jobToLoadReports.isLoading ||
						jobToFilterReports.isLoading
					}
					isSpacedOut={false}
				/>
				<table
					className={classNames(
						"w-full relative mb-6 border border-gray-300 rounded-sm bp3-interactive table-auto overflow-x-auto",
						{ "animate-pulse": jobToLoadReports.isLoading }
					)}
				>
					<thead className="relative">
						<tr className="h-[52px] py-6">
							<th className="text-[9px] md:text-[12px] xl:text-sm text-gray-500 pl-2">
								No.
							</th>
							<th className="text-[9px] md:text-[12px] xl:text-sm text-gray-500 lg:w-[110px] xl:w-auto">
								Title
							</th>
							{/* <th className="text-[9px] md:text-[12px] xl:text-sm text-gray-500">Vessel</th> */}
							<th className="text-[9px] md:text-[12px] xl:text-sm text-center text-gray-500 lg:w-[110px] xl:w-auto">
								Created Date (UTC)
							</th>
							<th className="text-[9px] md:text-[12px] xl:text-sm text-center text-gray-500 md:w-[110px] lg:w-[110px] xl:w-[140px]">
								Selected Date (LT)
							</th>
							<th className="text-[9px] md:text-[12px] xl:text-sm text-center text-gray-500">
								{`Report Types | Submission Date Time ${
									!isUserThirdParty
										? "| Signal Status | IMOS Status"
										: ""
								}`}
							</th>
							
							<th className="text-[9px] md:text-[12px] xl:text-sm text-center text-gray-500">
								Doc Status
							</th>
							{/* <th className="text-[9px] md:text-[12px] xl:text-sm text-center text-gray-500">
								Signal
							</th>
							<th className="text-[9px] md:text-[12px] xl:text-sm text-center text-gray-500">
								IMOS
							</th> */}
							<th className="text-[9px] md:text-[12px] xl:text-sm text-center text-gray-500">
								Actions
							</th>
							{isUserAllowedToUseCNRTool && (
								<th className="text-[9px] md:text-[12px] xl:text-sm text-center text-gray-500">
									Tools
								</th>
							)}
						</tr>
						{/* <LogFilterHeader /> */}
					</thead>
					<tbody
						className={classNames({
							"h-0 opacity-0 transition-all duration-100 max-h-0":
								jobToLoadReports.isLoading &&
								reports?.length === 0,
							"h-auto opacity-100 transition-all duration-1000 delay-300 max-h-auto":
								!jobToLoadReports.isLoading,
						})}
					>
						{reports?.map((report, index) => {
							return (
								<ReportRow
									report={report}
									key={index}
									onClick={(report: ITabReport) => {
										selectReport(report);
									}}
									setErrorDetailsModalOpen={
										setErrorDetailsModalOpen
									}
									setErrorDetails={setErrorDetails}
									indexNumber={
										(paginationOfReports.currentPage - 1) *
											paginationOfReports.itemsPerPage +
										(index + 1)
									}
									isUserThirdParty={isUserThirdParty}
									isUserAdmin={isUserAdmin}
									isUserAllowedToUseCNRTool={isUserAllowedToUseCNRTool}
									vesselName={vesselName}
									vesselImo={vesselImo}
									refreshReports={refreshReports}
									
								/>
							);
						})}
					</tbody>
				</table>
				{!jobToLoadReports.isLoading && reports?.length === 0 ? (
					<div className="flex flex-col justify-center h-64 text-center bg-gray-100">
						<h5 className="text-2xl font-thin text-gray-500 font-redhat-display">
							No Reports found..
						</h5>
					</div>
				) : null}
				<div></div>
				<Pagination
					{...paginationOfReports}
					onPageChange={function onPageChange(
						pageNumberFromPagination
					) {
						props.goToNewPage(
							vesselId || "",
							pageNumberFromPagination
						);
					}}
					isPageLoading={
						jobToLoadReports.isLoading ||
						jobToFilterReports.isLoading
					}
				/>
			</div>
			<ModalPortal
				isActive={reportOperation.isModalOpen}
				onClose={() => {
					resetOperations();
				}}
				isFullWidth
			>
				{reportOperation.isModalOpen ? (
					<TemplateRenderer
						setIsDragRef={setIsDragRef}
						onClose={function onClose() {
							props.clearSelectedReport();
							resetOperations();
						}}
					/>
				) : null}
			</ModalPortal>

			{errorDetailsModalOpen ? (
				<div className="">
					<ErrorModal
						errorDetails={errorDetails}
						open={errorDetailsModalOpen}
						setOpen={setErrorDetailsModalOpen}
						retryReport={props.retryReport}
					/>
				</div>
			) : null}
		</div>
	);
};

function mapStateToProps(store: IStore, ownProps: ReportsOwnProps) {
	const reportStore = store.app.logs;
	const reports = logSelectors.getReports(reportStore);
	const isUserThirdParty = authSelectors(store.app.auth).isUserThirdParty();
	const isUserAdmin = authSelectors(store.app.auth).isUserAdmin();
	const isUserAllowedToUseCNRTool = authSelectors(store.app.auth).isUserAllowedToUseCNRTool();
	const vesselStore = store.app.vesselStore;
	const vessel = vesselStore.vessels.byIds[ownProps.vesselId || ""] || {};

	return {
		reports: reports,
		paginationOfReports: logSelectors.getReportsPagination(reportStore),
		jobToLoadReports: logSelectors.jobToLoadReports(reportStore),
		jobToFilterReports: logSelectors.jobToLoadPrintTemplates(reportStore),
		isUserThirdParty,
		isUserAdmin,
		isUserAllowedToUseCNRTool,
		vesselName: vessel.vesselName || "",
		vesselImo: vessel.imoNumber || "",
	};
}

function mapDispatchToProps(dispatch: Dispatch) {
	return {
		loadReportCount(vesselId: string) {
			dispatch(logActions.commands.reportsCountLoad(vesselId));
		},
		loadReports(
			pageNumber: number,
			showPageNumberInNotification: boolean = false,
			vesselId: string
		) {
			dispatch(
				logActions.commands.reportsLoad({
					pageNumber,
					showPageNumberInNotification,
					vesselId,
				})
			);
		},
		loadReport(report: ITabReport, vesselId: string) {
			dispatch(logActions.commands.singleReportLoad(report, vesselId));
		},
		clearSelectedReport() {
			dispatch(logActions.document.clearSelectedReport());
		},
		goToNewPage(vesselId: string, pageNumber: number) {
			dispatch(
				historyActions.push(
					"#Report",
					rootRoutes.children.app.children.vessel.children.onboard.children.reports.url(
						vesselId,
						pageNumber
					)
				)
			);
		},
		resetPageNumber() {
			dispatch(logActions.document.reportsPaginationCurrentPageSet(1));
		},
		retryReport(reportType: EReportType, docId: string, vesselId: string) {
			dispatch(
				logActions.commands.retryReport(reportType, docId, vesselId)
			);
		},
	};
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(Reports);