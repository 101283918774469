import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IStore } from 'redux/store';
import { Dispatch } from 'redux';
import toolActions from 'redux/app/tool/actions';
import { IReleaseVersion } from 'redux/app/tool/@types';
import classNames from 'classnames';
import { format } from 'date-fns';
import toast, { Toaster } from 'react-hot-toast';

type OwnProps = {
    onBack: () => void;
};
type ReleaseHistoryProps = PropsFromRedux & OwnProps;

function ReleaseHistory(props: ReleaseHistoryProps) {
    const [activeTab, setActiveTab] = useState<'main' | 'hotfix'>('main');
    const [isLoading, setIsLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState<number[]>([]);

    useEffect(() => {
        loadReleaseHistory();
    }, []);

    const loadReleaseHistory = async () => {
        try {
            setIsLoading(true);
            await props.loadReleaseHistory();
        } catch (error) {
            toast.error('Failed to load release history');
        } finally {
            setIsLoading(false);
        }
    };

    const toggleExpandedRow = (revpos: number) => {
        setExpandedRows(prev => 
            prev.includes(revpos) 
                ? prev.filter(id => id !== revpos) 
                : [...prev, revpos]
        );
    };

    const renderReleaseTable = (releases: IReleaseVersion[]) => {
        if (releases.length === 0) {
            return (
                <div className="flex flex-col items-center justify-center py-12 bg-gray-50 rounded-lg">
                    <svg className="w-16 h-16 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4" />
                    </svg>
                    <p className="mt-2 text-lg font-medium text-gray-600">No releases found</p>
                </div>
            );
        }

        return (
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Date
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Revpos
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Frontend Version
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Backend Version
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Created By
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Changelog
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {releases.map((release) => (
                            <React.Fragment key={release.revpos}>
                                <tr className="hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {format(new Date(release.createdAt), 'MMM dd, yyyy HH:mm')}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {release.revpos}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                                            {release.frontend}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className="px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                            {release.backend}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {release.createdBy?.name || 'Unknown'}
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-500 max-w-md">
                                        <div 
                                            className="truncate max-w-xs cursor-pointer hover:text-blue-500 flex items-center"
                                            title={expandedRows.includes(release.revpos) ? "" : release.changelog}
                                            onClick={() => toggleExpandedRow(release.revpos)}
                                        >
                                            {release.changelog}
                                            <svg 
                                                className={`ml-1 w-4 h-4 transition-transform ${expandedRows.includes(release.revpos) ? 'transform rotate-180' : ''}`} 
                                                fill="none" 
                                                stroke="currentColor" 
                                                viewBox="0 0 24 24"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                                {expandedRows.includes(release.revpos) && (
                                    <tr className="bg-gray-50">
                                        <td colSpan={6} className="px-6 py-4 text-sm text-gray-600">
                                            <div className="whitespace-pre-wrap">
                                                <div className="font-semibold mb-1">Full Changelog:</div>
                                                {release.changelog}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div className="bg-white h-[calc(100vh-98px)] m-2 rounded-md overflow-y-auto p-6">
            <Toaster 
                position="top-right"
                toastOptions={{
                    duration: 3000,
                    style: {
                        background: '#333',
                        color: '#fff',
                    },
                }}
            />
            <div className="flex justify-between items-center mb-8">
                <div className="flex items-center gap-4">
                    <button
                        onClick={props.onBack}
                        className="text-gray-600 hover:text-gray-900 transition-colors"
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                    </button>
                    <h2 className="text-2xl font-semibold">Release History</h2>
                </div>
                <div className="flex gap-2">
                    <button
                        className="inline-flex items-center gap-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
                        onClick={loadReleaseHistory}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <>
                                <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Refreshing...
                            </>
                        ) : (
                            <>
                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>
                                Refresh
                            </>
                        )}
                    </button>
                </div>
            </div>

            <div className="bg-white shadow overflow-hidden rounded-lg">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex">
                        <button
                            className={classNames(
                                'py-4 px-6 text-center border-b-2 font-medium text-sm',
                                activeTab === 'main'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            )}
                            onClick={() => setActiveTab('main')}
                        >
                            Main Channel
                        </button>
                        <button
                            className={classNames(
                                'py-4 px-6 text-center border-b-2 font-medium text-sm',
                                activeTab === 'hotfix'
                                    ? 'border-orange-500 text-orange-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            )}
                            onClick={() => setActiveTab('hotfix')}
                        >
                            Hotfix Channel
                        </button>
                    </nav>
                </div>
                
                <div className="p-4">
                    {isLoading ? (
                        <div className="flex justify-center items-center py-12">
                            <svg className="animate-spin h-8 w-8 text-blue-500" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        </div>
                    ) : (
                        renderReleaseTable(
                            activeTab === 'main'
                                ? props.releaseHistory.main
                                : props.releaseHistory.hotfix
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(store: IStore) {
    const toolStore = (store as any).app.tool;
    return {
        releaseHistory: toolStore?.releaseHistory || { main: [], hotfix: [], legacy: [] }
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        loadReleaseHistory: () => {
            return new Promise((resolve, reject) => {
                try {
                    dispatch(toolActions.commands.toolReleaseHistoryLoad());
                    resolve(true);
                } catch (error) {
                    reject(error);
                }
            });
        }
    };
}

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
export default reduxConnector(ReleaseHistory); 